import { CreditScoreLevelLabel } from "src/constants/person";
import { ValueOf } from "src/types/common";

interface CreditScoreLevel {
    color: string;
    label: ValueOf<typeof CreditScoreLevelLabel>;
}

export const getCreditScoreLevel = (score: number): CreditScoreLevel => {
    if (score < 580) {
        return {
            color: '#DC1414',
            label: CreditScoreLevelLabel.Poor
        };
    } else if (score < 670) {
        return {
            color: '#FAC014',
            label: CreditScoreLevelLabel.Fair
        };
    } else if (score < 740) {
        return {
            color: '#88480A',
            label: CreditScoreLevelLabel.Good
        };
    } else if (score < 800) {
        return {
            color: '#19C009',
            label: CreditScoreLevelLabel.VeryGood
        };
    } else {
        return {
            color: '#0A7A4D',
            label: CreditScoreLevelLabel.Excellent
        };
    }
}