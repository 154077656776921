import { LinearProgress } from '@mui/material';
import type { AutocompleteProps } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Button from "components/dist/atoms/Button";
import Icon from "components/dist/atoms/Icon";
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from "components/dist/atoms/Text";
import Script from "next/script";
import { Fragment, useEffect, useState } from "react";
import { Address } from "src/backend";
import { useLicenseKeys } from "src/hooks/use-license-keys";
import { GoogleIcon } from "src/icons/google-icon";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import { useIsMounted } from 'usehooks-ts';

import { GooglePlacesTextFieldStyles } from "./google-places-textfield.styles";

interface MyAutocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    onSelectStreet?: (value: string) => void;
    onSelectDetails?: (value: Address) => void;
    onEnterAddressManuallyClick?: () => void;
    disabled?: boolean;
    currentAddress?: boolean;
}
let isFirstLoad = true

export function GooglePlacesAutocomplete<T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined>(props: MyAutocomplete<any, Multiple, DisableClearable, FreeSolo>) {
    const { onSelectStreet, onSelectDetails, onEnterAddressManuallyClick, currentAddress, ...autocompleteProps } = props;
    const [options, setOptions] = useState<any[]>([]);
    const { googleMapsKey } = useLicenseKeys();
    const isMounted = useIsMounted();
    const {
        suggestions: { data, loading: isSearching, status },
        setValue,
        init,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'us' },
            types: ['address']
        },
        debounce: 300,
        initOnMount: !isFirstLoad,
    });

    const myInit = () => {
        if (isFirstLoad) {
            init();
            isFirstLoad = false
        }
    }
    const handleInput = (e) => {
        if (!e || e.type === 'click') {
            return;
        }
        // Update the keyword of the input element
        onSelectStreet?.(e.target.value ?? '');
        setValue(e.target.value);
    };

    const handleChange = (_e, value) => {
        onSelectDetails?.(value);
    }
    const dataKey = data.map((suggestion) => suggestion.place_id).join('');
    useEffect(() => {
        (async () => {
            const promises = data.map((suggestion) => {
                return new Promise(async (resolve) => {
                    const address: Address = {
                        citySuburb: '',
                        stateProvinceRegion: '',
                        postalCode: '',
                        streetAddressLine2: '',
                        streetAddressLine1: '',
                        country: '',
                        postOfficeBox: '',
                        currentAddress: currentAddress ?? false,
                        moveInDate: "",
                        moveOutDate: ""
                    }

                    try {
                        const {
                            place_id,
                            structured_formatting: { main_text, secondary_text },
                        } = suggestion;
                        const details = await getDetails({ placeId: place_id });
                        details.address_components.forEach((component) => {
                            const [componentType] = component.types;
                            switch (componentType) {
                                case 'street_number':
                                    address.streetAddressLine1 = `${component.long_name} ${address.streetAddressLine1}`;
                                    break;
                                case 'route':
                                    address.streetAddressLine1 += component.short_name;
                                    break;
                                case 'subpremise':
                                    address.streetAddressLine2 = component.short_name;
                                    break;
                                case 'locality':
                                    address.citySuburb = component.short_name;
                                    break;
                                case 'administrative_area_level_1':
                                    address.stateProvinceRegion = component.short_name;
                                    break;
                                case 'postal_code':
                                    address.postalCode = `${component.long_name}${address.postalCode}`;
                                    break;
                                case 'postal_code_suffix':
                                    address.postalCode = `${address.postalCode}-${component.long_name}`;
                                    break;
                                case 'country':
                                    address.country = component.short_name;
                                    break;
                                default:
                                    break;
                            }
                        });
                        resolve({
                            label: address.streetAddressLine1,
                            displayLabel: `${main_text}, ${secondary_text}${address.postalCode ? `, ${address.postalCode}` : ''}`.replace(', USA', ''),
                            main: main_text,
                            key: suggestion.place_id,
                            secondary: String(secondary_text),
                            ...address,
                        });
                    } catch (error) {
                        resolve({
                            key: suggestion.place_id,
                            label: address.streetAddressLine1,
                            displayLabel: `${address.postalCode ? `, ${address.postalCode}` : ''}`.replace(', USA', ''),
                            main: 'test',
                            secondary: 'test',
                            ...address,
                        });
                    }
                });
            });
            try {
                if (promises.length) {
                    const results = await Promise.all(promises);
                    if (isMounted()) {
                        setOptions(results);
                    }
                } else {
                    throw new Error('No results');
                }
            } catch (error) {
                if (isMounted()) {
                    setOptions([]);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataKey, currentAddress]);

    return <>
        {googleMapsKey && <Script
            async
            strategy="afterInteractive"
            onReady={myInit}
            src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places&callback=Function.prototype`}></Script>}
        <Autocomplete
            {...autocompleteProps}
            options={options}
            filterOptions={(x) => x}
            disablePortal
            disabled={props.disabled}
            onChange={handleChange}
            onInputChange={handleInput}
            renderInput={props.renderInput}
            renderOption={(props, option) => (
                <Fragment key={option.key}>
                    <Separator key={`${option.key}_separator`} />
                    <GooglePlacesTextFieldStyles.ListItem {...props} >
                        <Text size="sm" key='option-container' as="div">
                            <Icon name="MapPin" width={18} height={18} strokeWidth={1.5} className="text-blue-100" />  {option.displayLabel}
                        </Text>
                    </GooglePlacesTextFieldStyles.ListItem>
                </Fragment>
            )}
            PaperComponent={({ children }) => (<PaperComponent
                isLoading={isSearching}
                onEnterAddressManuallyClick={props.onEnterAddressManuallyClick}
                isEmpty={status === "ZERO_RESULTS"}>
                {children}
            </PaperComponent>)}
        />
    </>;
}

const PaperComponent = ({ children, onEnterAddressManuallyClick, isEmpty, isLoading }) => (
    <Paper className='z-dialog'>
        {isLoading && <div className='p-3 h-2'>
            <LinearProgress />
        </div>}
        {!isEmpty ? children : <Stack row space="sm" className="p-3">
            <Icon name="MapPin" width={18} height={18} strokeWidth={1.5} className="text-blue-100" />
            <Text size="sm" >
                No Address found
            </Text>
        </Stack>}
        <Separator />
        <Stack
            space="sm"
            key="footer"
            className='pb-2 mt-2'>
            <div className='px-3 '>
                <Button
                    type="button"
                    onMouseDown={onEnterAddressManuallyClick}
                    variant="outline"
                    size="sm"
                    className='text-primary'>
                    Enter Address Manually
                </Button>
            </div>
            <Separator />
            <Stack className='px-3 items-center' space="sm" row justify="end">
                <Text variant="secondary" size="xs">Powered by</Text>
                <GoogleIcon />
            </Stack>
        </Stack>
    </Paper>)