import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import { DialogContainer } from "src/components/common/dialog-container"

type Props = {
    open: boolean;
    templateId: string;
    onClose: () => void;
    onConfirmReset: (templateId: string) => void;
    onSelectFilePicker: () => void;
}

export const ResetFileFormElementDialog = (props: Props) => {
    const { open, templateId, onClose, onConfirmReset, onSelectFilePicker } = props;

    const handleConfirmReset = () => {
        onConfirmReset(templateId);
        onClose();
    }
    return (
        <DialogContainer open={open} onClose={onClose}>
            <DialogContent>
                This Question has a template. Replace it with ?
            </DialogContent>
            <Divider />
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    startIcon={<CancelIcon />}
                    variant='contained'
                    color='error'>
                    Cancel
                </Button>

                <Box sx={{ gap: 2, display: 'flex' }}>
                    <Button
                        startIcon={<AttachFileIcon />}
                        onClick={onSelectFilePicker}
                        variant='contained'
                        color='secondary'>
                        Select File
                    </Button>
                    <Button
                        startIcon={<ContentCopyIcon />}
                        onClick={handleConfirmReset}
                        variant='contained'
                        color='primary'>
                        Template
                    </Button>
                </Box>
            </DialogActions>
        </DialogContainer>
    )
}