import { useReducer } from "react";
import { LoanRoleDto } from "src/backend";
import { ContactFormValues } from "src/components/contacts/contact-form/contact-form.types";
import { ContactRelationDtoExtended } from "src/services/contactApi";

interface ContactContextReducerState {
    addContactLoanRole: LoanRoleDto | null;
    contactListLoanRole: LoanRoleDto | null;
    loanRoles: Pick<LoanRoleDto, 'id' | 'user' | 'loan'>[] | null;
    editContactFormValues: ContactFormValues | null;
    loggedInUserContacts: ContactRelationDtoExtended[];
    moveToContactLoanRole: LoanRoleDto | null;
    moveToBorrowingTeamLoanRole: LoanRoleDto | null;
    moveKeyContactToContactLoanRole: LoanRoleDto | null;
    removeContactLoanRole: LoanRoleDto | null;
    moveContactToBorrowerContactRelation: ContactRelationDtoExtended | null;
}

const contactContextReducerInitialState: ContactContextReducerState = {
    addContactLoanRole: null,
    contactListLoanRole: null,
    editContactFormValues: null,
    moveToContactLoanRole: null,
    moveToBorrowingTeamLoanRole: null,
    moveKeyContactToContactLoanRole: null,
    moveContactToBorrowerContactRelation: null,
    loanRoles: null,
    removeContactLoanRole: null,
    loggedInUserContacts: []
};

type ContactContextAction =
    { type: "SET_CONTACT_LIST_LOAN_ROLE", payload: LoanRoleDto }
    | { type: "SET_IS_ADD_CONTACT_LOAN_ROLE", payload: LoanRoleDto }
    | { type: "SET_EDIT_CONTACT_FORM_VALUES", payload: ContactFormValues }
    | { type: "SET_MOVE_TO_CONTACT_LOAN_ROLE", payload: LoanRoleDto }
    | { type: "SET_MOVE_TO_BORROWING_TEAM_LOAN_ROLE", payload: LoanRoleDto }
    | { type: "SET_MOVE_KEY_CONTACT_TO_CONTACT_LOAN_ROLE", payload: LoanRoleDto }
    | { type: "SET_MOVE_CONTACT_TO_BORROWER_CONTACT_RELATION", payload: ContactRelationDtoExtended }
    | { type: "SET_REMOVE_CONTACT_LOAN_ROLE", payload: LoanRoleDto }
    | { type: "SET_LOAN_ROLES", payload: Pick<LoanRoleDto, 'id' | 'user' | 'loan'>[] }
    | { type: "SET_LOGGED_IN_USER_CONTACTS", payload: ContactRelationDtoExtended[] };

export const contactContextReducer = (
    state: ContactContextReducerState,
    action: ContactContextAction
): ContactContextReducerState => {
    switch (action.type) {
        case "SET_CONTACT_LIST_LOAN_ROLE":
            return { ...state, contactListLoanRole: action.payload };
        case "SET_IS_ADD_CONTACT_LOAN_ROLE":
            return { ...state, addContactLoanRole: action.payload };
        case "SET_EDIT_CONTACT_FORM_VALUES":
            return { ...state, editContactFormValues: action.payload };
        case "SET_MOVE_TO_CONTACT_LOAN_ROLE":
            return { ...state, moveToContactLoanRole: action.payload };
        case "SET_MOVE_TO_BORROWING_TEAM_LOAN_ROLE":
            return { ...state, moveToBorrowingTeamLoanRole: action.payload };
        case "SET_MOVE_KEY_CONTACT_TO_CONTACT_LOAN_ROLE":
            return { ...state, moveKeyContactToContactLoanRole: action.payload };
        case "SET_MOVE_CONTACT_TO_BORROWER_CONTACT_RELATION":
            return { ...state, moveContactToBorrowerContactRelation: action.payload };
        case "SET_REMOVE_CONTACT_LOAN_ROLE":
            return { ...state, removeContactLoanRole: action.payload };
        case "SET_LOAN_ROLES":
            return { ...state, loanRoles: action.payload };
        case "SET_LOGGED_IN_USER_CONTACTS":
            return { ...state, loggedInUserContacts: action.payload };
        default:
            return state;
    }
};

export const useContactContextReducer = () => {
    const [state, dispatch] = useReducer(
        contactContextReducer,
        contactContextReducerInitialState
    );

    const onSetContactListUser = (contactListLoanRole: LoanRoleDto, loanRoles: Pick<LoanRoleDto, 'id' | 'user' | 'loan'>[]) => {
        dispatch({ type: "SET_CONTACT_LIST_LOAN_ROLE", payload: contactListLoanRole });
        dispatch({ type: "SET_LOAN_ROLES", payload: loanRoles });
    }

    const onSetIsAddContactLoanRole = (addContactLoanRole: LoanRoleDto) => {
        dispatch({ type: "SET_IS_ADD_CONTACT_LOAN_ROLE", payload: addContactLoanRole });
    }

    const onSetEditContactFormValues = (editContactFormValues: ContactFormValues, contacts: ContactRelationDtoExtended[]) => {
        dispatch({ type: "SET_EDIT_CONTACT_FORM_VALUES", payload: editContactFormValues });
        dispatch({ type: "SET_LOGGED_IN_USER_CONTACTS", payload: contacts });
    }

    const onSetMoveToContactLoanRole = (moveToContactLoanRole: LoanRoleDto, keyContact: boolean) => {
        dispatch({
            type: "SET_MOVE_TO_CONTACT_LOAN_ROLE", payload: moveToContactLoanRole ? {
                ...moveToContactLoanRole,
                keyContact
            } : null
        });
    }

    const onSetMoveToBorrowingTeamLoanRole = (moveToBorrowingTeamLoanRole: LoanRoleDto) => {
        dispatch({ type: "SET_MOVE_TO_BORROWING_TEAM_LOAN_ROLE", payload: moveToBorrowingTeamLoanRole });
    }

    const onSetMoveKeyContactToContactLoanRole = (moveKeyContactToContactLoanRole: LoanRoleDto) => {
        dispatch({ type: "SET_MOVE_KEY_CONTACT_TO_CONTACT_LOAN_ROLE", payload: moveKeyContactToContactLoanRole });
    }

    const onSetMoveContactToBorrowerContactRelation = (moveContactToBorrowerContactRelation: ContactRelationDtoExtended) => {
        dispatch({ type: "SET_MOVE_CONTACT_TO_BORROWER_CONTACT_RELATION", payload: moveContactToBorrowerContactRelation });
    }

    const onSetRemoveContactLoanRole = (removeContactLoanRole: LoanRoleDto) => {
        dispatch({ type: "SET_REMOVE_CONTACT_LOAN_ROLE", payload: removeContactLoanRole });
    }

    const onSetLoanRoles = (loanRoles: LoanRoleDto[]) => {
        dispatch({ type: "SET_LOAN_ROLES", payload: loanRoles });
    }

    return {
        state,
        onSetMoveKeyContactToContactLoanRole,
        onSetMoveToBorrowingTeamLoanRole,
        onSetMoveToContactLoanRole,
        onSetContactListUser,
        onSetIsAddContactLoanRole,
        onSetEditContactFormValues,
        onSetMoveContactToBorrowerContactRelation,
        onSetRemoveContactLoanRole,
        onSetLoanRoles
    } as const;
};