import { LoanReviewStatus } from "src/backend";

const LoanReviewStatusList: LoanReviewStatus[] = [
    "LEAD", "IN_PROGRESS", "READY_FOR_UNDERWRITING", "READY_FOR_REVIEW_PENDING", "READY_FOR_REVIEW_REVIEW", "REVIEW_COMPLETE_ACCEPTED", "REVIEW_COMPLETE_CONDITIONAL", "REVIEW_COMPLETE_REJECTED", "CLOSING", "IN_SERVICE", "ARCHIVE", "IN_DEFAULT", "HOLD", "WITHDRAWN"
]

export const isElementReviewStatusHidden = (elementReviewStatus: LoanReviewStatus, reviewStatus: LoanReviewStatus) => {
    // get the index of the element review status
    // and the index of the review status
    // from the LoanReviewStatus enum
    const elementReviewStatusIndex = LoanReviewStatusList.indexOf(elementReviewStatus);
    const reviewStatusIndex = LoanReviewStatusList.indexOf(reviewStatus);
    const isHidden = elementReviewStatusIndex > reviewStatusIndex;
    return isHidden;
}