
import Text from 'components/dist/atoms/Text';
import { FormElementV2ResponseDto } from 'src/backend';
import { ConfirmationDialog } from 'src/components/common/v2/confirmation-dialog';

type Props = {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void,
    formElement?: Partial<FormElementV2ResponseDto>,
};

export const MergeFormElementConfirmDialog = (props: Props) => {
    return <ConfirmationDialog
        onConfirm={props.onConfirm}
        open={props.open}
        onClose={props.onClose}>
        <div>
            <Text className='text-center' size='sm'>
                {props.formElement?.title || 'This file'} has been accepted, are you sure you want
                to replace/merge it?
            </Text>
        </div>
    </ConfirmationDialog>
}
