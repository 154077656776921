import Button from 'components/dist/atoms/Button';
import Command from 'components/dist/atoms/Command';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Popover from 'components/dist/atoms/Popover';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { useState } from 'react';
import { ContactSubRoleDto } from 'src/backend';
import { useCreateSubRolesMutation, useGetContactSubRolesQuery } from 'src/services/contactApi';
import { classNames } from 'src/utils/tailwind-class-names';

import { ContactSubRoleLabel } from '../contact-sub-role-label';

interface ContactSubRolesAutocompleteProps {
    loanRoleId: string;
    onContactRoleSelected: (contactSubRole: ContactSubRoleDto) => void;
    hideTeamRoles: boolean;
    lenderId: string;
}

export const ContactSubRolesAutocomplete = (props: ContactSubRolesAutocompleteProps) => {
    const [inputValue, setInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { data: contactSubRoles = [] } = useGetContactSubRolesQuery({ companyId: props.lenderId }, {
        skip: !props.lenderId
    });

    const [createSubRoles] = useCreateSubRolesMutation();

    const onContactRoleSelected = (loanRoleId: string) => {
        const selectedRole = contactSubRoles.find(role => role.id === loanRoleId);
        props.onContactRoleSelected(selectedRole)
        setIsOpen(false)
    };

    const onAddNewRoleClick = () => {
        createSubRoles([{
            companyId: props.lenderId,
            title: inputValue,
            subRoleType: 'CONTACT',
            id: null
        }]).unwrap()
    }

    const onClearClick = () => {
        setInputValue('')
    }

    const filteredContactSubRoles = contactSubRoles
        .filter(role => !props.hideTeamRoles || ['CONTACT', 'KEY_CONTACT', 'NONE'].includes(role.subRoleType))
        .filter(role => role.title.toLowerCase().includes(inputValue.toLowerCase()));
    const selectedRole = filteredContactSubRoles.find(role => role.id === props.loanRoleId);

    return <Popover open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger asChild>
            <Button
                variant='outline'
                block
                className={classNames('text-sm hover:bg-white justify-start h-12 font-normal', {
                    'text-gray-neutral-70 hover:text-gray-neutral-70': !selectedRole,
                })}>
                {selectedRole?.title || 'Select Role'}
                <Icon name="NavArrowDown" width={20} height={20} className='ml-auto' strokeWidth={1.5} />
            </Button>
        </Popover.Trigger>
        <Popover.Content
            onOpenAutoFocus={(event) => event.preventDefault()}
            className='p-2 w-96 max-w-full sm:w-80 sm:w-80'>
            <Command
                shouldFilter={false}>
                <Command.Input
                    autoFocus={false}
                    asChild>
                    <Input
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        variant='sm'
                        wrapClassName='w-full focus-within:border-blue-100'
                        className='placeholder:text-gray-neutral-70'
                        placeholder='Create or Search Loan Role' >
                        <Input.Prefix>
                            <Icon name='Search' width={18} height={18} className='text-gray-neutral-70' strokeWidth={2} />
                        </Input.Prefix>
                        {inputValue && <Input.Suffix>
                            <Button
                                onClick={onClearClick}
                                variant="ghost" size='sm'
                                className="px-1 py-0 hover:bg-transparent">
                                <Icon name='Cancel' width={18} height={18} className='text-black-primary' strokeWidth={1.5} />
                            </Button>
                        </Input.Suffix>}
                    </Input>
                </Command.Input>
                <Command.List className='mt-4 h-48 overflow-auto scrollbar-stable'>
                    {!!inputValue?.trim() && <Command.Empty className='p-0 gap-3 flex flex-col'>
                        <Text size='xs' variant='secondary'>Role does not exist</Text>
                        <Button
                            onClick={onAddNewRoleClick}
                            variant='secondary'
                            className='text-blue-100 font-normal justify-start'
                            block>
                            Add Role &ldquo;{inputValue}&rdquo;
                        </Button>
                    </Command.Empty>}
                    {filteredContactSubRoles.length === 0 && !inputValue?.trim() && <Stack items='center' space='md' className='flex-1 pt-8'>
                        <Icon name="BigExclamation" width={64} height={64} strokeWidth={1.5} className='text-black-20' />
                        <Text size='sm'>Enter valid Role</Text>
                    </Stack>}
                    {filteredContactSubRoles.map(role => <Command.Item
                        className={classNames('cursor-pointer gap-2 overflow-hidden', {
                            'bg-gray-100': role.id === props.loanRoleId
                        })}
                        value={role.id}
                        keywords={[role.title]}
                        onSelect={onContactRoleSelected}
                        key={role.id}>
                        <Text size='sm' truncate>{role.title}</Text> <ContactSubRoleLabel type={role.subRoleType} />
                    </Command.Item>)}
                </Command.List>
            </Command>
        </Popover.Content>
    </Popover>
};