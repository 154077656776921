import { Children, isValidElement } from "react";

type ChildType = {
  readonly type: {
    displayName?: string;
  };
};

export type ReactComponent = React.ReactNode & ChildType;

const findChildRecursively = (
  child: React.ComponentType<any> | React.ReactNode,
  displayName: string,
): React.ReactNode | null => {
  if (
    isValidElement(child) &&
    typeof (child.type as unknown) !== "string" &&
    (child.type as React.ComponentType<any>).displayName === displayName
  ) {
    return child;
  }

  if (isValidElement(child) && (child as React.ReactElement).props.children) {
    const childChildren = Children.toArray((child as React.ReactElement).props.children);
    for (const child of childChildren) {
      const foundChild = findChildRecursively(child, displayName);
      if (foundChild) {
        return foundChild;
      }
    }
  }

  return null;
};

export function getChildOnDisplayName(
  children: React.ReactNode,
  displayName: string,
  defaultChild?: React.ReactNode,
): React.ReactNode | null {
  const childrenAsComponent = children as React.ComponentType<any>[];

  const childrenToReturn = Children.map(childrenAsComponent, (child: React.ComponentType<any>) => {
    return findChildRecursively(child, displayName);
  });

  if (Children.count(childrenToReturn) > 0) {
    return childrenToReturn;
  }

  return defaultChild;
}