import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Portal from 'components/dist/atoms/Portal';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useMemo } from "react";
import { toast } from 'react-toastify';
import { BorrowUserSetDto, ContactRelationAddDto, LoanRoleDto } from "src/backend";
import { ChangeLoanRoleToContactConfirmationDialog } from 'src/components/contacts/change-loan-role-to-contact-confirmation-dialog';
import { ContactForm } from 'src/components/contacts/contact-form';
import { ContactFormValues } from 'src/components/contacts/contact-form/contact-form.types';
import { ContactFormFooter } from 'src/components/contacts/contact-form/contact-form-footer.component';
import { ContactsList } from 'src/components/contacts/contacts-list';
import { mapAppUserToUser, SelectUserAutocompleteProps } from 'src/components/contacts/select-user-autocomplete/select-user-autocomplete.state';
import { RoleTypeLabel } from 'src/constants/loan';
import { QUERY_PARAM_LENDER_ID } from 'src/constants/url';
import { ContactRelationDtoExtended, useDeleteContactRelationMutation, useEditContactMutation } from 'src/services/contactApi';
import { useEditNonLenderUserMutation } from 'src/services/loanApi';
import { useGetLoggedInUserQuery } from 'src/services/userApi';
import { getLoan } from 'src/slices/loan';
import { useDispatch } from 'src/store';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { useContactContextReducer } from "./contact-context.reducer";


interface ContactContextValue {
  onSetContactListUser: (user: LoanRoleDto, loanRoles: Pick<LoanRoleDto, 'id' | 'user'>[]) => void;
  onSetEditContactFormValues: (loanRole: ContactFormValues, contacts: ContactRelationDtoExtended[]) => void;
  onSetIsAddContactLoanRole: (loanRole: LoanRoleDto) => void;
  onRemoveContact: (loanRole: Pick<LoanRoleDto, 'id' | 'user'>) => void;
  onMoveLoanRoleToKeyContact: (loanRole: LoanRoleDto) => void;
  onMoveLoanRoleToBorrowingTeam: (loanRole: LoanRoleDto) => void;
  onMoveContactToBorrower: (contactRelation: ContactRelationDtoExtended) => void;
  onMoveLoanRoleToContact: (loanRole: LoanRoleDto) => void;
  onMoveKeyContactToContact: (loanRole: Pick<LoanRoleDto, 'id' | 'user'>) => void;
  onMoveContactToKeyContact: (contactRelation: ContactRelationDtoExtended, loanId: string, loanRoles: Pick<LoanRoleDto, 'id' | 'user'>[], contactFormValues?: Partial<ContactFormValues>) => void;
}

const contactContextInitialValue: ContactContextValue = {
  onSetContactListUser: () => { },
  onSetEditContactFormValues: () => { },
  onSetIsAddContactLoanRole: () => { },
  onRemoveContact: () => { },
  onMoveLoanRoleToBorrowingTeam: () => { },
  onMoveKeyContactToContact: () => { },
  onMoveLoanRoleToKeyContact: () => { },
  onMoveLoanRoleToContact: () => { },
  onMoveContactToBorrower: () => { },
  onMoveContactToKeyContact: () => { },
};

export const ContactContext = createContext<ContactContextValue>(
  contactContextInitialValue
);

export const ContactContextProvider = (props: React.PropsWithChildren<object>) => {
  const contactReducer = useContactContextReducer();
  const [deleteContactRelation, { isLoading: isDeleteContactRelationLoading }] = useDeleteContactRelationMutation();
  const [editContact] = useEditContactMutation();
  const [editNonLenderUser] = useEditNonLenderUserMutation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { data: loggedInUserData } = useGetLoggedInUserQuery();
  const lenderId = loggedInUserData?.employer?.id ?? router.query[QUERY_PARAM_LENDER_ID] as string;
  const onRemoveContact = useCallback((loanRole: LoanRoleDto) => {
    contactReducer.onSetRemoveContactLoanRole(loanRole);
  }, [contactReducer]);

  const onRemoveContactConfirm = useCallback(async (loanRole: LoanRoleDto) => {
    contactReducer.onSetRemoveContactLoanRole(null);
    await deleteContactRelation({
      loanId: loanRole.loan,
      loanRoleId: loanRole.id
    }).unwrap()
    toast.success(`${getUserDisplayName(loanRole.user)} removed from your profile and loan`);
  }, [contactReducer, deleteContactRelation]);

  const onMoveContactToKeyContact = useCallback(async (
    contact: ContactRelationDtoExtended,
    loanId: string,
    loanRoles: Pick<LoanRoleDto, 'id' | 'user'>[],
    contactFormValues?: Partial<ContactFormValues>) => {
    try {
      const iRelation: ContactRelationAddDto = {
        id: contact.id,
        visibleToBorrower: contact.visibleToBorrower,
        visibleToLender: contact.visibleToLender,
        userContactId: contact.contactUser.id,
        contactSubRoleId: contact.contactSubRole?.id,
        userContactEmailAddress: contact.contactUser.emailAddress,
        familyName: contact.familyName,
        givenName: contact.givenName,
        companyAddress: contact.companyAddress,
        companyName: contact.companyName,
        companyWebsite: contact.companyWebsite,
        keyContact: true,
        positionOrTitle: contact.positionOrTitle,
        ...contactFormValues,
      }

      const contactLoanRole = loanRoles.find(loanRole => loanRole.user.id === contact.contactUser.id)
      await editContact({
        loanId: loanId,
        iRelation: iRelation,
        contactLoanRoleId: contactLoanRole.id
      }).unwrap();

      toast.success(`${getUserDisplayName(contact.contactUser)} moved to key contacts${!!contactFormValues?.contactSubRoleTitle ? `/${contactFormValues.contactSubRoleTitle}` : ''} successfully`);

      dispatch(getLoan(loanId, false, true));
    } catch (error) {
      toast.error('Error moving contact to key contact');
      console.error(error);
    }
  }, [dispatch, editContact]);

  const onMoveLoanRoleToContact = useCallback(async (loanRole: LoanRoleDto) => {
    contactReducer.onSetMoveToContactLoanRole(loanRole, false);
  }, [contactReducer]);

  const onMoveLoanRoleToKeyContact = useCallback(async (loanRole: LoanRoleDto) => {
    contactReducer.onSetMoveToContactLoanRole(loanRole, true);
  }, [contactReducer]);

  const onMoveLoanRoleToContactConfirm = async (loanRole: LoanRoleDto) => {
    const payload: Partial<BorrowUserSetDto> = {
      borrowerType: "MEMBER",
      templateId: null,
      role: 'CONTACT',
      contactRelationDto: {
        id: null,
        contactSubRoleId: null,
        familyName: loanRole.user.familyName,
        givenName: loanRole.user.givenName,
        keyContact: loanRole.keyContact,
        positionOrTitle: loanRole.user.positionOrTitle,
        userContactEmailAddress: loanRole.user.emailAddress,
        userContactId: loanRole.user.id,
        visibleToBorrower: loanRole.visibleToBorrower,
        companyAddress: loanRole.user.companyAddress,
        companyName: loanRole.user.companyName,
        companyWebsite: loanRole.user.companyWebsite,
        visibleToLender: true
      }
    }
    await editNonLenderUser({
      loanId: loanRole.loan,
      userId: loanRole.user.id,
      iDto: payload,
    }).unwrap();
    if (loanRole.keyContact) {
      toast.success(`${getUserDisplayName(loanRole.user)} moved to key contacts successfully`)
    } else {
      toast.success(`${getUserDisplayName(loanRole.user)} moved to your profile as a contact successfully`)
    }
    dispatch(getLoan(loanRole.loan, false, true));
    contactReducer.onSetMoveToContactLoanRole(null, false);

  }

  const onMoveLoanRoleToBorrowingTeam = useCallback(async (loanRole: LoanRoleDto) => {
    contactReducer.onSetMoveToBorrowingTeamLoanRole(loanRole);
  }, [contactReducer]);

  const onMoveLoanRoleToBorrowingTeamConfirm = useCallback(async (loanRole: LoanRoleDto) => {
    const payload: Partial<BorrowUserSetDto> = {
      borrowerType: "MEMBER",
      templateId: null,
      role: 'BORROWER',
      contactRelationDto: {
        id: null,
        contactSubRoleId: null,
        familyName: loanRole.user.familyName,
        givenName: loanRole.user.givenName,
        keyContact: false,
        positionOrTitle: loanRole.user.positionOrTitle,
        userContactEmailAddress: loanRole.user.emailAddress,
        userContactId: loanRole.user.id,
        visibleToBorrower: loanRole.visibleToBorrower,
        visibleToLender: true,
        companyAddress: loanRole.user.companyAddress,
        companyName: loanRole.user.companyName,
        companyWebsite: loanRole.user.companyWebsite,
      }
    };
    await editNonLenderUser({
      loanId: loanRole.loan,
      userId: loanRole.user.id,
      iDto: payload,
    }).unwrap();
    toast.success(`${getUserDisplayName(loanRole.user)} moved to borrowing team successfully`)
    dispatch(getLoan(loanRole.loan, false, true));
  }, [dispatch, editNonLenderUser]);

  const onMoveKeyContactToContact = useCallback(async (loanRole: LoanRoleDto) => {
    contactReducer.onSetMoveKeyContactToContactLoanRole(loanRole);
  }, [contactReducer])

  const onMoveKeyContactToContactConfirm = useCallback(async (loanRole: LoanRoleDto) => {
    const payload: Partial<BorrowUserSetDto> = {
      borrowerType: "MEMBER",
      templateId: null,
      role: 'CONTACT',
      contactRelationDto: {
        id: null,
        contactSubRoleId: null,
        familyName: loanRole.user.familyName,
        givenName: loanRole.user.givenName,
        keyContact: false,
        positionOrTitle: loanRole.user.positionOrTitle,
        userContactEmailAddress: loanRole.user.emailAddress,
        userContactId: loanRole.user.id,
        visibleToBorrower: loanRole.visibleToBorrower,
        visibleToLender: true,
        companyAddress: loanRole.user.companyAddress,
        companyName: loanRole.user.companyName,
        companyWebsite: loanRole.user.companyWebsite,
      }
    };
    await editNonLenderUser({
      loanId: loanRole.loan,
      userId: loanRole.user.id,
      iDto: payload,
    }).unwrap();
    toast.success(`${getUserDisplayName(loanRole.user)} moved to a contact on your profile successfully`)
    dispatch(getLoan(loanRole.loan, false, true));
  }, [dispatch, editNonLenderUser]);

  const onMoveContactToBorrower = useCallback(async (contactRelation: ContactRelationDtoExtended) => {
    contactReducer.onSetMoveContactToBorrowerContactRelation(contactRelation);
  }, [contactReducer]);

  const onMoveContactToBorrowerConfirm = useCallback(async (contactRelation: ContactRelationDtoExtended, loanId: string) => {
    const payload: Partial<BorrowUserSetDto> = {
      borrowerType: "MEMBER",
      templateId: null,
      role: 'BORROWER',
      contactRelationDto: {
        id: contactRelation.id,
        contactSubRoleId: contactRelation.contactSubRole?.id ?? null,
        familyName: contactRelation.contactUser.familyName,
        givenName: contactRelation.contactUser.givenName,
        keyContact: false,
        positionOrTitle: contactRelation.contactUser.positionOrTitle,
        userContactEmailAddress: contactRelation.contactUser.emailAddress,
        userContactId: contactRelation.contactUser.id,
        visibleToBorrower: contactRelation.visibleToBorrower,
        visibleToLender: contactRelation.visibleToLender,
        companyAddress: contactRelation.companyAddress,
        companyName: contactRelation.companyName,
        companyWebsite: contactRelation.companyWebsite,
      }
    };
    await editNonLenderUser({
      loanId: loanId,
      userId: contactRelation.contactUser.id,
      iDto: payload,
    }).unwrap();
    toast.success(`${getUserDisplayName(contactRelation.contactUser)}} moved to borrowing team successfully`)
    dispatch(getLoan(loanId, false, true));
    contactReducer.onSetMoveToContactLoanRole(null, false);
  }, [contactReducer, dispatch, editNonLenderUser]);

  const handleUserSelected = useCallback((user: SelectUserAutocompleteProps['users'][0]) => {
    const contact = contactReducer.state.loggedInUserContacts.find(contact => contact.contactUser.id === user.id);
    if (contact) {
      contactReducer.onSetEditContactFormValues({
        loanId: contact.loanId,
        userId: contact.contactUser.id,
        givenName: contact.givenName,
        familyName: contact.familyName,
        emailAddress: contact.contactUser.emailAddress,
        phoneNumber: contact.contactUser.mobilePhone,
        companyAddress: contact.companyAddress,
        companyName: contact.companyName,
        contactSubRoleType: contact.contactSubRole?.subRoleType,
        contactSubRoleTitle: contact.contactSubRole?.title,
        companyWebsite: contact.companyWebsite,
        positionOrTitle: contact.positionOrTitle,
        contactSubRoleId: contact.contactSubRole?.id,
        visibleToBorrower: contact.visibleToBorrower,
        emailEditingDisabled: !contact.contactUser.stub,
        keyContact: contact.keyContact,
        editOtherFieldsDisabled: false,
        fieldsVisible: true,
        templateId: null,
        visibleToLender: contact.visibleToLender,
      }, contactReducer.state.loggedInUserContacts);
    }
  }, [contactReducer]);

  const onAddNewClick = useCallback((loanRole: LoanRoleDto) => {
    contactReducer.onSetEditContactFormValues(null, []);
    contactReducer.onSetIsAddContactLoanRole(loanRole);
  }, [contactReducer]);

  const value = useMemo(() => {
    return {
      onSetContactListUser: contactReducer.onSetContactListUser,
      onSetIsAddContactLoanRole: contactReducer.onSetIsAddContactLoanRole,
      onMoveLoanRoleToContact,
      onSetEditContactFormValues: contactReducer.onSetEditContactFormValues,
      onMoveKeyContactToContact,
      onRemoveContact,
      onMoveContactToKeyContact,
      onMoveLoanRoleToKeyContact,
      onMoveContactToBorrower,
      onMoveLoanRoleToBorrowingTeam,
    };
  }, [contactReducer.onSetContactListUser, contactReducer.onSetIsAddContactLoanRole, contactReducer.onSetEditContactFormValues, onMoveLoanRoleToContact, onMoveKeyContactToContact, onRemoveContact, onMoveContactToKeyContact, onMoveLoanRoleToKeyContact, onMoveContactToBorrower, onMoveLoanRoleToBorrowingTeam]);

  return (<ContactContext.Provider value={value}>
    {props.children}
    {contactReducer.state.contactListLoanRole !== null && <Portal>
      {/** backdrop */}
      <div className='bg-black-primary/80 backdrop-blur-sm z-dialog inset-0 fixed' onClick={() => contactReducer.onSetContactListUser(null, [])} />
      <dialog data-state='open' aria-label='Contacts List'
        className='fixed w-full h-full sm:h-auto sm:max-h-[95%] left-[50%] top-[50%] m-0 z-dialog flex flex-col w-full sm:max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full'>
        <Stack row className="p-2 sm:hidden">
          <Button size='sm' circle variant='secondary' onClick={() => contactReducer.onSetContactListUser(null, [])}>
            <Icon name="Cancel" width={18} height={18} strokeWidth={2} />
          </Button>
        </Stack>
        <ContactsList
          loanRole={contactReducer.state.contactListLoanRole}
          loanRoles={contactReducer.state.loanRoles}
          loanId={contactReducer.state.contactListLoanRole.loan} />
      </dialog>
    </Portal>}
    {contactReducer.state.editContactFormValues !== null && <Dialog
      open
      onOpenChange={() => contactReducer.onSetEditContactFormValues(null, [])}
    >
      <Dialog.Content
        onOpenAutoFocus={(e) => e.preventDefault()}
        className='w-full sm:max-w-2xl px-4 h-full max-h-[920px] sm:max-h-[920px] gap-0'>
        <Text weight='medium' size='sm' className='p-2'>Edit Contact</Text>
        <Separator />
        <ContactForm
          onAddNewClick={onAddNewClick}
          key={contactReducer.state.editContactFormValues.userId}
          onUserSelected={handleUserSelected}
          users={contactReducer.state.loggedInUserContacts.map(contact => mapAppUserToUser(contact.contactUser))}
          initialValues={contactReducer.state.editContactFormValues}
          onClose={() => contactReducer.onSetEditContactFormValues(null, [])}
          loanId={contactReducer.state.editContactFormValues.loanId}
          lenderId={lenderId}
        >
          <ContactFormFooter
            onClose={() => contactReducer.onSetEditContactFormValues(null, [])}
          />
        </ContactForm>
      </Dialog.Content>
    </Dialog>}
    {contactReducer.state.addContactLoanRole !== null && <Dialog
      onOpenChange={() => contactReducer.onSetIsAddContactLoanRole(null)}
      open>
      <Dialog.Content
        onOpenAutoFocus={(e) => e.preventDefault()}
        className='w-full sm:max-w-2xl gap-0 h-full max-h-[920px] sm:max-h-[920px]'>
        <Text weight='medium' size='sm' className='p-2'>Add Contact</Text>
        <Separator />
        <div className='px-4 flex-1 flex flex-col overflow-hidden'>
          <ContactForm
            users={[]}
            lenderId={lenderId}
            onUserSelected={() => { }}
            ownerRole={contactReducer.state.addContactLoanRole}
            onClose={() => contactReducer.onSetIsAddContactLoanRole(null)}
            loanId={contactReducer.state.addContactLoanRole.loan}
          >
            <ContactFormFooter
              onClose={() => contactReducer.onSetIsAddContactLoanRole(null)}
            />
          </ContactForm>
        </div>
      </Dialog.Content>
    </Dialog>}
    {contactReducer.state.moveToContactLoanRole && <ChangeLoanRoleToContactConfirmationDialog
      noConfirmClose
      onConfirm={() => onMoveLoanRoleToContactConfirm(contactReducer.state.moveToContactLoanRole)}
      onCancel={() => contactReducer.onSetMoveToContactLoanRole(null, false)}
      loading={false}
    >
      <Text className="mb-2" fontWeight="medium">
        Confirm loan role change from {RoleTypeLabel(contactReducer.state.moveToContactLoanRole.role)} to {contactReducer.state.moveToContactLoanRole.keyContact ? "Key Contact" : "Contact"}
      </Text>
      <Text variant="secondary" size="sm" >
        {getUserDisplayName(contactReducer.state.moveToContactLoanRole.user)} loan role has been updated to {contactReducer.state.moveToContactLoanRole.keyContact ? "Key Contact" : "Contact"}. mysherpas will match existing items to new role and place all items in your shoebox for categorization or removal
      </Text>
    </ChangeLoanRoleToContactConfirmationDialog>}
    {contactReducer.state.removeContactLoanRole && <ActionAlertDialog
      variant='danger'
      open
      loading={isDeleteContactRelationLoading}
      confirmButtonText='Yes, Continue'
      onOpenChange={(open: boolean) => !open && contactReducer.onSetRemoveContactLoanRole(null)}
      onConfirm={() => onRemoveContactConfirm(contactReducer.state.removeContactLoanRole)}
      message={`Are you sure you want to remove ${getUserDisplayName(contactReducer.state.removeContactLoanRole.user)} from your profile and loan?`}
    />}
    {contactReducer.state.moveToBorrowingTeamLoanRole && <ActionAlertDialog
      variant='warning'
      open
      confirmButtonText='Continue'
      onOpenChange={() => contactReducer.onSetMoveToBorrowingTeamLoanRole(null)}
      onConfirm={() => onMoveLoanRoleToBorrowingTeamConfirm(contactReducer.state.moveToBorrowingTeamLoanRole)}
      message={`You’re moving ${getUserDisplayName(contactReducer.state.moveToBorrowingTeamLoanRole.user)} from a key contact to the borrowing team. Are you sure you want to continue?`}
    />}
    {contactReducer.state.moveContactToBorrowerContactRelation && <ActionAlertDialog
      variant='warning'
      open
      confirmButtonText='Continue'
      onOpenChange={() => contactReducer.onSetMoveContactToBorrowerContactRelation(null)}
      onConfirm={() => onMoveContactToBorrowerConfirm(contactReducer.state.moveContactToBorrowerContactRelation, contactReducer.state.moveContactToBorrowerContactRelation.loanId)}
      message={`You’re moving ${getUserDisplayName(contactReducer.state.moveContactToBorrowerContactRelation.contactUser)} from your contact to borrowing team. Are you sure you want to continue?`}
    />}
    {contactReducer.state.moveKeyContactToContactLoanRole && <ActionAlertDialog
      variant='warning'
      open
      confirmButtonText='Continue'
      onOpenChange={() => contactReducer.onSetMoveKeyContactToContactLoanRole(null)}
      onConfirm={() => onMoveKeyContactToContactConfirm(contactReducer.state.moveKeyContactToContactLoanRole)}
      message={`You’re moving ${getUserDisplayName(contactReducer.state.moveKeyContactToContactLoanRole.user)} from a key contact to a contact. Are you sure you want to continue?`}
    />}
  </ContactContext.Provider>
  );
}

export const useContactContext = () => {

  const context = useContext(ContactContext);
  if (context === undefined) {
    throw new Error('useContactContext must be used within a ContactContextProvider');
  }

  return context;
}