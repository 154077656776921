import Button from "components/dist/atoms/Button";
import Dialog from "components/dist/atoms/Dialog";
import Icon from "components/dist/atoms/Icon";

interface ChangeLoanRoleToContactConfirmationDialogProps {
    onConfirm: () => void;
    onCancel: () => void;
    loading: boolean;
    noConfirmClose?: boolean;
}

export const ChangeLoanRoleToContactConfirmationDialog = (props: React.PropsWithChildren<ChangeLoanRoleToContactConfirmationDialogProps>) => {
    return <Dialog
        open
        onOpenChange={(open) => !open && props.onCancel()}>
        <Dialog.Content className="max-w-[95%] rounded sm:max-w-3xl overflow-auto">
            <div className="flex gap-2 px-6 pt-6 pb-0">
                <Icon name="BigExclamation" width={24} height={24} strokeWidth={2} className="text-black-10" />
                <div className="flex flex-col gap-3">
                    {props.children}
                </div>
            </div>
            <div className="flex justify-between px-6 py-3">
                <Button
                    onClick={props.onCancel}
                    variant='outline'
                    color='secondary'>
                    Cancel
                </Button>
                <div className="flex gap-3 flex-start">
                    {!props.noConfirmClose && <Button
                        disabled={props.loading}
                        onClick={props.onCancel}
                        variant='outline'
                        color='secondary'>
                        Confirm and Close
                    </Button>}
                    <Button
                        disabled={props.loading}
                        onClick={props.onConfirm}>
                        Confirm
                    </Button>
                </div>
            </div>
        </Dialog.Content>
    </Dialog>
}