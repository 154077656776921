import React from "react";
import { AppUserSignupRequestDto } from "src/backend";
import { useGetCompanyEmployeesQuery } from "src/services/companyApi";
import { editUser } from "src/slices/lender";
import { useDispatch } from "src/store";

interface Props {
    lenderId: string;
    skip?: boolean;
}

export const useLenderEmployees = ({ lenderId, skip }: Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const { data, refetch } = useGetCompanyEmployeesQuery(
        { companyId: lenderId },
        {
            skip: !lenderId || skip
        });


    const onUpdate = React.useCallback(async (user: AppUserSignupRequestDto & { id: string }) => {
        setLoading(true);
        try {
            await dispatch(editUser(user.id, user));

        } catch (e) { }
        setLoading(false);
    }, [dispatch]);

    return {
        loading,
        employees: data ?? [],
        onUpdate,
        refresh: refetch
    }
}