import * as React from "react"

export const FolderOpenIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={20}
        fill="none"
        viewBox="0 0 22 20"
        {...props}
    >
        <path
            fill="#FAC014"
            d="m21.87 10.559-3.667 7.5c-.206.425-.63.691-1.126.691H2.444C1.094 18.75 0 17.63 0 16.25V3.75c0-1.38 1.094-2.5 2.444-2.5h4.488c.648 0 1.27.263 1.729.732l1.877 1.768h5.35c1.351 0 2.445 1.12 2.445 2.5V7.5H16.5V6.25a.619.619 0 0 0-.611-.625H9.778L7.364 3.308a.595.595 0 0 0-.432-.183H2.444a.619.619 0 0 0-.61.625V15L4.55 9.441a1.219 1.219 0 0 1 1.094-.691h15.133c.905 0 1.497.977 1.092 1.809Z"
        />
    </svg>
)
