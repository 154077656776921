import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import { useState } from "react";
import { PriorityType } from "src/backend";
import { FormElementPriority } from 'src/constants/form-element';
import { themeV2 } from "src/theme/mysherpas-theme-option";
import { filterPriority, getFormElementPriorityColor, getFormElementPriorityLabel, getPriorityIcon, sortPriorities } from "src/utils/form-elements";

import { FormElementPriorityChip } from "./form-element-priority-chip";

type FormElementPriorityDropdownProps = {
    priority: PriorityType;
    disabled?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    onPriorityChange?: (priority: PriorityType) => void;
    className?: string;
    isUserABorrower?: boolean;
};

const PriorityTypeList: Record<PriorityType, PriorityType> = {
    Unset: 'Unset',
    UNSET: "UNSET",
    HIDDEN: "HIDDEN",
    PRIVATE: "PRIVATE",
    IGNORE: "IGNORE",
    BLOCKER: "BLOCKER",
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
    CRITICAL: "CRITICAL",
    URGENT: "URGENT"
}
const priorities = Object.keys(PriorityTypeList).filter(filterPriority).sort(sortPriorities);

export const FormElementPriorityDropdown = (props: FormElementPriorityDropdownProps) => {
    const { priority, disabled, onPriorityChange, isUserABorrower = false, className, onOpen, onClose } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
        onClose?.();
    };

    const handlePriorityClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const priority = event.currentTarget.dataset.priority;
        onPriorityChange?.(priority as PriorityType);
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'priority-popover' : undefined;
    // hide the priority chip if isUserABorrower is true and priority is Hidden or Private
    if (isUserABorrower &&
        (priority === FormElementPriority.Hidden || priority === FormElementPriority.Private)) {
        return null;
    }
    return <>
        <FormElementPriorityChip
            disabled={disabled}
            isUserABorrower={isUserABorrower}
            priority={priority} />
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List sx={{ py: 0, minWidth: 112 }}>
                {priorities.map((listItem: PriorityType) => (
                    <ListItem sx={{ p: 0 }} key={listItem}>
                        <ListItemButton
                            component='button'
                            data-priority={listItem}
                            onClick={handlePriorityClick}
                            sx={{
                                fontSize: 14,
                                color: themeV2.colors.blue[900],
                                textTransform: 'capitalize',
                                gap: 2,
                                display: 'flex',
                                '& svg': {
                                    color: `${getFormElementPriorityColor(listItem)}.main`,
                                }
                            }}>
                            {getPriorityIcon(listItem)}
                            {getFormElementPriorityLabel(listItem)}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Popover>
    </>;
}