import { RecipientSelectProps } from 'components/dist/organisms/RecipientSelect';
import { AppUserDTO2, BasicLoanRoleDto } from "src/backend";
import { UserAvatar } from 'src/components/user/user-avatar';

import { getInitials } from '../get-initials';
import { getRoleForRoleGroup } from '../get-role-for-role-group';
import { getUserDisplayName } from '../user/get-user-display-name';
import { isRoleABorrower } from '../user/is-role-a-borrower';

export const createRecipientList = (
    roles: Omit<BasicLoanRoleDto, 'contactRelations'>[],
    users: AppUserDTO2[],
    loggedInUser: AppUserDTO2,
    searchUsers: AppUserDTO2[]): RecipientSelectProps['options'] => {
    const options: RecipientSelectProps['options'] = [];
    const loanRoleUserIds = roles.map(role => role.user.id);
    // create contact team group
    const contactTeamUsers = roles.filter(role => role.role === 'CONTACT' && !role.keyContact).map(role => role.user);
    const contactTeamOption: RecipientSelectProps['options'][0]['options'] = contactTeamUsers.map(user => ({
        value: user.id,
        user,
        label: getUserDisplayName(user),
        isMe: user.id === loggedInUser.id,
        avatar: <UserAvatar
            size="xsm"
            avatarDocId={user.avatarDocId}
            userId={user.id}
            role={user.loggedCompanyRole}
        >
            {getInitials(getUserDisplayName(user))}
        </UserAvatar>,
        onLoan: true
    }));
    // key contact team
    const keyContactTeamUsers = roles.filter(role => role.role === 'CONTACT' && role.keyContact).map(role => role.user);
    const keyContactTeamOption: RecipientSelectProps['options'][0]['options'] = keyContactTeamUsers.map(user => ({
        value: user.id,
        user,
        label: getUserDisplayName(user),
        isMe: user.id === loggedInUser.id,
        avatar: <UserAvatar
            size="xsm"
            avatarDocId={user.avatarDocId}
            userId={user.id}
            role={user.loggedCompanyRole}
        >
            {getInitials(getUserDisplayName(user))}
        </UserAvatar>,
        onLoan: true
    }));
    // create a borrowing team group
    // based on the roles
    const loanBorrowingTeamUsers = roles.filter(role => ['BORROWER', 'LEAD_BORROWER'].includes(role.role))
        .map(role => role.user);
    const borrowingTeamOption: RecipientSelectProps['options'][0]['options'] = loanBorrowingTeamUsers.map(user => ({
        value: user.id,
        user,
        label: getUserDisplayName(user),
        isMe: user.id === loggedInUser.id,
        avatar: <UserAvatar
            size="xsm"
            avatarDocId={user.avatarDocId}
            userId={user.id}
            role={user.loggedCompanyRole}
        >
            {getInitials(getUserDisplayName(user))}
        </UserAvatar>,
        onLoan: true
    }));

    const loanLendingTeamUsers = roles.filter(role => !isRoleABorrower(role.role) && (role.visibleToBorrower || !isRoleABorrower(loggedInUser.loggedCompanyRole))).map(role => role.user);
    const lendingTeamOption: RecipientSelectProps['options'][0]['options'] = loanLendingTeamUsers.map(user => ({
        value: user.id,
        user,
        label: getUserDisplayName(user),
        isMe: user.id === loggedInUser.id,
        avatar: <UserAvatar
            size="xsm"
            avatarDocId={user.avatarDocId}
            userId={user.id}
            role={user.loggedCompanyRole}
        >
            {getInitials(getUserDisplayName(user))}
        </UserAvatar>,
        onLoan: true
    }));

    // if the user is a borrower, return the borrowing team first with label My Team
    // if user is lender return the lending team first with label My Team

    if (loggedInUser.loggedRoleGroup !== 'LENDER') {
        options.push(...[
            {
                label: 'My Team',
                onLoan: true,
                options: borrowingTeamOption
            }, {
                label: 'Lending Team',
                onLoan: true,
                options: lendingTeamOption
            },
            {
                label: 'Key Contacts',
                onLoan: true,
                options: keyContactTeamOption
            },
            {
                label: 'Contacts',
                onLoan: true,
                options: contactTeamOption
            }
        ]);
    } else {
        options.push(...[{
            label: 'My Team',
            onLoan: true,
            options: lendingTeamOption
        }, {
            label: 'Borrowing Team',
            onLoan: true,
            options: borrowingTeamOption
        },
        {
            label: 'Key Contacts',
            onLoan: true,
            options: keyContactTeamOption
        },
        {
            label: 'Contacts',
            onLoan: true,
            options: contactTeamOption
        }
        ]);
    }

    // add external users
    const externalUsers = [
        ...users.filter(user => !loanRoleUserIds.includes(user.id)),
        ...searchUsers.filter(user => !loanRoleUserIds.includes(user.id))
    ];
    const externalUsersOption: RecipientSelectProps['options'][0]['options'] = externalUsers.map(user => ({
        value: user.id,
        label: getUserDisplayName(user),
        isMe: user.id === loggedInUser.id,
        user,
        avatar: <UserAvatar
            size="xsm"
            avatarDocId={user.avatarDocId}
            userId={user.id}
            role={getRoleForRoleGroup(user.loggedRoleGroup)}
        >
            {getInitials(getUserDisplayName(user))}
        </UserAvatar>,
        onLoan: false
    }));


    options.push({
        label: 'Not on Loan',
        onLoan: false,
        options: externalUsersOption
    });

    return options;
}
