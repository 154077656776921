import { useCallback, useLayoutEffect, useRef, useState } from "react";
import type { FormElementV2ResponseDto, LoanEntityDto, LoanEntityLabel, Role, SimpleLabelDto } from "src/backend";
import { RoleTypeLabel } from "src/constants/loan";
import { LOAN_TABS } from "src/constants/query-params";
import { Permission } from "src/constants/user";
import { useUser } from "src/hooks/use-user";
import { SherpaEntityWithInformation } from "src/slices/entity";
import { EntityLabel } from "src/types/entity";
import { Loan, LoanRole } from "src/types/loan";
import { addressToString } from "src/utils/address-to-string";
import { getEntityCreditReportFormElement } from "src/utils/entity/get-entity-credit-report-form-element";
import { getEntityEditUrl } from "src/utils/entity/get-entity-edit-url";
import { getEntityRiskFlagReportFormElement } from "src/utils/entity/get-entity-risk-flag-report-form-element";
import { getDriversLicenseStatus } from "src/utils/person/get-drivers-license-status";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";
import { roleCan } from "src/utils/user/role-can";

import { LoanOverviewEntityTooltip } from "../../loan-overview-entity-tooltip";
import { LoanOverviewPersonTooltipCard } from "../../loan-overview-person-tooltip-card";
import { useLoanOverviewTeamHook } from "../../loan-overview-team/loan-overview-team.hook";
import { isLoanRoleCreditScoreEnabled, isLoanRoleDeleteEnabled, isLoanRoleRolesEnabled } from "../../loan-overview-team/loan-overview-team-list-item";
import { LoanOverviewBusinessListItemStyles } from "./loan-overview-business-list-item.styles";

interface LoanOverviewBusinessListItemProps {
    entity: SherpaEntityWithInformation & {
        formElementId?: string;
    };
    loan: Loan;
    formElements: FormElementV2ResponseDto[];
    onDelete?: (entity: SherpaEntityWithInformation) => void;
}

export const getRoleLabel = (role: Role, roles: Pick<LoanRole, 'role'>[]): SimpleLabelDto => {
    return ({
        id: role,
        title: RoleTypeLabel(role, roles),
        canBeOnBorrower: true,
        canBeOnLender: false,
    })
};

export const getBorrowerLoanRoleLabel = (entity: LoanEntityDto) => {
    if (entity.label.includes('APPLICANT')) {
        return "Borrower";
    } else if (entity.label.includes('SPOUSE')) {
        return "Spouse";
    } else if (entity.label.includes('GUARANTOR')) {
        return "Guarantor";
    } else {
        return "Member";
    }
}

export const getEntityLoanRoleLabel = (entity: LoanEntityDto): LoanEntityLabel | null => {
    if (!entity.label) {
        return null;
    }
    if (entity.label.includes('AFFILIATE')) {
        return 'AFFILIATE';
    } else if (entity.label.includes('RELATED_NON_AFFILIATE')) {
        return 'RELATED_NON_AFFILIATE';
    } else if (entity.label.includes('APPLICANT')) {
        return null;
    } else if (entity.label.includes('MEMBER')) {
        return 'MEMBER';
    } else if (entity.label.includes('SPOUSE')) {
        return 'SPOUSE';
    } else if (entity.label.includes('GUARANTOR')) {
        return null;
    } else if (entity.label.includes('FRANCHISEE')) {
        return 'FRANCHISEE';
    } else {
        return null;
    }
}


export const useLoanOverviewBusinessListItem = (props: LoanOverviewBusinessListItemProps) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const [maxLabels, setMaxLabels] = useState<number>(3);
    const userId = props.entity.userIndividualId
    const entityRiskFlagReportFormElement = getEntityRiskFlagReportFormElement(props.entity, props.formElements);
    const entityCreditReportFormElement = getEntityCreditReportFormElement(props.entity, props.formElements);
    const LinkComponent = props.entity?.formElementId ? LoanOverviewBusinessListItemStyles.LinkContainer : LoanOverviewBusinessListItemStyles.TitleContainer;
    const loanRole = props.loan.loanRoles.find(loanRole => loanRole.user.id === userId);
    const editUrl = getEntityEditUrl(props.entity,
        props.loan.id, {
        tab: LOAN_TABS.DETAILS,
        personId: loanRole?.id,
    })
    const { user } = useUser();
    const showRoleChip = loanRole?.id && !isRoleABorrower(loanRole.role) || (loanRole?.id && isRoleABorrower(loanRole.role) && !!props.entity.userIndividualId);

    const labels: string[] = [];
    // add user labels
    props.entity.userLabels
        .forEach(label => labels.push(label.title));
    // add entity labels

    const longLabels = [...labels];

    const isPocAdded = props.entity.label.includes('PRIMARY_OPERATION_COMPANY');
    props.entity.label
        .forEach(label => {
            if (label !== 'OPERATING_COMPANY' ||
                (label === 'OPERATING_COMPANY' && !isPocAdded)) {
                labels.push(EntityLabel[label]?.short ?? label)
                longLabels.push(EntityLabel[label]?.long ?? label)
            }
        });

    const {
        borrowerActionsDisabled,
        userRole,
        creditScoreEnabled,
        handleEditClick,
        handleDeleteRole,
        handleSendMessageClick,
    } = useLoanOverviewTeamHook({
        loan: props.loan,
    });

    const canSeeDriversLicense = loanRole?.id && user.id === loanRole.user.id || roleCan(userRole, Permission.SeeDriversLicense);

    const hasPendingInvite = props.loan.pendingInviteUsers?.some(pendingInvite => pendingInvite.id === loanRole?.user.id);
    const isIndividual = loanRole?.borrowerType === 'INDIVIDUAL';

    const isCreditScoreVisible = !(loanRole?.stub || hasPendingInvite) &&
        props.loan.lender.hasBorrowerOnboard &&
        isLoanRoleCreditScoreEnabled(creditScoreEnabled, loanRole, user) &&
        isIndividual &&
        loanRole?.creditConsentStatus === 'ACCEPTED';

    const tooltipComponent = !loanRole?.id ? (<LoanOverviewEntityTooltip
        onDelete={props.onDelete}
        editUrl={editUrl}
        labels={longLabels}
        entity={props.entity} />) : <LoanOverviewPersonTooltipCard
        canSeeDriversLicense={canSeeDriversLicense}
        isCreditScoreVisible={isCreditScoreVisible}
        // @ts-expect-error
        driversLicenseStatus={getDriversLicenseStatus(props?.entity.mostRecentDriversLicenseInformation?.expiration)}
        labels={longLabels}
        onSendMessageClick={handleSendMessageClick}
        driversLicenseVisible={canSeeDriversLicense}
        onDelete={isLoanRoleDeleteEnabled(borrowerActionsDisabled, loanRole, []) ? handleDeleteRole : undefined}
        onEdit={!borrowerActionsDisabled ? handleEditClick : undefined}
        rolesEnabled={isLoanRoleRolesEnabled(borrowerActionsDisabled, loanRole, []) && !loanRole.stub}
        loan={props.loan}
        role={loanRole}
        showRoleChip={showRoleChip} />;

    const recalculateLabels = useCallback(() => {
        const container = titleRef.current;
        if (!container) return;
        const isOverflowing = container.scrollWidth > container.clientWidth;
        // we need to hide some breadcrumbs
        // until it's no longer overflowing while also displaying the dropdown
        if (isOverflowing && maxLabels > 0) {
            setMaxLabels((prevState) => prevState - 1);
        }
    }, [titleRef, maxLabels]);

    useLayoutEffect(() => {
        recalculateLabels();
        window.addEventListener('resize', recalculateLabels);
        return () => window.removeEventListener('resize', recalculateLabels);
    }, [recalculateLabels]);

    return ({
        titleRef,
        entityRiskFlagReportFormElement,
        entityCreditReportFormElement,
        editUrl,
        maxLabels,
        loanRole,
        LinkComponent,
        tooltipComponent,
        labels,
        addressString: getAddressString(props.entity),
    }) as const
};

const getAddressString = (entity: SherpaEntityWithInformation) => {
    if (addressToString(entity.companyAddress)) {
        return addressToString(entity.companyAddress);
    } else if (entity.residentialAddresses?.length > 0) {
        const currentAddress = entity.residentialAddresses.find(address => address.address.currentAddress);
        if (currentAddress) {
            return addressToString(currentAddress.address);
        }
        return addressToString(entity.residentialAddresses[0].address);
    } else {
        return ''
    }
}