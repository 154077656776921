import { TaskGroup } from "src/constants/ui";
import { useGetConsolidatedTasksQuery } from "src/services/taskApi";

export const useConsolidatedTasks = ({ loanId }: { loanId: string }) => {
    const consolidatedTasks = useGetConsolidatedTasksQuery({ loanId: loanId },
        {
            skip: !loanId,
        });

    const totalUploadFileTasks = consolidatedTasks.data?.perTask?.[TaskGroup.AnswerQuestionTask] || 0;
    const totalApproveFileTasks = consolidatedTasks.data?.perTask?.[TaskGroup.ApproveAnswerTask] || 0;
    const totalFillFormTasks = consolidatedTasks.data?.perTask?.[TaskGroup.FillFormTask] || 0;
    const totalShoeboxTasks = consolidatedTasks.data?.perTask?.[TaskGroup.ShoeBoxTask] || 0;
    const totalAssignTasks = consolidatedTasks.data?.perTask?.[TaskGroup.AssignQuestionTask] > 0 ? 1 : 0;
    const totalAssignSigneeTasks = 0;
    const totalFillDocumentTasks = 0;
    const totalNonShoeboxTasks = totalUploadFileTasks +
        totalApproveFileTasks +
        totalAssignTasks +
        totalFillFormTasks +
        totalAssignSigneeTasks +
        totalFillDocumentTasks;

    const tasksCountPerUserIdMap = Object.keys(consolidatedTasks.data?.perUser ?? {}).reduce((acc, nextUser) => ({
        ...acc,
        [nextUser]: Object.entries(consolidatedTasks.data.perUser[nextUser].count).reduce((acc, [nextTask, nextCount]) => {
            if (nextTask === TaskGroup.AssignQuestionTask) {
                return acc + (nextCount > 0 ? 1 : 0)
            }
            return acc + nextCount
        }, 0)
    }), {} as Record<string, number>)

    return ({
        tasksCountPerUserIdMap,
        refetch: consolidatedTasks.refetch,
        totalUploadFileTasks,
        totalAssignTasks,
        totalApproveFileTasks,
        totalFillFormTasks,
        totalShoeboxTasks,
        totalAssignSigneeTasks,
        totalFillDocumentTasks,
        totalNonShoeboxTasks,
    }) as const
}