import { RefObject, useEffect, useState, useTransition } from 'react';


type OverflowState = 'none' | 'x' | 'y' | 'both';

const useIsOverflow = (ref: RefObject<HTMLDivElement>, stopChecking: boolean): OverflowState => {
    const [overflow, setOverflow] = useState<OverflowState>('none');
    const [, startTransition] = useTransition();

    useEffect(() => {
        const element = ref.current;

        if (!element || stopChecking) return;

        const handleResize = () => {
            startTransition(() => {
                const { scrollWidth, clientWidth, scrollHeight, clientHeight } = element;
                const hasHorizontalOverflow = scrollWidth > clientWidth;
                const hasVerticalOverflow = scrollHeight > clientHeight;

                let newOverflow: OverflowState = 'none';
                if (hasHorizontalOverflow) newOverflow = hasVerticalOverflow ? 'both' : 'x';
                else if (hasVerticalOverflow) newOverflow = 'y';
                setOverflow(newOverflow);
            });
        };

        handleResize();

        // use intersection observer to detect overflow changes
        const observer = new ResizeObserver(handleResize);
        observer.observe(element);
        window.addEventListener("resize", handleResize);
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
            observer.disconnect();
        }
    }, [ref, startTransition, stopChecking]);

    return overflow;
};

export default useIsOverflow;

