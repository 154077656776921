import Button from 'components/dist/atoms/Button';
import Drawer from 'components/dist/atoms/Drawer';
import Icon from 'components/dist/atoms/Icon';
import ProgressBar from 'components/dist/atoms/ProgressBar';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import FileIcon from 'components/dist/molecules/FileIcon';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { useEffect, useRef, useState } from 'react';
import useIsOverflow from 'src/hooks/use-is-overflow';
import { classNames } from 'src/utils/tailwind-class-names';

interface SecureUploadFormFilesListProps<T> {
    onCancelSingleFile: (listFile: T) => void;
    onStageSingleFileForDelete: (listFile: T) => void;
    onStageSingleFileForRename: (listFile: T) => void;
    onFileClick: (listFile: T) => void;
    item: T;
    className?: string;
    progress: number;
    title: string;
    documentName: string;
    status: 'uploading' | 'error' | 'success';

}

export const SecureUploadFormFilesListItem = <T extends { id: string },>(props: SecureUploadFormFilesListProps<T>) => {
    const titleReference = useRef<HTMLDivElement>(null)
    const [checkOverflow, setCheckOverflow] = useState(true)
    const isTitleOverflow = useIsOverflow(titleReference, !checkOverflow)

    useEffect(() => {
        if (['x', 'both'].includes(isTitleOverflow)) {
            setCheckOverflow(false)
        }
    }, [isTitleOverflow])

    return (<Stack row space="sm"
        justify="between"
        className={classNames(props.className)} items="center" key={props.item.id}>
        <Stack row space="sm" items="center" className='overflow-hidden flex-grow'>
            <FileIcon
                fileName={props.documentName}
                className='shrink-0' />
            <Stack
                space="sm"
                className='overflow-hidden flex-grow'>
                <Text
                    as="div"
                    onClick={() => {
                        if (props.status === 'success') {
                            props.onFileClick(props.item)
                        }
                    }}
                    className={`${props.status === 'success' ? 'cursor-pointer' : ''} flex-grow flex flex-row`}
                    variant={props.status === 'uploading' ? 'secondary' : 'primary'}
                    size="sm"
                    ref={titleReference}
                    truncate>
                    {props.title}
                </Text>
                {props.status === 'uploading' && <ProgressBar
                    className='flex'
                    height="h-1"
                    progressBackGroundColor="bg-black-20"
                    items={[
                        {
                            title: 'Uploading',
                            value: props.progress
                        }
                    ]}
                />}
            </Stack>
        </Stack>
        {props.status === 'error' && <Stack row space="none">
            <Tooltip.Provider>
                <Tooltip>
                    <Tooltip.Trigger asChild>
                        <Button
                            type="button"
                            variant="ghost"
                            size="sm">
                            <Icon name="InfoCircleSolid" className='text-destructive' width={20} height={20} strokeWidth={1.5} />
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content
                        align="end"
                        side="bottom"
                        alignOffset={0}
                    >
                        <Text size="sm">
                            File upload failed
                        </Text>
                    </Tooltip.Content>
                </Tooltip>
            </Tooltip.Provider>
        </Stack>}
        {props.status === 'success' && <Stack row space="none">
            {!["x", 'both'].includes(isTitleOverflow) && <>
                <Button
                    type="button"
                    className='hidden sm:inline-block'
                    aria-label="View"
                    onClick={() => {
                        if (props.status === 'success') {
                            props.onFileClick(props.item)
                        }
                    }}
                    variant="ghost"
                    size="sm">
                    <Icon name="EyeAlt" width={20} height={20} strokeWidth={1.5} />
                </Button>
                <Button
                    aria-label="Rename"
                    onClick={() => {
                        props.onStageSingleFileForRename(props.item)
                    }}
                    className='hidden sm:inline-block'
                    type="button"
                    variant="ghost"
                    size="sm">
                    <Icon name="Edit" width={20} height={20} strokeWidth={1.5} />
                </Button>
                <Button
                    aria-label="Delete"
                    onClick={() => {
                        props.onStageSingleFileForDelete(props.item)
                    }}
                    className='hidden sm:inline-block'
                    type="button"
                    variant="ghost"
                    size="sm">
                    <Icon name="Trash" width={20} height={20} strokeWidth={1.5} />
                </Button>
            </>}
            <ScreenSizeDropdownMenu modal={false}>
                <ScreenSizeDropdownMenu.Trigger asChild>
                    <Button
                        type="button"
                        className={classNames({
                            'sm:hidden': isTitleOverflow === 'none'
                        })}
                        variant="ghost"
                        size="sm">
                        <Icon name="MoreVert" width={20} height={20} strokeWidth={1.5} />
                    </Button>
                </ScreenSizeDropdownMenu.Trigger>
                <ScreenSizeDropdownMenu.Content
                    align="end"
                    side='bottom'

                    className='px-4 sm:w-72 max-w-full'>
                    <div className='flex flex-col w-full'>
                        <ScreenSizeDropdownMenu.Item
                            className='h-11 flex gap-2 items-center'
                            onSelect={() => {
                                if (props.status === 'success') {
                                    props.onFileClick(props.item)
                                }
                            }}>

                            <Icon name="EyeAlt" width={20} height={20} strokeWidth={1.5} />
                            <Text size="sm">View</Text>
                        </ScreenSizeDropdownMenu.Item>
                        <ScreenSizeDropdownMenu.Item
                            className='h-11 flex gap-2 items-center'
                            onSelect={() => {
                                props.onStageSingleFileForRename(props.item)
                            }}>

                            <Icon name="Edit" width={20} height={20} strokeWidth={1.5} />
                            <Text size="sm">Rename</Text>
                        </ScreenSizeDropdownMenu.Item>
                        <Separator />
                        <ScreenSizeDropdownMenu.Item
                            className='h-11 flex gap-2 items-center text-destructive'
                            onSelect={() => {
                                props.onStageSingleFileForDelete(props.item)
                            }}>
                            <Icon name="Trash" width={20} height={20} strokeWidth={1.5} />
                            <Text size="sm" variant="inherit">Delete</Text>
                        </ScreenSizeDropdownMenu.Item>
                    </div>
                </ScreenSizeDropdownMenu.Content>
            </ScreenSizeDropdownMenu>
        </Stack>}
        {props.status === 'uploading' && <Button
            type="button"
            aria-label="Cancel"
            onClick={() => {
                props.onCancelSingleFile(props.item)
            }}
            variant="ghost"
            size="sm">
            <Icon name="Cancel" width={20} height={20} strokeWidth={1.5} />
        </Button>}
    </Stack>)
}