import Button from "components/dist/atoms/Button";
import Checkbox from "components/dist/atoms/Checkbox";
import Icon from "components/dist/atoms/Icon";
import Input from "components/dist/atoms/Input";
import Label from "components/dist/atoms/Label";
import Separator from "components/dist/atoms/Separator";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import Tooltip from "components/dist/atoms/Tooltip";
import ActionAlertDialog from "components/dist/molecules/ActionAlertDialog";
import ScreenSizeTooltip from "components/dist/molecules/ScreenSizeTooltip";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { ContactSubRolesAutocomplete } from "../contact-sub-roles-autocomplete";
import { useContactsListState } from "./contacts-list.state";
import { ContactsListProps } from "./contacts-list.types";
import { ContactsListItem } from "./contacts-list-item.component";

export const ContactsList = (props: ContactsListProps) => {
    const state = useContactsListState(props);

    return <Stack className="overflow-hidden">
        <Text
            weight='medium'
            className='p-2'
            size='sm'>
            {state.dialogTitle}
        </Text>
        <Separator />
        <Stack className="px-2 pt-2 pb-7 overflow-hidden" space="default">
            <Input
                aria-label='Search Team Member Contacts'
                variant='sm'
                placeholder={state.searchPlaceholder}
                wrapClassName='focus-within:border-blue-100'
                onChange={state.onSearchChange}
                value={state.filter}
            >
                <Input.Prefix>
                    <Icon name="Search" className="text-gray-neutral-70" width={16} height={16} strokeWidth={2} />
                </Input.Prefix>
                {state.filter && <Input.Suffix>
                    <Button
                        onClick={state.onClearClick}
                        variant="ghost"
                        size='sm'
                        className="px-1 py-0 h-7">
                        <Icon name='Cancel' width={20} height={20} className='text-black-primary' strokeWidth={1.5} />
                    </Button>
                </Input.Suffix>}
            </Input>
            <Stack space='sm' className="h-64 overflow-auto scrollbar-stable">
                {state.keyContacts.length > 0 && <>
                    <Stack row space='sm'>
                        <Text size='sm' weight='medium' as='span'>Key Contacts</Text>
                        <Tooltip.Provider>
                            <ScreenSizeTooltip>
                                <ScreenSizeTooltip.Trigger >
                                    <Icon name="InfoEmpty" width={14} height={14} strokeWidth={1.5} />
                                </ScreenSizeTooltip.Trigger>
                                <ScreenSizeTooltip.Content

                                    className="max-w-sm whitespace-pre-wrap	p-2 rounded"
                                    align="start"
                                    side="bottom"
                                    alignOffset={0}
                                >
                                    <Text size="xs" weight='normal'>
                                        List of your Contacts moved to Key Contacts
                                    </Text>
                                </ScreenSizeTooltip.Content>
                            </ScreenSizeTooltip>
                        </Tooltip.Provider>
                    </Stack>
                    <Stack as='ul'>
                        {state.keyContacts.map((contact) => <ContactsListItem
                            isEditRemoveEnabled={state.isEditRemoveEnabled}
                            onEditClick={() => state.onEditClick(contact)}
                            onRemoveClick={() => state.onRemoveClick(contact.contactUser)}
                            onMoveToBorrowerClick={() => state.onMoveToBorrowerClick(contact)}
                            onSendMessageClick={() => state.onSendMessageClick(contact.contactUser)}
                            onMoveToKeyContactClick={() => state.onMoveToKeyContactClick(contact)}
                            onMoveToContactClick={() => state.onMoveToContact(contact)}
                            key={contact.contactUser.id}
                            contact={contact} />)}
                    </Stack>
                </>}
                {!!state.contacts.length && !!state.keyContacts.length && <Separator />}
                {(state.contacts.length + state.keyContacts.length) === 0 && <Stack space='md' items='center' justify='center' className='flex-1'>
                    <Icon name="BigExclamation" width={64} height={64} strokeWidth={1.5} className='text-black-10' />
                    <Text size='sm'>User not found</Text>
                </Stack>}
                {state.contacts.length > 0 && <>
                    <Text size='sm' weight='medium'>Contacts</Text>
                    <Stack as='ul'>
                        {state.contacts.map((contact) => <ContactsListItem
                            isEditRemoveEnabled={state.isEditRemoveEnabled}
                            onEditClick={() => state.onEditClick(contact)}
                            onRemoveClick={() => state.onRemoveClick(contact.contactUser)}
                            onMoveToContactClick={() => state.onMoveToContact(contact)}
                            onMoveToBorrowerClick={() => state.onMoveToBorrowerClick(contact)}
                            onSendMessageClick={() => state.onSendMessageClick(contact.contactUser)}
                            onMoveToKeyContactClick={() => state.onMoveToKeyContactClick(contact)}
                            key={contact.contactUser.id}
                            contact={contact} />)}
                    </Stack>
                </>}
            </Stack>
        </Stack>
        {state.confirmationState.operation === 'MOVE_TO_KEY_CONTACT' && state.confirmationState.value && <ActionAlertDialog
            variant='warning'
            open
            confirmButtonText='Continue'
            onOpenChange={state.onRemoveCancel}
            onConfirm={() => state.confirmationState.operation === 'MOVE_TO_KEY_CONTACT' && state.onMoveContactToKeyContactConfirmation(state.confirmationState.value)}
            message={`You’re moving ${getUserDisplayName(state.confirmationState.value.contactUser)} from your contact to a key contact on the loan. Are you sure you want to continue?`}
        />}
        {state.confirmationState.operation === 'MOVE_TO_KEY_CONTACT_SET_CONTACT_ROLE' && state.confirmationState.value && <ActionAlertDialog
            variant='warning'
            open
            onOpenChange={state.onRemoveCancel}
            confirmButtonText='Save and Continue'
            cancelButtonText="Cancel"
            onConfirm={() => state.confirmationState.operation === 'MOVE_TO_KEY_CONTACT_SET_CONTACT_ROLE' && state.onMoveContactToKeyContactConfirmation(state.confirmationState.value, state.contactFormValues)}
            message={<Stack space='lg' justify="start" items='start'>
                <Text size='sm'>
                    You’re moving {getUserDisplayName(state.confirmationState.value.contactUser)} from your contact to a key contact on the loan. Do you want to set the key contact role?
                </Text>
                <Stack space='sm' className="w-full">
                    <ContactSubRolesAutocomplete
                        hideTeamRoles
                        lenderId={state.lenderId}
                        loanRoleId={state.contactFormValues?.contactSubRoleId}
                        onContactRoleSelected={contactSubRole => {
                            state.onContactFormValuesChange('contactSubRoleId', contactSubRole.id);
                            state.onContactFormValuesChange('contactSubRoleTitle', contactSubRole.title);
                        }}
                    />
                    <Stack asChild space='sm' row items='center'>
                        <Label fontSize='xs'>
                            <Checkbox
                                checked={state.contactFormValues?.visibleToBorrower}
                                onCheckedChange={checked => state.onContactFormValuesChange('visibleToBorrower', checked)}
                                size='sm' /> Visible to Borrowers
                        </Label>
                    </Stack>
                </Stack>
            </Stack>}
        />}
    </Stack>
};