import Button from "components/dist/atoms/Button"
import Checkbox from "components/dist/atoms/Checkbox"
import Dialog from "components/dist/atoms/Dialog"
import Icon from "components/dist/atoms/Icon"
import Label from "components/dist/atoms/Label"
import Separator from "components/dist/atoms/Separator"
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import FileIcon from "components/dist/molecules/FileIcon"
import { useState } from "react"
import { FormElementV2ResponseDtoExtended } from "src/types/formelement"

interface EditFormElementMultipleEntitiesConfirmDialogProps {
    elements: Pick<FormElementV2ResponseDtoExtended, 'id' | 'title' | 'answer'>[]
    onCancel: () => void
    loading: boolean
    onConfirm: (ids: string[]) => void
    targetEntityName: string
}

export const EditFormElementMultipleEntitiesConfirmDialog = (props: EditFormElementMultipleEntitiesConfirmDialogProps) => {
    const [checkedElementsIds, setCheckedElementsIds] = useState<string[]>(
        props.elements.map(e => e.id)
    );

    const onCheckElement = (elementId: string) => {
        setCheckedElementsIds(prev => {
            if (prev.includes(elementId)) {
                return prev.filter(id => id !== elementId)
            }
            return [...prev, elementId]
        })
    }

    const onConfirm = () => {
        props.onConfirm(checkedElementsIds)
    }

    return <Dialog
        onOpenChange={open => {
            if (!open) {
                props.onCancel()
            }
        }}
        open
    >
        <Dialog.Content
            withoutPortal
            className="h-full sm:h-auto sm:max-w-3xl gap-1 overflow-hidden">
            <Dialog.Title className="text-sm px-5 pt-4 font-normal flex flex-row items-center gap-2">
                <Icon name="BigExclamationDestructive" width={32} height={32} />  You’re changing {props.elements.length === 1 ? `${props.elements[0].title} entity` : `${props.elements.length} items entities`} to {props.targetEntityName}. Confirm and Save to Continue
            </Dialog.Title>
            <Separator />
            <Stack space="md" className="h-full sm:h-96 scrollbar-stable overflow-x-hidden overflow-y-auto max-h-full px-4 py-6">
                <Label className="gap-2 flex flex-row items-center text-sm">
                    <Checkbox
                        checked={(() => {
                            // if all are checked return true
                            // if some are checked return indeterminate
                            // if none are checked return false
                            if (checkedElementsIds.length === 0) {
                                return false
                            } else if (checkedElementsIds.length === props.elements.length) {
                                return true
                            } else {
                                return 'indeterminate'
                            }
                        })()}
                        onCheckedChange={checked => {
                            if (checked) {
                                setCheckedElementsIds(props.elements.map(e => e.id))
                            } else {
                                setCheckedElementsIds([])
                            }
                        }}
                        size='sm' />
                    Select all
                </Label>
                <Stack space="md" className="pl-4 pb-4">
                    {props.elements
                        .map(element => (<Stack
                            row
                            className="overflow-hidden"
                            space="sm"
                            items='center'
                            key={element.id}>
                            <Checkbox
                                checked={checkedElementsIds.includes(element.id)}
                                onCheckedChange={() => onCheckElement(element.id)}
                                size='sm' />
                            <FileIcon fileName={element.answer?.document?.name} />
                            <Text
                                truncate
                                size='sm'>
                                {element.title}
                            </Text>
                        </Stack>))}
                </Stack>
            </Stack>
            <Dialog.Footer className="justify-between sm:justify-between">
                <Dialog.Close asChild>
                    <Button
                        disabled={props.loading}
                        variant="outline">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button
                    loading={props.loading}
                    onClick={onConfirm}
                    disabled={checkedElementsIds.length === 0}
                >
                    Confirm and Save
                </Button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>
}