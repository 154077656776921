import type { EntityResponseDto, FormElementV2ResponseDto } from "src/backend";

export const getEntityRiskFlagReportFormElement = (entity: EntityResponseDto, formElements: FormElementV2ResponseDto[]): FormElementV2ResponseDto => {
    if (!entity || !formElements || formElements.length === 0) {
        return null;
    }
    const riskFlagFormElement = formElements.find((formElement) => ['BUSINESS_QUICK_SEARCH_FLAG_REPORT', 'PERSON_QUICK_SEARCH_FLAG_REPORT'].includes(formElement.knowledgeBase) &&
        formElement.sherpaEntityId === entity.id);

    if (!riskFlagFormElement || !riskFlagFormElement.answer) {
        null;
    }

    return riskFlagFormElement;
}