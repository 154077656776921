import * as React from "react"

export const BuildingIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={21}
        fill="none"
        viewBox="0 0 20 21"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8.333 8.008 8.342 8m3.325.01.008-.01m-3.342 3.343.009-.01m3.325.01.008-.01m-3.342 3.343.009-.01m3.325.01.008-.01M5 17.5V5.167a.5.5 0 0 1 .5-.5H10V3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5Z"
        />
    </svg>
)
