import { endOfDay, isPast, parseISO, sub } from "date-fns";

export const getDriversLicenseStatus = (expiration: string): 'EXPIRED' | 'EXPIRING' | 'ACTIVE' | 'DEFAULT' => {
    // if it's never been set
    if (!expiration) {
        return 'DEFAULT';
    }
    // if it's expired using date-fns
    const expirationDate = parseISO(expiration);
    if (isPast(endOfDay(expirationDate))) {
        return 'EXPIRED';
    }
    // if it expires in the next 90 days using date-fns
    if (isPast(endOfDay(sub(expirationDate, { days: 90 })))) {
        return 'EXPIRING';
    }
    // if it's active
    return 'ACTIVE';
}