import Icon from 'components/dist/atoms/Icon';
import Separator from "components/dist/atoms/Separator";
import Text from 'components/dist/atoms/Text';

import { NeedsListContainerStyles } from "./needs-list-container.styles";

interface NeedsListContainerProps {
    title: string;
    preview?: boolean;
    autoHeight?: boolean;
}
export const NeedsListContainer = (props: React.PropsWithChildren<NeedsListContainerProps>) => {
    return <NeedsListContainerStyles.needsListContainer>
        <NeedsListContainerStyles.TitleTypography>
            {props.title} Needs List <span>
                {props.preview ? '(Preview)' : ''}
            </span>
        </NeedsListContainerStyles.TitleTypography>
        <Separator />
        {!props.children && <div
            className="flex-1 items-center justify-center flex flex-col gap-3 h-[600px] overflow-auto">
            <div className="rounded-full w-10 h-10 bg-black-20 flex items-center justify-center">
                <Icon name="DocumentUpload" width={18} height={18} />
            </div>
            <Text size="sm">
                Needs list will show here
            </Text>
        </div>}
        {props.children && <div className={!props.autoHeight ? "h-[600px] overflow-auto scrollbar-stable" : ""}>
            {props.children}
        </div>}
    </NeedsListContainerStyles.needsListContainer>
}