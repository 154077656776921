import { useConfirmUnsavedChanges } from "src/hooks/use-confirm-unsaved-changes";

const UnsavedChangesMessage = 'You have unsaved changes. Are you sure you want to leave this page?';

interface ConfirmUnsavedChangesDialogProps {
    hasUnsavedChanges: boolean;
    message?: string;
    cancelText?: string;
    confirmText?: string;
    onRouteChange?: () => void;
}

export const ConfirmUnsavedChangesDialog = (props: ConfirmUnsavedChangesDialogProps) => {
    const { hasUnsavedChanges, message, onRouteChange } = props;
    const { AlertComponent } = useConfirmUnsavedChanges(hasUnsavedChanges,
        (confirm) => {
            if (confirm) {
                onRouteChange?.();
            }
        },
        message ?? UnsavedChangesMessage,
        {
            cancelText: "Stay",
            confirmText: "Leave",
            title: "Changes made"
        }
    );


    return <>
        {AlertComponent}
    </>
}