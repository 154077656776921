import Error from "@mui/icons-material/Error";
import Badge from '@mui/material/Badge';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import React, { useMemo } from "react";
import { api } from "src/api";
import { CardStatus } from "src/constants/loan";
import { reGenerateCreditReport } from "src/slices/loan";
import { useDispatch } from "src/store";
import { getCreditScoreLevel } from "src/utils/person/get-credit-score-color"
import { classNames } from "src/utils/tailwind-class-names";

import { MysherpasTooltip } from "./common/mysherps-tooltip/mysherpas-tooltip.component";

type Props = {
    loanId: string;
    userId: string;
    creditScore: number;
    creditReportError: string;
    softCreditStatus: string;
}

export const CreditScoreChip = (props: Props) => {
    const dispatch = useDispatch();
    const [regenerateTimeout, setRegenerateTimeout] = React.useState<number>(0);
    const timeoutReference = React.useRef<ReturnType<typeof setTimeout>>();

    const handleRegenerateCreditReport = async () => {
        const individual = await api.getIndividualFromUser(props.userId);
        dispatch(reGenerateCreditReport({
            loanId: props.loanId,
            individualId: individual.id,
        }));
        if (timeoutReference.current !== null) {
            setRegenerateTimeout(1)
            clearTimeout(timeoutReference.current)
            timeoutReference.current = setTimeout(() => {
                setRegenerateTimeout(0)
            }, 10_000)
        }
    }

    const isErrored = ([CardStatus.PROCESSED_ERROR, CardStatus.PROCESSED_INCOMPLETE, 'ERROR'] as string[]).includes(props.softCreditStatus);
    const errorMessage = useMemo(() => {
        if (isErrored) {
            try {
                // @ts-ignore
                const errorObject = JSON.parse(props.creditReportError || '{}')
                return errorObject?.description;
            } catch (e) {
                return 'Error'
            }
        }
        return ''
        // @ts-ignore
    }, [isErrored, props.creditReportError])

    const isTimeoutActive = regenerateTimeout > 0;
    const isGenerationInProgress = isTimeoutActive //|| softCreditPull?.consentStatus === CardStatus.PROCESSING;

    return <>
        {Boolean(props.creditScore) && <MysherpasTooltip title={getCreditScoreLevel(props.creditScore).label}>
            <div
                className='bg-blue-100 rounded flex flex-col text-center text-xs p-1 font-medium text-white'>
                FICO
                <div
                    className='px-1 py-0 rounded-none m-0 bg-white min-w-9'
                    style={{
                        color: getCreditScoreLevel(props.creditScore).color,
                    }}>
                    {props.creditScore}
                </div>
            </div>
        </MysherpasTooltip>}
        {!Boolean(props.creditScore) &&
            <MysherpasTooltip title={!!errorMessage ? <div dangerouslySetInnerHTML={{ __html: errorMessage }} /> : 'Regenerate Credit Report'}>
                <Badge
                    badgeContent={(!isGenerationInProgress && isErrored) ? <Error color='warning' /> : null}>
                    <div
                        className={classNames('bg-blue-100 font-medium flex p-0.5 text-white text-[10px] rounded-sm text-center flex-col')}
                    >
                        FICO
                        <Button
                            loading={isGenerationInProgress}
                            color={(isErrored && !isTimeoutActive) ? 'error' : "secondary"}
                            type='button'
                            size='sm'
                            variant='ghost'
                            className={classNames("h-3.5 min-w-9 px-1 py-0 rounded-sm bg-white text-black-primary", {
                                'text-destructive': (!isGenerationInProgress && isErrored)
                            })}

                            onClick={handleRegenerateCreditReport}>
                            <Icon name="Refresh" width={10} height={10} strokeWidth={2} />
                        </Button>
                    </div>
                </Badge>
            </MysherpasTooltip>}
    </>
}