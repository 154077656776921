import React from "react";

export const useHasValuesCallback = (values: Record<string, any | any[]>, dirty: boolean, callback?: (dirty: boolean) => void): boolean => {
    const [hasValues, setHasValues] = React.useState(false);
    React.useEffect(() => {
        const hasValues = Object.values(values).some(value => {
            if (Array.isArray(value)) {
                return value.length > 0;
            } else if (typeof value === 'string') {
                return !!value;
            }

            return false;
        });

        if (dirty && hasValues) {
            callback?.(true);
            setHasValues(true);
        } else {
            callback?.(false);
            setHasValues(false);
        }
    }, [values, callback, dirty]);

    return hasValues;
};