// convert 10 digits phone number to format (xxx) xxx-xxxx
export const convertPhone = (value: string) => {
    const textValue = String(value);
    // use regex to check if string has only digits
    const regExp = new RegExp(/^\d+$/);

    //check value has only digits
    if (!regExp.test(textValue) || textValue.length !== 10) {
        return value;
    }
    return textValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}