import { AppUserDTO2, LoanRoleDto } from "src/backend";
import { RoleType } from "src/constants/loan";

export const isLoanRoleTasksDisabled = (tasksDisabled: boolean, role: LoanRoleDto, user: AppUserDTO2) => {
    return tasksDisabled && role.user.id !== user.id
}

export const isLoanRoleDeleteEnabled = (actionsDisabled: boolean, role: LoanRoleDto, roles: LoanRoleDto[]) => {
    const hasManyLeadLenders = roles.filter((role) => role.role === RoleType.LeadLender).length > 1;

    return !actionsDisabled && !!role.id && ((hasManyLeadLenders && role.role === RoleType.LeadLender) || role.role !== RoleType.LeadLender)
}

export const isLoanRoleCreditScoreEnabled = (creditScoreEnabled: boolean, role: LoanRoleDto, user: AppUserDTO2) => {
    if (!role) {
        return false;
    }
    return (creditScoreEnabled || role.user.id === user.id) && !!role.id
}

export const isLoanRoleRolesEnabled = (actionsDisabled: boolean, role: LoanRoleDto, roles: LoanRoleDto[]) => {
    const hasManyLeadLenders = roles.filter((role) => role.role === RoleType.LeadLender).length > 1;

    return !actionsDisabled &&
        role.role !== RoleType.Underwriter &&
        !!role.id &&
        ((hasManyLeadLenders && role.role === RoleType.LeadLender) || role.role !== RoleType.LeadLender)
}

