import Avatar from "components/dist/atoms/Avatar";
import Button from "components/dist/atoms/Button";
import HighlightText from "components/dist/atoms/HighlightText";
import Icon from "components/dist/atoms/Icon";
import Input from "components/dist/atoms/Input";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import { getInitials } from "src/utils/get-initials";
import { classNames } from "src/utils/tailwind-class-names";

import { SelectUserAutocompleteProps, useSelectUserAutocompleteState } from "./select-user-autocomplete.state";


export const SelectUserAutocomplete = (props: SelectUserAutocompleteProps) => {
    const state = useSelectUserAutocompleteState(props);

    return <div>
        <Stack
            space="sm"
            className="relative overflow-visible group w-full">
            <Input
                value={state.inputValue}
                onChange={state.onInputChange}
                wrapClassName='w-full group-focus-within:border-blue-100 text-sm'
                placeholder='Search' >
                <Input.Prefix>
                    <Icon name='Search' width={18} height={18} strokeWidth={2} className='text-gray-neutral-70' />
                </Input.Prefix>
                <Input.Suffix>
                    <Button
                        variant="ghost" size='sm' className="px-1">
                        <Icon name='NavArrowDown' width={20} height={20} className='text-black-primary' strokeWidth={1.5} />
                    </Button>
                </Input.Suffix>
            </Input>
            <ul
                className={classNames('group-focus-within:flex [&>_div]:w-full h-64 overflow-auto scrollbar-stable flex-col z-20 hidden absolute top-[54px] w-full bg-white shadow border border-gray-neutral-80 p-2 rounded-md', {
                    '': state.inputValue.length > 0
                })}>
                {state.users.length === 0 && <li className='p-0 gap-3 flex flex-col w-full overflow-hidden'>
                    <Text size='xs' variant='secondary'>User does not exist</Text>
                </li>}
                {state.users.map(user => <li
                    tabIndex={0}
                    className={classNames("overflow-hidden flex flex-row focus:bg-gray-table-header cursor-pointer gap-2 w-full text-sm p-2 hover:bg-gray-table-header rounded-md", {
                        'bg-gray-table-header': state.selectedUser?.id === user.id
                    })}
                    onClick={() => state.onUserSelected(user)}
                    key={user.id}>
                    <Avatar size='xs'>
                        <Avatar.Fallback>
                            {getInitials(user.displayName)}
                        </Avatar.Fallback>
                    </Avatar>
                    <Text size='sm' truncate>
                        <HighlightText text={user.displayName} highlightText={state.inputValue} />
                    </Text>
                </li>)}
            </ul>
        </Stack>
        <div>
            <Button
                onClick={props.onAddNewClick}
                className="text-left justify-start px-0" variant='link'>
                <Icon name="Plus" width={20} height={20} strokeWidth={1.5} />
                Add New
            </Button>
        </div>
    </div>
}
