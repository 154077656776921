import { useEffect } from "react";
import { SimpleLabelDto } from "src/backend";
import { getLabelsAvailableToMe, putLabelOnSomeone, selectLabelsAvailableToMe } from "src/slices/lender"
import { useDispatch, useSelector } from "src/store"

interface LabelsAvailableToMeProps {
    loanId: string;
}

interface CompanyLabelState {
    labels: SimpleLabelDto[];
    applicantLabels: SimpleLabelDto[];
    lenderLabels: SimpleLabelDto[];
    putLabelOnRole: (labels: SimpleLabelDto[], roleId: string) => Promise<void>;
}

export const useLabelsAvailableToMe = (props: LabelsAvailableToMeProps): CompanyLabelState => {
    const labels = useSelector(selectLabelsAvailableToMe(props.loanId));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!!props.loanId) {
            dispatch(getLabelsAvailableToMe({ loanId: props.loanId }));
        }
    }, [dispatch, props.loanId]);

    const handlePutLabelOnRole = async (labels: SimpleLabelDto[], roleId: string) => {
        await dispatch(putLabelOnSomeone({
            loanId: props.loanId,
            labels: labels,
            roleId
        }))
    }
    return {
        labels,
        applicantLabels: labels.filter(l => l.canBeOnBorrower),
        lenderLabels: labels.filter(l => l.canBeOnLender),
        putLabelOnRole: handlePutLabelOnRole
    }
}