import { TeamMemberSelectListAssignedListItem } from "./team-member-select-list.types";

interface UsersSelectDialogState {
    loading: boolean;
    open: boolean;
    unassignedChecked: boolean;
    assignedList: TeamMemberSelectListAssignedListItem[];
    searchQuery: string;
}

export const teamMemberSelectListInitialState: UsersSelectDialogState = {
    loading: false,
    open: false,
    unassignedChecked: false,
    assignedList: [],
    searchQuery: "",
};

enum ActionType {
    LOADING = "LOADING",
    LOADED = "LOADED",
    OPEN = "OPEN",
    CLOSE = "CLOSE",
    SET_ASSIGNED_LIST = "SET_ASSIGNED_LIST",
    SET_SEARCH_QUERY = "SET_SEARCH_QUERY",
    SET_UNASSIGNED_CHECKED = "SET_UNASSIGNED_CHECKED",
};

type Action =
    | { type: typeof ActionType.LOADING }
    | { type: typeof ActionType.LOADED }
    | { type: typeof ActionType.OPEN }
    | { type: typeof ActionType.CLOSE }
    | { type: typeof ActionType.SET_ASSIGNED_LIST; payload: TeamMemberSelectListAssignedListItem[] }
    | { type: typeof ActionType.SET_SEARCH_QUERY; payload: string }
    | { type: typeof ActionType.SET_UNASSIGNED_CHECKED; payload: boolean };

export const teamMemberSelectListReducer = (state = teamMemberSelectListInitialState, action: Action): UsersSelectDialogState => {

    switch (action.type) {
        case ActionType.OPEN:
            return {
                ...state,
                open: true,
            };
        case ActionType.CLOSE:
            return {
                ...state,
                open: false,
            };
        case ActionType.SET_ASSIGNED_LIST:
            return {
                ...state,
                assignedList: action.payload,
            };
        case ActionType.SET_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: action.payload,
            };
        case ActionType.LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionType.LOADED:
            return {
                ...state,
                loading: false,
            };
        case ActionType.SET_UNASSIGNED_CHECKED:
            return {
                ...state,
                unassignedChecked: action.payload,
            };
        default:
            return state;
    }
}

export const teamMemberSelectListActions = {
    open: (): Action => ({
        type: ActionType.OPEN,
    }),
    close: (): Action => ({
        type: ActionType.CLOSE,
    }),
    setAssignedList: (assignedList: TeamMemberSelectListAssignedListItem[]): Action => ({
        type: ActionType.SET_ASSIGNED_LIST,
        payload: assignedList,
    }),
    setSearchQuery: (searchQuery: string): Action => ({
        type: ActionType.SET_SEARCH_QUERY,
        payload: searchQuery,
    }),
    loading: (): Action => ({
        type: ActionType.LOADING,
    }),
    loaded: (): Action => ({
        type: ActionType.LOADED,
    }),
    setUnassignedChecked: (checked: boolean): Action => ({
        type: ActionType.SET_UNASSIGNED_CHECKED,
        payload: checked,
    }),
};
