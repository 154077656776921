import * as Yup from 'yup';

export const formElementFormValidation = Yup
    .object()
    .shape({
        title: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(255),
        expireDate: Yup
            .date()
            // only future dates
            .min(new Date(), "Must be a future date")
            .nullable(),
        storageType: Yup
            .string()
            .required("Required")
            .oneOf(["FILE", "FOLDER"])
            .nullable(),
    });
