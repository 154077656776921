import { styled } from '@mui/material/styles';
import Link from "src/components/common/next-link-composed";


const Title = styled("div")(({ theme }) => ({
    flex: 1,
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    overflow: 'hidden',
    '&::first-letter': {
        textTransform: 'capitalize',
    },
    gap: theme.spacing(1),
    flexWrap: 'wrap',
}));

const TitleContainer = styled("div")(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    minWidth: 0,
}));


const LinkContainer = styled(Link)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    color: theme.palette.text.primary,
    minWidth: 0,
    '&:hover': {
        textDecoration: 'none',
    },
}));

export const LoanOverviewBusinessListItemStyles = {
    Title,
    LinkContainer,
    TitleContainer
}