import { Role, RoleLenderGroupLevel } from "src/backend";

export const getRoleForRoleGroup = (roleGroup: RoleLenderGroupLevel): Role => {
    if (roleGroup === 'BORROWER') {
        return 'BORROWER';
    } else if (roleGroup === 'CONTACT') {
        return 'CONTACT';
    } else {
        return 'LENDER';
    }
};