import React from 'react';
import { AppUserDTO2 } from 'src/backend';
import { useUser } from 'src/hooks/use-user';
import { mapSharedInfoToAssignListItem } from 'src/utils/form-element/map-shared-info-to-assign-list-item';

import { TeamMemberSelectListAssignedListItem } from '../team-member-select-list/team-member-select-list.types';
import { UsersSelectDialogProps } from "./users-select-dialog.types";

export const useUsersSelectDialog = (props: UsersSelectDialogProps) => {
    const userState = useUser();
    const [checkedUsers, setCheckedUsers] = React.useState<AppUserDTO2[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(props.open || false);

    const assignedList: TeamMemberSelectListAssignedListItem[] = mapSharedInfoToAssignListItem({
        loanRoles: props.loanRoles,
        sharedInfo: props.elements
            .map(element => element.sharedInfo).flat(),
        loggedInUserId: userState.user.id
    });


    // when the assign button is clicked, call the callback with the assigned list
    const onAssignClick = async () => {
        try {
            setLoading(true);
            const removedUsers = assignedList
                // filter the users that are still in the assigned list state
                .filter(assigned => !checkedUsers.some(user => user.id === assigned.user.id))
                .map(assigned => assigned.user);

            const usersIds = checkedUsers
                // and not already in the assigned list
                .filter(alreadyChecked => !assignedList.some(assignedList => assignedList.user.id === alreadyChecked.id))

            if (removedUsers.length) {
                await props.onUnSelect(removedUsers.map(user => user.id));
                await props.onUnSelectUsers?.(removedUsers);
            }
            if (usersIds.length > 0) {
                await props.onSelect(usersIds.map(user => user.id));
                await props.onSelectUsers?.(usersIds);
            }
        }
        catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsDialogOpen(false);
        }
    };

    const onUsersSelected = async (users: AppUserDTO2[]) => {
        setCheckedUsers(users);
    };

    const onOpenChange = (open: boolean) => {
        props.onOpenChange?.(open);
        setIsDialogOpen(open);
        if (!open) {
            setCheckedUsers([]); // reset the checked ids when the dialog is closed
            setLoading(false);
        }
    }

    return {
        onAssignClick,
        onUsersSelected,
        onOpenChange,
        open: isDialogOpen,
        loading: loading,
        assignedList,
    } as const;
};