import Stack from 'components/dist/atoms/Stack';
import CopyButton from "components/dist/molecules/CopyButton"

export const CopyContainer = (props: { children: React.ReactNode, text: string | number }) => {
    return (<Stack
        row
        className='overflow-hidden'>
        <Stack row space='sm' items='center' className='hover:bg-gray-table-header rounded-md group/copy h-8 cursor-default overflow-hidden'>
            {props.children}
            <CopyButton text={String(props.text)} size="sm" className='px-0 group-hover/copy:px-3 w-0 group-hover/copy:w-auto overflow-hidden' />
        </Stack>
    </Stack>)
}