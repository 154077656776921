import { useSearchUsersQuery } from "src/services/userApi";

import { useAuth } from "./use-auth";

interface UserBorrowerProps {
    skip: boolean;
}

export const useSearchUsers = (props: UserBorrowerProps) => {
    const { user } = useAuth();
    const { data: users = [], isLoading } = useSearchUsersQuery({ slice: '' }, {
        skip: props.skip || user.loggedRoleGroup !== 'LENDER'
    });


    return { borrowers: users, loading: isLoading };
}