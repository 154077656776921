import * as Yup from 'yup';

export const formElementFormValidation = Yup.object()
    .shape({
        title: Yup
            .string()
            .max(250)
            .nullable(true)
            .required('Required'),
        description: Yup
            .string()
            .max(250)
            .nullable(true),
        parentId: Yup
            .string()
            .required('Required')
            .nullable(true),
        entityId: Yup
            .string()
            .when("isEditingEntityEnabled", {
                is: true,
                then: Yup.string().required('Required'),
                otherwise: Yup.string().nullable(true)
            })
            .nullable(true),
        dueDate: Yup
            .date()
            .nullable(),
    })
