import type { EntityResponseDto, FormElementV2ResponseDto } from "src/backend";

export const getEntityCreditReportFormElement = (entity: EntityResponseDto, formElements: FormElementV2ResponseDto[]): FormElementV2ResponseDto => {
    if (!entity || !formElements || formElements.length === 0 || entity.type !== 'INDIVIDUAL') {
        return null;
    }
    const creditReportFormElement = formElements.find((formElement) => ['CREDIT_REPORT'].includes(formElement.knowledgeBase) &&
        formElement.sherpaEntityId === entity.id);

    if (!creditReportFormElement || !creditReportFormElement.answer) {
        null;
    }

    return creditReportFormElement;
}