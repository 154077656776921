import { ListItem } from "./lists";

export const BlockType = {
    None: 'NO_BLOCK',
    Loan: 'LOAN',
    Entity: 'ENTITY',
} as const;

export const LoanViewType = {
    None: 'NO_VIEW',
} as const;


export const TemplateQueryParam = {
    templateId: 'templateId',
}

export const LoanEntityLabel = {
    Applicant: 'APPLICANT',
    PrimaryCollateral: 'PRIMARY_COLLATERAL',
    Member: 'MEMBER',
} as const;

const EntityModifierType = {
    ALL: "ALL",
    NONE: "NONE",
    APPLICANT: "APPLICANT",
    NON_APPLICANT: "NON_APPLICANT",
    GUARANTOR: "GUARANTOR",
    SPOUSE: "SPOUSE",
    NEW: "NEW",
    FRANCHISEE: "FRANCHISEE",
    AFFILIATE: "AFFILIATE",
    RELATED_NON_AFFILIATE: "RELATED_NON_AFFILIATE",
    RELATED_ENTITY: "RELATED_ENTITY",
};

export const entityModifiers: ListItem[] = Object.keys(EntityModifierType).map(key => ({
    label: key,
    value: key,
}));