import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { themeV2 } from "src/theme/mysherpas-theme-option";

const Header = styled('div')(({ theme }) => ({
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const Logo = styled('div')(({ theme }) => ({
    width: 48,
    height: 48,
    backgroundColor: themeV2.neutral.grey[10],
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const DetailsList = styled(List)(({ theme }) => ({
    color: themeV2.colors.blue[900],
    '& .MuiListItemText-primary': {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: themeV2.colors.blue[900],
        fontSize: theme.typography.pxToRem(14),
    }
}));

export const LoanOverviewEntityTooltipStyles = {
    Header,
    Logo,
    DetailsList
}