import { useEffectAtMount } from 'polyrhythm-react';
import { useEffect, useState } from 'react';
import { useSubscription } from "react-stomp-hooks";
import { TemplateResponseDto } from 'src/backend';
import { LoanStatus } from 'src/constants/loan';
import { BlockType } from 'src/constants/template';
import { useMounted } from 'src/hooks/use-mounted';
import { findByEntityTypeAndLoanId, getV2Templates, v2TemplatesSelector } from 'src/slices/templatev2';
import { updateLoanStatus } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';

import { useActiveLoan } from './use-active-loan';
import { useUser } from './use-user';

const isTemplateACollateral = (template: TemplateResponseDto) => template.sherpaEntityType.includes("ASSET");

const isATrustTemplate = (template: TemplateResponseDto) => 'TRUST' === template.sherpaEntityType;

const isACompanyTemplate = (template: TemplateResponseDto) => 'COMPANY' === template.sherpaEntityType;

const isAnEntityTemplate = (template: TemplateResponseDto) => ([
    'COMPANY',
    'INDIVIDUAL',
    'TRUST',
] as string[]).includes(template.sherpaEntityType);

const isNonApplicantTemplate = (template: TemplateResponseDto) => template.entityModifiers.includes('NON_APPLICANT');
const isNullTemplate = (template: TemplateResponseDto) => template.entityModifiers === null;
const isEmptyTemplate = (template: TemplateResponseDto) => template.entityModifiers.length === 0;
const isApplicantTemplate = (template: TemplateResponseDto) => template.entityModifiers.includes('APPLICANT');
const isAllTemplate = (template: TemplateResponseDto) => template.entityModifiers.includes('ALL');

const isApplicantEntityTemplate = (template: TemplateResponseDto) => isNullTemplate(template) ||
    isEmptyTemplate(template) ||
    isAllTemplate(template) ||
    isApplicantTemplate(template);

const isNonApplicantEntityTemplate = (template: TemplateResponseDto) => isNullTemplate(template) ||
    isEmptyTemplate(template) ||
    isAllTemplate(template) ||
    isNonApplicantTemplate(template);

const isAnIndividual = (template: TemplateResponseDto) => 'INDIVIDUAL' === template.sherpaEntityType;

export const useLoanTemplates = (): {
    all: TemplateResponseDto[];
    templates: TemplateResponseDto[];
    blocks: TemplateResponseDto[];
    assets: TemplateResponseDto[];
    applicantEntities: TemplateResponseDto[];
    entities: TemplateResponseDto[];
    companies: TemplateResponseDto[];
    individuals: TemplateResponseDto[];
    trusts: TemplateResponseDto[];
    onDelete: (id: string) => void;
    onConfirmDelete: (id: string) => void;
    onCancelDelete: () => void;
    onCompanyIdFilterChange: (companyId: string) => void;
    templateIdToDelete: string;
} => {
    const [tempDeletedTemplatesIds, setTempDeletedTemplatesIds] = useState<string[]>([]);
    const [templateIdToDelete, setTemplateIdToDelete] = useState<string | null>(null);
    const [companyIdFilter, setCompanyIdFilter] = useState<string | null>(null);
    const activeLoanState = useActiveLoan();
    const userState = useUser({
        loan: activeLoanState.loan,
    });
    const isMounted = useMounted();
    const dispatch = useDispatch()
    const templates = useSelector(v2TemplatesSelector());

    const handleDeleteTemplate = (id: string) => {
        dispatch(updateLoanStatus({ loanId: id, status: LoanStatus.ARCHIVED }));
        setTempDeletedTemplatesIds(prevState => [...prevState, id]);
    }

    const handleConfirmDelete = (id: string) => {
        setTemplateIdToDelete(id);
    }

    const handleCancelDelete = () => {
        setTemplateIdToDelete(null);
    }

    const handleCompanyIdFilterChange = (companyId: string) => {
        setCompanyIdFilter(companyId);
    }

    useSubscription('/topic/templates', () => {
        dispatch(getV2Templates());
    });

    useEffectAtMount(() => {
        dispatch(getV2Templates())
    })

    useEffect(() => {
        if (isMounted() && userState.loanRole?.role === 'LEAD_BORROWER' && !!activeLoanState.loan?.id) {
            dispatch(findByEntityTypeAndLoanId('INDIVIDUAL', activeLoanState.loan.id));
        }
    }, [dispatch, isMounted, activeLoanState.loan?.id, userState.loanRole?.role, userState.viewType])

    const transformedBlocks = Object.values(templates)
        .filter(template => !tempDeletedTemplatesIds.includes(template.id) &&
            template.blockType === BlockType.Entity &&
            (companyIdFilter ? template.company.id === companyIdFilter : true))
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    const transformedLoanTemplates = Object.values(templates)
        .filter(template => !tempDeletedTemplatesIds.includes(template.id) &&
            template.blockType === BlockType.Loan &&
            (companyIdFilter ? template.company.id === companyIdFilter : true))
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    return {
        templateIdToDelete,
        all: Object.values(templates),
        templates: transformedLoanTemplates,
        blocks: transformedBlocks,
        assets: transformedBlocks
            .filter(isTemplateACollateral)
            .filter(isNonApplicantEntityTemplate),
        entities: transformedBlocks
            .filter(isAnEntityTemplate)
            .filter(isNonApplicantEntityTemplate),
        applicantEntities: transformedBlocks
            .filter(isApplicantEntityTemplate),
        individuals: transformedBlocks
            .filter(isAnIndividual)
            .filter(isNonApplicantEntityTemplate),
        companies: transformedBlocks
            .filter(isACompanyTemplate)
            .filter(isNonApplicantEntityTemplate),
        trusts: transformedBlocks
            .filter(isATrustTemplate)
            .filter(isNonApplicantEntityTemplate),
        onDelete: handleDeleteTemplate,
        onConfirmDelete: handleConfirmDelete,
        onCancelDelete: handleCancelDelete,
        onCompanyIdFilterChange: handleCompanyIdFilterChange,
    };
}