import * as React from "react"

export const HomeSaleIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={20}
        fill="none"
        viewBox="0 0 21 20"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12.168 8.208c-.833-.769-3.056-1.025-3.056.514 0 1.538 3.056.769 3.056 2.306 0 1.538-2.5 1.539-3.333.513m1.666.714v1.082m0-5.74V6.67m-8.333 0 8.11-4.055a.5.5 0 0 1 .447 0l8.11 4.055"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17.17 9.172v6.666a1.667 1.667 0 0 1-1.667 1.667h-10a1.667 1.667 0 0 1-1.667-1.666V9.171"
        />
    </svg>
)
