import Dialog from "components/dist/atoms/Dialog"
import { useFormElementContext } from "src/contexts/form-element-context";
import { useUserLoanViewType } from "src/hooks/loans/use-user-loan-view-type";
import { Loan } from "src/types/loan";

import { EditFormElementDetails } from "../form-element-form"

interface FormElementFormDialogProps {
    loanId: string;
    loanEntities: Loan['loanEntities']
}

export const FormElementFormDialog = (props: FormElementFormDialogProps) => {
    const formElementContext = useFormElementContext();
    const { loanViewType } = useUserLoanViewType();

    if (formElementContext.editFileRequestIds.length === 0) return null;

    return <Dialog
        onOpenChange={(isOpen) => !isOpen && formElementContext.onSetEditFileRequestIds([])}
        open>
        <Dialog.Content
            overlayClassName="z-full-screen"
            className='sm:max-w-[95%] lg:max-w-[850px] min-w-10 sm:max-w-[850px] h-full sm:h-auto w-[850px] max-w-full z-full-screen'>
            <EditFormElementDetails
                parentId={loanViewType === 'CONSOLIDATED_LENDER' ? null : formElementContext.activeFolderId}
                onSuccess={() => formElementContext.onSetEditFileRequestIds([])}
                onCancel={() => formElementContext.onSetEditFileRequestIds([])}
                elementIds={formElementContext.editFileRequestIds}
                loanId={props.loanId}
                loanEntities={props.loanEntities}
            />
        </Dialog.Content>
    </Dialog>
}