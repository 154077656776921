import { UserProgress } from "src/backend";

export const getUserLoanProgress = (userId: string, userProgress: Record<string, UserProgress>): UserProgress => {
    if (!userProgress || !userId || !userProgress[userId]) {
        return {
            acceptedPct: 0,
            openPct: 0,
            submittedPct: 0,
        }
    }

    return userProgress[userId];
}
