import { useState } from "react";
import { AppUserDTO2 } from "src/backend";
import { useGetRecipientsUsers } from "src/hooks/messages/use-get-recipients-list";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isValidEmail } from "src/utils/validation";

export interface ContactAutocompleteProps {
    loanId: string;
    onContactSelected: (user: Partial<AppUserDTO2>) => void;
    value: Partial<AppUserDTO2>
}

const clearFocus = () => {
    const element = document.activeElement as HTMLElement;
    element?.blur();
}

export const useContactAutocompleteState = (props: ContactAutocompleteProps) => {
    const [inputValue, setInputValue] = useState('');
    const { users } = useGetRecipientsUsers({
        loanId: props.loanId,
        type: 'NOT_ON_LOAN'
    });

    const onContactSelected = (user: AppUserDTO2) => {
        props.onContactSelected(user);
        setInputValue(getUserDisplayName(user));
        clearFocus();
    };

    const onClearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setInputValue('');
    }

    const onAddNewContactClick = () => {
        const isEmailAddress = isValidEmail(inputValue);
        props.onContactSelected({
            givenName: !isEmailAddress ? inputValue : '',
            familyName: '',
            emailAddress: isEmailAddress ? inputValue : '',
        });
        setInputValue('');
        clearFocus()
    }

    const filteredUsers = users.filter(user => filter(user.familyName + ' ' + user.givenName, inputValue, [user.emailAddress]));

    return {
        users: filteredUsers,
        inputValue,
        onContactSelected,
        onClearClick,
        onAddNewContactClick,
        onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)
    }
};

const filter = (value: string, search: string, keywords: string[]) => {
    const extendValue = value + ' ' + keywords.join(' ')
    if (extendValue.includes(search)) return true
    return false
}