import * as React from "react"

export const TrashIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.667 7.497-1.663 9.455a1.667 1.667 0 0 1-1.641 1.379H6.638a1.666 1.666 0 0 1-1.642-1.379L3.333 7.497m14.167-2.5h-4.688m-10.312 0h4.688m0 0V3.331a1.667 1.667 0 0 1 1.666-1.667h2.292a1.666 1.666 0 0 1 1.666 1.667v1.666m-5.624 0h5.625"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

