import Avatar from "components/dist/atoms/Avatar";
import Button from "components/dist/atoms/Button";
import HighlightText from "components/dist/atoms/HighlightText";
import Icon from "components/dist/atoms/Icon";
import Input from "components/dist/atoms/Input";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import { getInitials } from "src/utils/get-initials";
import { classNames } from "src/utils/tailwind-class-names";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { ContactAutocompleteProps, useContactAutocompleteState } from "./contact-autocomplete.state";


export const ContactAutocomplete = (props: ContactAutocompleteProps) => {
    const state = useContactAutocompleteState(props);

    return <Stack
        className="relative overflow-visible group w-full">
        <Input
            value={state.inputValue}
            onChange={state.onInputChange}
            wrapClassName='w-full group-focus-within:border-blue-100 text-sm'
            placeholder='Search or Enter Name or Email' >
            <Input.Prefix>
                <Icon name='Search' width={18} height={18} className='text-gray-neutral-70' />
            </Input.Prefix>
            {state.inputValue && <Input.Suffix>
                <Button
                    onClick={state.onClearClick}
                    variant="ghost" size='sm' className="px-1">
                    <Icon name='Cancel' width={18} height={18} className='text-black-primary' strokeWidth={1.5} />
                </Button>
            </Input.Suffix>}
        </Input>
        <ul
            className={classNames('[&>_div]:w-full h-64 overflow-auto scrollbar-stable flex-col z-20 hidden absolute top-[54px] w-full bg-white shadow border border-gray-neutral-80 p-2 rounded-md', {
                'group-focus-within:flex': state.inputValue.length > 0
            })}>
            {state.users.length === 0 && <li className='p-0 gap-3 flex flex-col w-full overflow-hidden'>
                <Text size='xs' variant='secondary'>User does not exist</Text>
                <Button
                    variant='secondary'
                    onClick={state.onAddNewContactClick}
                    className='text-blue-100 font-normal items-start text-left justify-start truncate'
                    block>
                    Add Contact &ldquo;{state.inputValue}&rdquo;
                </Button>
            </li>}
            {state.users.length > 0 && <li>
                <Text weight='medium' size='sm'>Not on loan</Text>
            </li>}
            {state.users.map(user => <li
                tabIndex={0}
                className={classNames("overflow-hidden shrink-0 flex flex-row focus:bg-gray-table-header cursor-pointer gap-2 w-full text-sm p-2 hover:bg-gray-table-header rounded-md", {
                    'bg-gray-table-header': props.value?.id === user.id
                })}
                onClick={() => state.onContactSelected(user)}
                key={user.id}>
                <Avatar size='xs'>
                    <Avatar.Fallback
                        className={classNames({
                            'bg-blue-100 text-white': user.loggedRoleGroup === 'LENDER',
                            'bg-yellow-76 text-black-primary': user.loggedRoleGroup === 'CONTACT',
                            'bg-yellow-60 text-black-primary': user.loggedRoleGroup === 'BORROWER',
                        })}
                    >
                        {getInitials(getUserDisplayName(user))}
                    </Avatar.Fallback>
                </Avatar>
                <Text size='sm' truncate>
                    <HighlightText text={getUserDisplayName(user)} highlightText={state.inputValue} />
                </Text>
            </li>)}
        </ul>
    </Stack>
}
