import * as React from "react"

export const ExclamationCircleGraphic = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={65}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#F8F8FA"
            d="M32.5 64c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32C14.827 0 .5 14.327.5 32c0 17.673 14.327 32 32 32Z"
        />
        <path
            fill="#7EB3FF"
            d="M32.501 36.576a4.571 4.571 0 0 1-4.571-4.572V18.29a4.571 4.571 0 1 1 9.142 0v13.715a4.572 4.572 0 0 1-4.57 4.57ZM32.501 50.283a4.571 4.571 0 1 1 0-9.142 4.571 4.571 0 0 1 0 9.142Z"
        />
    </svg>
)
