import { styled } from "@mui/material/styles"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const DropdownFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: theme.typography.pxToRem(8),
    color: themeV2.neutral.grey[100],
    alignItems: 'center',
    padding: theme.spacing(.5, 1, 1),
    gap: theme.spacing(.5),
}))

const ListItem = styled('li')(({ theme }) => ({
    '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.25),
    }
}))

export const GooglePlacesTextFieldStyles = {
    DropdownFooter,
    ListItem
}