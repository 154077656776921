import { useRouter } from "next/router";
import { useDeferredValue, useState } from "react";
import { toast } from "react-toastify";
import { AppUserDTO2 } from "src/backend";
import { QUERY_PARAM_LENDER_ID } from "src/constants/url";
import { useContactContext } from "src/contexts/contact-context";
import { useMessagesContext } from "src/contexts/messages-context";
import { ContactRelationDtoExtended, useGetContactRelationQuery } from "src/services/contactApi";
import { AppUserDTO2Extended, useGetLoggedInUserQuery } from "src/services/userApi";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { ContactFormValues } from "../contact-form/contact-form.types";
import { ContactConfirmationState, ContactsListProps } from "./contacts-list.types";

const confirmationInitialState: ContactConfirmationState = {
    operation: null,
    value: null
};

export const useContactsListState = (props: ContactsListProps) => {
    const [confirmationState, setConfirmationState] = useState<ContactConfirmationState>(confirmationInitialState);
    const [contactFormValues, setContactFormValues] = useState<ContactFormValues>(null);
    const [filter, setFilter] = useState("");
    const messageContext = useMessagesContext();
    const contactContext = useContactContext();
    const deferredFilter = useDeferredValue(filter);
    const router = useRouter();
    const { data: loggedInUserData } = useGetLoggedInUserQuery();
    const lenderId = loggedInUserData?.employer?.id ?? router.query[QUERY_PARAM_LENDER_ID] as string;
    const { data = [] } = useGetContactRelationQuery({
        loanId: props.loanId,
        loanRoleId: props.loanRole.id,
    });

    const onMoveToBorrowerClick = (contact: ContactRelationDtoExtended) => {
        contactContext.onMoveContactToBorrower(contact);
    };

    const onEditClick = (contactRelation: ContactRelationDtoExtended) => {
        contactContext.onSetEditContactFormValues({
            contactSubRoleType: contactRelation.contactSubRole?.subRoleType,
            templateId: null,
            loanId: props.loanId,
            contactSubRoleId: contactRelation.contactSubRole?.id,
            userId: contactRelation.contactUser.id,
            emailAddress: contactRelation.contactUser.emailAddress,
            phoneNumber: contactRelation.contactUser.mobilePhone,
            companyAddress: contactRelation.companyAddress,
            companyName: contactRelation.companyName,
            companyWebsite: contactRelation.companyWebsite,
            givenName: contactRelation.givenName,
            contactSubRoleTitle: contactRelation.contactSubRole?.title,
            familyName: contactRelation.familyName,
            positionOrTitle: contactRelation.positionOrTitle,
            visibleToBorrower: contactRelation.visibleToBorrower,
            visibleToLender: contactRelation.visibleToLender,
            emailEditingDisabled: !contactRelation.contactUser.stub,
            keyContact: contactRelation.keyContact,
            editOtherFieldsDisabled: false,
        }, data)
    };

    const onRemoveConfirmation = (user: AppUserDTO2Extended) => {
        const loanRole = props.loanRoles.find((loanRole) => loanRole.user.id === user.id);
        if (loanRole) {
            contactContext.onRemoveContact(loanRole);
        } else {
            toast.error("Error removing contact, Role not found");
        }
    };

    const onMoveContactToKeyContactConfirmation = (contact: ContactRelationDtoExtended, contactFormValues?: Partial<Pick<ContactFormValues, 'contactSubRoleId' | 'visibleToBorrower'>>) => {
        contactContext.onMoveContactToKeyContact(contact, props.loanId, props.loanRoles, contactFormValues);
        setContactFormValues(null);
    }

    const onRemoveClick = (user: AppUserDTO2Extended) => {
        const loanRole = props.loanRoles.find((loanRole) => loanRole.user.id === user.id);
        contactContext.onRemoveContact(loanRole);
    }

    const onRemoveCancel = () => {
        setConfirmationState({
            operation: null,
            value: null
        });
    }

    const onSendMessageClick = (contact: AppUserDTO2Extended) => {
        messageContext.onSendMessageClick([], {
            recipients: [contact.id],
            loanId: props.loanId
        });
    };

    const onMoveToContact = (contact: ContactRelationDtoExtended) => {
        const loanRole = props.loanRoles.find((loanRole) => loanRole.user.id === contact.contactUser.id);
        contactContext.onMoveKeyContactToContact(loanRole);
    };

    const onMoveToKeyContactClick = (contact: ContactRelationDtoExtended) => {
        if (contact.contactSubRole) {
            setConfirmationState({
                operation: 'MOVE_TO_KEY_CONTACT',
                value: contact
            });
        } else {
            setConfirmationState({
                operation: 'MOVE_TO_KEY_CONTACT_SET_CONTACT_ROLE',
                value: contact
            });
        }
    };
    const onContactFormValuesChange = (key: keyof ContactFormValues, value: string | boolean) => {
        setContactFormValues(prevValues => ({
            ...prevValues,
            [key]: value
        }));
    };
    const onClearClick = () => {
        setFilter("");
    }
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    }

    const filteredContacts = data.filter((contact) => filterUser(contact.contactUser, deferredFilter));

    const contacts = filteredContacts.filter((contact) => !contact.keyContact);

    const keyContacts = filteredContacts.filter((contact) => contact.keyContact);

    const isEditRemoveEnabled = loggedInUserData?.user.id === props.loanRole.user.id;

    return {
        confirmationState,
        user: loggedInUserData?.user,
        keyContacts,
        contacts,
        filter,
        searchPlaceholder: getSearchPlaceholder(props.loanRole.user, loggedInUserData?.user),
        dialogTitle: getDialogTitle(props.loanRole.user, loggedInUserData?.user),
        contactFormValues,
        isEditRemoveEnabled,
        lenderId,
        onClearClick,
        onRemoveCancel,
        onContactFormValuesChange,
        onSearchChange,
        onMoveToBorrowerClick,
        onRemoveClick,
        onEditClick,
        onRemoveConfirmation,
        onSendMessageClick,
        onMoveToKeyContactClick,
        onMoveContactToKeyContactConfirmation,
        onMoveToContact
    } as const;
};

const getSearchPlaceholder = (roleUser: AppUserDTO2, loggedInUser: AppUserDTO2Extended): string => {
    if (roleUser.id === loggedInUser.id) {
        return "Search My Contacts";
    }
    return "Search Team Member Contacts";
}

const getDialogTitle = (roleUser: AppUserDTO2, loggedInUser: AppUserDTO2Extended): string => {
    if (roleUser.id === loggedInUser.id) {
        return "My Contacts";
    }
    return `${getUserDisplayName(roleUser)} Contacts`;
}

const filterUser = (user: AppUserDTO2Extended, filter: string) => {
    // filter by display name or email address
    return user.displayName.toLowerCase().includes(filter.toLowerCase()) ||
        user.emailAddress.toLowerCase().includes(filter.toLowerCase());
}