import { Address } from "src/backend";

export const addressToString = (address: Partial<Address>) => {
    if (!address) return '';

    const { citySuburb,
        postalCode,
        stateProvinceRegion,
        streetAddressLine1,
        streetAddressLine2 } = address;

    const addressLines = [
        streetAddressLine2,
        streetAddressLine1,
        citySuburb,
        stateProvinceRegion,
        postalCode,
    ].filter(line => line);

    return addressLines.join(', ');
}