import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import React, { isValidElement } from 'react';
import { ExclamationCircleGraphic } from 'src/icons/exclamation-circle-graphic';
import { getChildOnDisplayName, ReactComponent } from 'src/utils/react/get-child-on-display-name';

import { ConfirmationDialogProps } from "./confirmation-dialog.types";

export const ConfirmationDialog = ({
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    ...props
}: React.PropsWithChildren<ConfirmationDialogProps>) => {
    // get the trigger component
    const trigger = getChildOnDisplayName(props.children, ConfirmationDialogTrigger.displayName);
    // filter out the trigger component
    const children = React.Children.toArray(props.children).filter((child) => {
        if (
            isValidElement(child) &&
            typeof child.type !== 'string' &&
            (child as ReactComponent).type.displayName === ConfirmationDialogTrigger.displayName
        ) {
            return false;
        }

        return true;
    });

    return (<Dialog
        open={props.open}
        onOpenChange={(open: boolean) => props.onClose && open === false && props.onClose()}>
        {trigger}
        <Dialog.Content className='flex flex-col z-dialog p-4 max-w-[413px] z-full-screen' overlayClassName='z-full-screen'>
            <Dialog.Header className='flex-1 border-b-0 items-center'>
                <ExclamationCircleGraphic />
            </Dialog.Header>
            <div className='overflow-y-auto'>
                {children}
            </div>
            <Dialog.Footer
                className='flex-1 gap-6 items-center sm:justify-center flex flex-row justify-center border-t-0'>
                <Dialog.Close asChild>
                    <Button
                        className='w-36 h-10'
                        variant='outline'>
                        {cancelText}
                    </Button>
                </Dialog.Close>
                {props.onConfirm && <Button
                    disabled={props.loading}
                    className='w-36 h-10'
                    onClick={props.onConfirm}
                >
                    {props.loading ? "Please wait..." : confirmText}
                </Button>}
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>);
}

export const ConfirmationDialogTrigger = ({ children }: React.PropsWithChildren<{}>) => {
    return <Dialog.Trigger asChild>{children}</Dialog.Trigger>;
}

ConfirmationDialogTrigger.displayName = 'ConfirmationDialogTrigger';
