
import Text from 'components/dist/atoms/Text';
import { FormElementV2ResponseDto } from 'src/backend';
import { ConfirmationDialog } from 'src/components/common/v2/confirmation-dialog';

type Props = {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void,
    formElement?: Partial<FormElementV2ResponseDto>,
    questionOrAnswer: "QUESTION" | "ANSWER"
    loading: boolean,
};

export const DeleteQuestionConfirmDialog = (props: Props) => {
    return <ConfirmationDialog
        loading={props.loading}
        onConfirm={props.onConfirm}
        open={props.open}
        onClose={props.onClose}>
        <div>
            <Text className='text-center' size='sm'>
                Are you sure you want to delete {props.questionOrAnswer === 'ANSWER' ? "the answer to" : ""} {props.formElement.title}
            </Text>
        </div>
    </ConfirmationDialog>
}