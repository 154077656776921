
import ActionAlertDialog from "components/dist/molecules/ActionAlertDialog";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { QUERY_DOCUMENT_PREVIEW_ID } from "src/constants/shoebox";
import { QUERY_PARAM_TASK_VIEW } from "src/constants/url";

export const useConfirmUnsavedChanges = (shouldWarn?: boolean, onRouteChange?: (confirm?: boolean) => void, message?: string, options: {
    cancelText: string;
    confirmText: string;
    title: string;
} = {
        cancelText: "",
        confirmText: "",
        title: "Changes made"
    }) => {
    const [nextUrl, setNextUrl] = useState<string>(null);
    const [open, setOpen] = useState<boolean>(false);
    const { events, asPath, push } = useRouter();

    const lastHistoryState = useRef(global.history?.state);

    const handleOnConfirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();

        onRouteChange?.(true);
        setOpen(false);

        await push(nextUrl);
        setNextUrl(null);
    }

    const handleAbort = () => {
        setNextUrl(null);
        setOpen(false);
        events.emit("routeChangeError");
        const state = lastHistoryState.current;
        if (state != null && history.state != null && state.idx !== history.state.idx) {
            history.go(state.idx < history.state.idx ? -1 : 1);
        }
    }

    const onOpenChange = () => {
        handleAbort();
        setOpen(false);
        handleAbort();
    };
    useEffect(() => {
        const storeLastHistoryState = () => {
            lastHistoryState.current = history.state;
        };
        if (!!events) {
            events.on("routeChangeComplete", storeLastHistoryState);
            return () => {
                events.off("routeChangeComplete", storeLastHistoryState);
            };
        }
    }, [events]);

    useEffect(() => {

        const routeChangeStart = (url: string) => {

            const ignoreQueryParams = [QUERY_DOCUMENT_PREVIEW_ID, QUERY_PARAM_TASK_VIEW];
            const urlIsPreview = ignoreQueryParams.some((param) => url.includes(param));
            const asPathIsPreview = ignoreQueryParams.some((param) => asPath.includes(param));
            const skipWarning = urlIsPreview || asPathIsPreview;

            if (asPath !== url &&
                shouldWarn &&
                !skipWarning &&
                !nextUrl) {

                setNextUrl(url);
                setOpen(true);
                // eslint-disable-next-line no-throw-literal
                throw "Abort route change. Please ignore this error.";
            } else {
                onRouteChange?.();
            }
        };

        const beforeUnload = (e) => {
            if (shouldWarn && !nextUrl) {
                const event = e || window.event;
                event.returnValue = message;
                return message;
            }
            return null;
        };

        if (!!events) {

            window.addEventListener("beforeunload", beforeUnload);
            events.on("routeChangeStart", routeChangeStart);
            return () => {

                events.off("routeChangeStart", routeChangeStart);
                window.removeEventListener("beforeunload", beforeUnload);
            };
        }
    }, [events, asPath, message, onRouteChange, shouldWarn, nextUrl]);

    return {
        AlertComponent: open ? (<ActionAlertDialog
            open={open}
            message={message}
            title={options.title ?? "Changes made"}
            cancelButtonText={options.cancelText}
            confirmButtonText={options.confirmText}
            onConfirm={handleOnConfirm}
            onOpenChange={onOpenChange} />) : null
    };
};