import Badge from 'components/dist/atoms/Badge';
import Stack from 'components/dist/atoms/Stack';
import { forwardRef } from 'react';

interface ShrinkingLabelsProps {
    labels: string[]
    maxLabels?: number
    hidePlus?: boolean
}

export const ShrinkingLabels = forwardRef<HTMLDivElement, ShrinkingLabelsProps>(({ hidePlus, maxLabels = 0, labels }, ref) => {

    return <Stack
        ref={ref}
        row
        space="sm"
        items='center'>
        {labels.filter((_, index) => index < maxLabels).map((label, index) => <Badge key={index} variant='label'>{label}</Badge>)}
        {labels.length > maxLabels && !hidePlus && <Badge className='shrink-0' variant='label' >+{labels.length - maxLabels}</Badge>}
    </Stack>
})

ShrinkingLabels.displayName = 'ShrinkingLabels';