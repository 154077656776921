import { KeyStorage } from "src/constants/key-storage";
import { useGetKeyPreferenceQuery, useSetKeyPreferenceMutation } from "src/services/userApi";

interface Props {
    loanId: string
}

export const useSendAutomaticPublicMessage = (props: Props) => {
    const { data: remoteSettings, isLoading } = useGetKeyPreferenceQuery<{ data: { visible: boolean } | undefined, isLoading: boolean }>({
        key: `${KeyStorage.SendAutomaticPublicMessage}.${props.loanId}`
    });

    const [setKeyPreference] = useSetKeyPreferenceMutation();

    const onToggle = () => {
        setKeyPreference({
            key: `${KeyStorage.SendAutomaticPublicMessage}.${props.loanId}`,
            value: { visible: !remoteSettings?.visible }
        })
    }

    return {
        visible: remoteSettings?.visible ?? false,
        loading: isLoading,
        onToggle
    } as const;
};