import { useEffect, useState } from "react";
import { AppUserDTO2 } from "src/backend";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

interface User {
    initials: string;
    displayName: string;
    emailAddress: string;
    id: string;
}

export const mapAppUserToUser = (user: Pick<AppUserDTO2, 'id' | 'givenName' | 'familyName' | 'emailAddress'>): User => {
    const displayName = getUserDisplayName(user);
    const initials = getInitials(displayName);
    return {
        initials: initials,
        displayName,
        emailAddress: user.emailAddress,
        id: user.id
    }
}

export interface SelectUserAutocompleteProps {
    onUserSelected: (user: User) => void;
    users: User[];
    value: string;
    onAddNewClick: () => void;
}

const clearFocus = () => {
    const element = document.activeElement as HTMLElement;
    element?.blur();
}

export const useSelectUserAutocompleteState = (props: SelectUserAutocompleteProps) => {
    const [selectedUserId, setSelectedUserId] = useState(props.value);
    const [inputValue, setInputValue] = useState('');

    const onUserSelected = (user: User) => {
        props.onUserSelected(user);
        setInputValue(user.displayName);
        setSelectedUserId(user.id);
        clearFocus();
    };

    const filteredUsers = props.users.filter(user => filter(user.displayName, inputValue, [user.emailAddress]));

    const selectedUser = props.users.find(user => user.id === selectedUserId);

    useEffect(() => {
        if (selectedUser?.displayName) setInputValue(selectedUser.displayName);
    }, [selectedUser?.displayName]);

    return {
        users: filteredUsers,
        inputValue,
        selectedUser,
        onUserSelected,
        onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)
    } as const;
};

const filter = (value: string, search: string, keywords: string[]) => {
    const extendValue = value + ' ' + keywords.join(' ')
    if (extendValue.includes(search)) return true
    return false
}