
import Button from 'components/dist/atoms/Button';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import { classNames } from 'src/utils/tailwind-class-names';

interface AddUsersFormProps {
    onCancel: () => void;
    onSave: () => void;
    onSaveClose?: () => void;
    onSaveAndNew?: () => void;
    isLoading?: boolean;
    isDirty?: boolean;
    submitText?: string;
    cancelText?: string;
    saveOnlyText?: string;
}

export const AddUsersFormFooter = ({
    submitText = 'Save',
    saveOnlyText,
    cancelText = 'Cancel',
    ...props }: AddUsersFormProps) => <div className="bg-white w-full sticky bottom-0 right-0 z-10 px-0" style={{ boxShadow: "4px -4px 20px 0px rgba(235, 237, 243, 0.50)" }}>
        <div className='w-full px-6'>
            <Stack
                className='flex w-full py-4 justify-end sm:justify-between'
                space="md"
                row>
                <Button
                    disabled={props.isLoading}
                    onClick={props.onCancel}
                    variant='outline'
                    className={classNames('min-w-[100px] sm:inline-flex', {
                        'hidden': props.onSaveClose
                    })}>
                    {cancelText ?? 'Cancel'}
                </Button>
                <Stack row items='center' space="md">
                    <Button
                        variant={props.onSaveClose ? 'outline' : 'default'}
                        disabled={!props.isDirty || props.isLoading}
                        loading={props.isLoading}
                        onClick={props.onSave}
                        className={classNames("min-w-[100px]", {
                            'text-blue-100': props.onSaveClose
                        })}>
                        {saveOnlyText ?? "Save"}
                    </Button>
                    {props.onSaveClose && <Stack
                        className='divide-x divide-white'
                        space='none'
                        row>
                        <Button
                            disabled={!props.isDirty || props.isLoading}
                            loading={props.isLoading}
                            onClick={props.onSaveClose}
                            className={classNames("flex items-center gap-2 min-w-[100px]", {
                                'rounded-r-none': props.onSaveAndNew
                            })}>
                            {submitText}
                        </Button>
                        {props.onSaveAndNew && <DropdownMenu>
                            <DropdownMenu.Trigger asChild>
                                <Button
                                    aria-label="Save Options"
                                    disabled={!props.isDirty || props.isLoading}
                                    className="rounded-l-none w-10 border-t-0 border-b-0 border-r-0">
                                    <Icon name="NavArrowDown" width={18} height={18} strokeWidth={2} />
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content
                                align='end'
                                className='w-72 p-2 max-w-full'
                            >
                                <DropdownMenu.Item onSelect={props.onSaveAndNew}>
                                    Save and New
                                </DropdownMenu.Item>
                                <DropdownMenu.Item onSelect={props.onSaveClose}>
                                    Save and Close
                                </DropdownMenu.Item>
                            </DropdownMenu.Content>
                        </DropdownMenu>}
                    </Stack>}
                </Stack>
            </Stack>
        </div>
    </div>
