import Button from "components/dist/atoms/Button";
import Icon, { IconProps } from "components/dist/atoms/Icon";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";
import ScreenSizeDropdownMenu from "components/dist/molecules/ScreenSizeDropdownMenu";
import { ShrinkingLabels } from "src/components/common/entity/shrinking-labels";
import { UserAvatar } from "src/components/user/user-avatar";
import { ContactRelationDtoExtended } from "src/services/contactApi";
import { classNames } from "src/utils/tailwind-class-names";

interface ContactsListItemProps {
    contact: ContactRelationDtoExtended;
    isEditRemoveEnabled,
    onEditClick: () => void;
    onRemoveClick: () => void;
    onMoveToBorrowerClick: () => void;
    onMoveToContactClick: () => void;
    onMoveToKeyContactClick: () => void;
    onSendMessageClick: () => void;
}

export const ContactsListItem = (props: ContactsListItemProps) => {
    const menuItems: {
        icon: IconProps["name"];
        text: string;
        variant?: "destructive";
        onClick: () => void;
    }[] = [
            ...props.isEditRemoveEnabled ? [{
                icon: "EditPencil" as const,
                text: "Edit",
                onClick: props.onEditClick,
            }] : [],
            {
                icon: "UserMinus",
                text: "Move to Borrowing Team",
                onClick: props.onMoveToBorrowerClick,
            },
            ...[props.contact.keyContact ? {
                icon: "UserMinus" as const,
                text: "Move to Contacts",
                onClick: props.onMoveToContactClick,
            } : {
                icon: "UserMinus" as const,
                text: "Move to Key Contacts",
                onClick: props.onMoveToKeyContactClick,
            }],
            {
                icon: "MessageText",
                text: "Send a Message",
                onClick: props.onSendMessageClick,
            },
            ...props.isEditRemoveEnabled ? [{
                icon: "Trash" as const,
                text: "Remove",
                variant: "destructive" as const,
                onClick: props.onRemoveClick,
            }] : []
        ];
    const labels = []
    if (props.contact.contactSubRole) {
        labels.push(props.contact.contactSubRole.title)
    }
    if (props.contact.positionOrTitle) {
        labels.push(props.contact.positionOrTitle)
    }
    return <Stack
        className="py-3 px-3 hover:bg-gray-neutral-30 rounded-md"
        row
        items='center'
        space="sm"
        as='li'
        key={props.contact.contactUser.id}>
        <UserAvatar
            userId={props.contact.contactUser.id}
            size='sm'
            avatarDocId={props.contact.contactUser.avatarDocId}
            role="CONTACT">
            {props.contact.initials}
        </UserAvatar>
        <Stack space="xs" className="flex-1 overflow-hidden">
            <Stack row space='sm'>
                <Text size='sm' truncate>
                    {props.contact.displayName}
                </Text>
                <ShrinkingLabels labels={labels} maxLabels={1} />
            </Stack>
            {props.contact.contactUser.emailAddress !== props.contact.displayName &&
                <Text variant='secondary' size='xs'>
                    {props.contact.contactUser.emailAddress}
                </Text>}
        </Stack>
        <ScreenSizeDropdownMenu modal={false}>
            <ScreenSizeDropdownMenu.Trigger asChild>
                <Button size='custom' circle variant="ghost">
                    <Icon name="MoreVert" width={24} height={24} strokeWidth={2} />
                </Button>
            </ScreenSizeDropdownMenu.Trigger>
            <ScreenSizeDropdownMenu.Content
                className="pt-2 sm:pt-2 sm:w-64">
                {menuItems.map((item) => (
                    <ScreenSizeDropdownMenu.Item
                        onSelect={item.onClick}
                        key={item.text}
                        className={classNames("gap-2", {
                            "text-destructive": item.variant === "destructive"
                        })}>
                        <Icon name={item.icon} width={20} height={20} strokeWidth={2} />
                        <Text variant='inherit' size='sm'>{item.text}</Text>
                    </ScreenSizeDropdownMenu.Item>
                ))}
            </ScreenSizeDropdownMenu.Content>
        </ScreenSizeDropdownMenu>
    </Stack>
};