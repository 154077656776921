import { styled } from "@mui/material/styles"
import { themeV2 } from "src/theme/mysherpas-theme-option";

const needsListContainer = styled('div')(({ theme }) => ({
    backgroundColor: themeV2.neutral.grey[10],
    borderRadius: theme.shape.borderRadius,
}));

const TitleTypography = styled('div')(({ theme }) => ({
    color: themeV2.colors.blue[900],
    padding: theme.spacing(1.5),
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    '& > span': {
        color: themeV2.neutral.grey[100],
    }
}));

export const NeedsListContainerStyles = {
    needsListContainer,
    TitleTypography,
}