import Badge from 'components/dist/atoms/Badge';
import { ContactSubRoleType } from "src/backend";

interface ContactSubRoleLabelProps {
    type: ContactSubRoleType;
}

const TypeLabelMap: Record<ContactSubRoleType, string> = {
    "NONE": '',
    BORROWER: 'Team Role',
    MEMBER: 'Team Role',
    KEY_CONTACT: 'Key Contact',
    CONTACT: 'Contact',
};

export const ContactSubRoleLabel = (props: ContactSubRoleLabelProps) => {
    const label = TypeLabelMap[props.type];

    if (!label || ['NONE', 'CONTACT'].includes(props.type)) {
        return null;
    }

    return <Badge className='text-xs' variant="label">
        {label}
    </Badge>
};