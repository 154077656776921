import { format } from "date-fns";
import { useFormik } from "formik"
import { FormElementV2RequestDto } from "src/backend"

import { formElementFormValidation } from "./form-element-form.validation";

interface FormElementFormHookProps<T> {
    formElement?: T;
    onSubmit: (values: T) => void;
}

export const useFormElementFormHook = <T extends Partial<FormElementV2RequestDto>>(props: FormElementFormHookProps<T>) => {
    const formik = useFormik<T>({
        validationSchema: formElementFormValidation,
        initialValues: {
            hasExpiration: false,
            canHaveExpiration: props.formElement?.canHaveExpiration || false,
            storageType: null,
            ...props.formElement,
            title: props.formElement?.title || '',
        },
        onSubmit: (values) => {
            props.onSubmit(values);
        }
    })

    const setDateFieldValue = (field: string, date: Date) => {
        try {
            formik.setFieldValue(field, format(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS'))
        } catch (e) { }
    }

    return {
        handleInputChange: formik.handleChange,
        values: formik.values,
        errors: formik.errors,
        handleSubmit: formik.handleSubmit,
        setFieldTouched: formik.setFieldTouched,
        setDateFieldValue,
        isDirty: formik.dirty,
        touched: formik.touched,
    }
}