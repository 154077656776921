import { j as h } from "./jsx-runtime-B6kdoens.js";
import { _ as v, a as _ } from "./index-28E3tTLh.js";
import * as g from "react";
import { forwardRef as R, createElement as d, useRef as x, useEffect as C, useState as F } from "react";
import { $ as L, b as k } from "./index-CTDw4Pi4.js";
import { $ as w } from "./index-Cp-Nu9_j.js";
import { $ as y } from "./index-CZBInFmv.js";
import { $ as E, a as K, b as z } from "./index-BR2J6Bm_.js";
import { $ as B } from "./index-U5S6G_il.js";
import { $ as T } from "./index-DaaF0TLE.js";
import { $ as U } from "./index-CX8l_p9w.js";
import { $ as H } from "./index-Cs5eRmmP.js";
import { c as W } from "./index-BV2fynjD.js";
import { c as G } from "./utils-CJ9afRe1.js";
import { I as X } from "./Icon-GJXsxHcP.js";
const P = "Radio", [Y, I] = w(P), [J, Q] = Y(P), Z = /* @__PURE__ */ R((r, t) => {
  const { __scopeRadio: e, name: n, checked: o = !1, required: a, disabled: s, value: f = "on", onCheck: i, ...l } = r, [$, u] = F(null), p = _(
    t,
    (m) => u(m)
  ), c = x(!1), b = $ ? !!$.closest("form") : !0;
  return /* @__PURE__ */ d(J, {
    scope: e,
    checked: o,
    disabled: s
  }, /* @__PURE__ */ d(y.button, v({
    type: "button",
    role: "radio",
    "aria-checked": o,
    "data-state": A(o),
    "data-disabled": s ? "" : void 0,
    disabled: s,
    value: f
  }, l, {
    ref: p,
    onClick: k(r.onClick, (m) => {
      o || i == null || i(), b && (c.current = m.isPropagationStopped(), c.current || m.stopPropagation());
    })
  })), b && /* @__PURE__ */ d(re, {
    control: $,
    bubbles: !c.current,
    name: n,
    value: f,
    checked: o,
    required: a,
    disabled: s,
    style: {
      transform: "translateX(-100%)"
    }
  }));
}), ee = "RadioIndicator", oe = /* @__PURE__ */ R((r, t) => {
  const { __scopeRadio: e, forceMount: n, ...o } = r, a = Q(ee, e);
  return /* @__PURE__ */ d(H, {
    present: n || a.checked
  }, /* @__PURE__ */ d(y.span, v({
    "data-state": A(a.checked),
    "data-disabled": a.disabled ? "" : void 0
  }, o, {
    ref: t
  })));
}), re = (r) => {
  const { control: t, checked: e, bubbles: n = !0, ...o } = r, a = x(null), s = U(e), f = T(t);
  return C(() => {
    const i = a.current, l = window.HTMLInputElement.prototype, u = Object.getOwnPropertyDescriptor(l, "checked").set;
    if (s !== e && u) {
      const p = new Event("click", {
        bubbles: n
      });
      u.call(i, e), i.dispatchEvent(p);
    }
  }, [
    s,
    e,
    n
  ]), /* @__PURE__ */ d("input", v({
    type: "radio",
    "aria-hidden": !0,
    defaultChecked: e
  }, o, {
    tabIndex: -1,
    ref: a,
    style: {
      ...r.style,
      ...f,
      position: "absolute",
      pointerEvents: "none",
      opacity: 0,
      margin: 0
    }
  }));
};
function A(r) {
  return r ? "checked" : "unchecked";
}
const ae = [
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight"
], N = "RadioGroup", [te, Ee] = w(N, [
  E,
  I
]), S = E(), q = I(), [ce, ne] = te(N), se = /* @__PURE__ */ R((r, t) => {
  const { __scopeRadioGroup: e, name: n, defaultValue: o, value: a, required: s = !1, disabled: f = !1, orientation: i, dir: l, loop: $ = !0, onValueChange: u, ...p } = r, c = S(e), b = B(l), [m, O] = L({
    prop: a,
    defaultProp: o,
    onChange: u
  });
  return /* @__PURE__ */ d(ce, {
    scope: e,
    name: n,
    required: s,
    disabled: f,
    value: m,
    onValueChange: O
  }, /* @__PURE__ */ d(K, v({
    asChild: !0
  }, c, {
    orientation: i,
    dir: b,
    loop: $
  }), /* @__PURE__ */ d(y.div, v({
    role: "radiogroup",
    "aria-required": s,
    "aria-orientation": i,
    "data-disabled": f ? "" : void 0,
    dir: b
  }, p, {
    ref: t
  }))));
}), de = "RadioGroupItem", ie = /* @__PURE__ */ R((r, t) => {
  const { __scopeRadioGroup: e, disabled: n, ...o } = r, a = ne(de, e), s = a.disabled || n, f = S(e), i = q(e), l = x(null), $ = _(t, l), u = a.value === o.value, p = x(!1);
  return C(() => {
    const c = (m) => {
      ae.includes(m.key) && (p.current = !0);
    }, b = () => p.current = !1;
    return document.addEventListener("keydown", c), document.addEventListener("keyup", b), () => {
      document.removeEventListener("keydown", c), document.removeEventListener("keyup", b);
    };
  }, []), /* @__PURE__ */ d(z, v({
    asChild: !0
  }, f, {
    focusable: !s,
    active: u
  }), /* @__PURE__ */ d(Z, v({
    disabled: s,
    required: a.required,
    checked: u
  }, i, o, {
    name: a.name,
    ref: $,
    onCheck: () => a.onValueChange(o.value),
    onKeyDown: k((c) => {
      c.key === "Enter" && c.preventDefault();
    }),
    onFocus: k(o.onFocus, () => {
      var c;
      p.current && ((c = l.current) === null || c === void 0 || c.click());
    })
  })));
}), fe = /* @__PURE__ */ R((r, t) => {
  const { __scopeRadioGroup: e, ...n } = r, o = q(e);
  return /* @__PURE__ */ d(oe, v({}, o, n, {
    ref: t
  }));
}), j = se, D = ie, ue = fe, pe = W(
  "aspect-square bg-white rounded-full border text-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "border-primary",
        secondary: "border-gray-neutral-80"
      },
      size: {
        md: "h-4 w-4",
        default: "h-5 w-5"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
), V = g.forwardRef(({ className: r, ...t }, e) => /* @__PURE__ */ h.jsx(
  j,
  {
    className: G("grid gap-2", r),
    ...t,
    ref: e
  }
));
V.displayName = j.displayName;
const M = g.forwardRef(({ className: r, size: t, variant: e, ...n }, o) => /* @__PURE__ */ h.jsx(
  D,
  {
    ref: o,
    className: G(
      pe({ size: t, variant: e }),
      r
    ),
    ...n,
    children: /* @__PURE__ */ h.jsx(ue, { className: "flex items-center justify-center", children: /* @__PURE__ */ h.jsx(X, { name: "Circle", className: "h-3 w-3 fill-primary" }) })
  }
));
M.displayName = D.displayName;
const Ge = Object.assign(V, {
  Item: M
});
export {
  Ge as R
};
