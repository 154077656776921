import Checkbox from "components/dist/atoms/Checkbox"
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import UserNameText from 'components/dist/molecules/UserNameText';
import { ShrinkingLabels } from "src/components/common/entity/shrinking-labels";
import { UserAvatar } from "src/components/user/user-avatar"
import { getInitials } from "src/utils/get-initials"
import { getUserOnLoanLabels } from "src/utils/loan/get-user-on-loan-labels";
import { getUserDisplayName } from "src/utils/user/get-user-display-name"

import { UserListItemProps } from "./user-list-item.types"

export const UserListItem = (props: UserListItemProps) => {
    const labels: string[] = getUserOnLoanLabels({
        loanEntities: props.loanEntities,
        loanRoles: props.loanRoles,
        userId: props.user.id
    })
    return <Stack space="md" className='overflow-hidden items-start' row >
        {'onCheckedChange' in props && <Checkbox
            size="sm"
            disabled={props.disabled}
            checked={props.checked}
            onCheckedChange={(checked) => props.onCheckedChange(checked, props.user)}
            value={props.user.id}
            className='mt-1' />}
        <UserAvatar
            size="xs"
            userId={props.user.id}
            avatarDocId={props.user.avatarDocId}
            role={props.role} >
            {getInitials(getUserDisplayName(props.user))}
        </UserAvatar>
        <div className='flex-1 overflow-hidden'>
            <UserNameText user={props.user}
                size="sm"
                isMe={props.user.isLoggedUser} />
            <Text size='sm' className="overflow-hidden text-ellipsis whitespace-nowrap" variant="secondary">{props.user.emailAddress}</Text>
        </div>
        <ShrinkingLabels hidePlus labels={labels} maxLabels={1} />
    </Stack>
}