import * as React from "react"

export const FolderIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={16}
        fill="none"
        viewBox="0 0 20 16"
        {...props}
    >
        <g clipPath="url(#aFolderIcon)">
            <path
                fill="#FAC014"
                fillRule="evenodd"
                d="M8 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2h-8L8 0Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="aFolderIcon">
                <path fill="#fff" d="M0 0h20v16H0z" />
            </clipPath>
        </defs>
    </svg>
)
