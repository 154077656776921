import { ContactSubRoleType } from 'src/backend';
import * as Yup from 'yup';

export const ContactFormValidation = Yup.object({
    emailAddress: Yup.string().email('Invalid email address').required('Email is required'),
    templateId: Yup.string().when('contactSubRoleType', {
        is: (contactSubRoleType: ContactSubRoleType) => contactSubRoleType === 'BORROWER',
        then: Yup.string().required('Template is required'),
    }).nullable(),
});
