import { LoanDto, LoanEntityDto, LoanRoleDto, Role } from "src/backend"
import { getBorrowerLoanRoleLabel, getRoleLabel } from "src/components/loans/tabs/overview/v2/loan-overview-businesses/loan-overview-business-list-item/loan-overview-business-list-item.hook";
import { GuaranteeType } from "src/types/entity";

import { isRoleABorrower } from "../user/is-role-a-borrower";

export const getLabelsForLender = (
    role: Pick<LoanRoleDto, 'labels' | 'user' | 'role'>,
    loanRoles: Pick<LoanDto['loanRoles'][0], 'role'>[]): string[] => {
    const labels: string[] = [];

    if (role.user.positionOrTitle) {
        labels.push(role.user.positionOrTitle);
    } else if (role.role !== 'LEAD_LENDER') {
        labels.push(getRoleLabel("Member" as Role, loanRoles).title);
    }
    role.labels.forEach(label => {
        labels.push(label.title);
    })
    return labels;
};

export const getLabelsForBorrower = (
    role: Pick<LoanRoleDto, 'labels' | 'user' | 'role'>,
    loanRoles: Pick<LoanDto['loanRoles'][0], 'role'>[],
    loanEntities: LoanEntityDto[]): string[] => {
    const entity = loanEntities?.find(entity => entity.sherpaEntity.userIndividualId === role.user.id);

    const labels: string[] = [];

    // if user is an individual we will get positionOrTitle from employments
    let positionOrTitle = role.user.positionOrTitle;
    if (entity && entity.sherpaEntity.type === 'INDIVIDUAL') {
        // @ts-expect-error the generated dto does not have this field
        const currentEmployment: EmploymentEntity = entity.mostRecentUserInformation.employments.find((employment: EmploymentEntity) => employment.currentEmployer);
        if (currentEmployment) {
            positionOrTitle = currentEmployment.positionOrTitle;
        }
    }
    if (entity && !entity.label.includes("MEMBER")) {
        // if it's not a lead borrower, show the entity label
        labels.push(getBorrowerLoanRoleLabel(entity));
    } else if (positionOrTitle) {
        labels.push(positionOrTitle);
    }
    else if (role.role === 'CONTACT') {
        labels.push(getRoleLabel('CONTACT', loanRoles).title);
    } else {
        labels.push(getRoleLabel("Member" as Role, loanRoles).title);
    }
    // include GuaranteeType label if it exists
    if (entity?.label?.some(label => Object.keys(GuaranteeType).includes(label))) {
        const guaranteeTypeLabels = entity.label.filter(label => Object.keys(GuaranteeType).includes(label));
        guaranteeTypeLabels.filter(label => label !== 'NOT_A_GUARANTOR')
            .forEach(label => {
                labels.push(GuaranteeType[label].replace(/_/g, ' ').toLowerCase());
            });
    }

    role.labels.forEach(label => {
        labels.push(label.title);
    })
    return labels;
};

interface Args {
    loanEntities: LoanEntityDto[];
    loanRoles: Pick<LoanDto['loanRoles'][0], 'role' | 'user' | 'labels'>[]
    userId: string;
}

export const getUserOnLoanLabels = (args: Args) => {
    const userRoleOnLoan = args.loanRoles.find(role => role.user.id === args.userId);
    if (!userRoleOnLoan) {
        return [];
    }
    // if user is a borrower
    if (isRoleABorrower(userRoleOnLoan.role)) {
        return getLabelsForBorrower(userRoleOnLoan, args.loanRoles, args.loanEntities);
    } else {
        return getLabelsForLender(userRoleOnLoan, args.loanRoles);
    }
}