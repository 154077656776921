import Divider from '@mui/material/Divider';
import Badge from 'components/dist/atoms/Badge';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import CopyButton from 'components/dist/molecules/CopyButton';
import { LinkProps as NextLinkProps } from 'next/link';
import { SherpaEntityType } from "src/backend";
import { CopyContainer } from 'src/components/common/copy-container.component';
import { BuildingIcon } from "src/icons/building-icon";
import { HomeSaleIcon } from "src/icons/home-sale-icon";
import { UserIcon } from "src/icons/user-icon";
import { SherpaEntityWithInformation } from "src/slices/entity"
import { addressToString } from "src/utils/address-to-string";
import { stopPropagation } from 'src/utils/dom/stop-propagation';

import { LoanOverviewEntityTooltipStyles } from "./loan-overview-entity-tooltip.styles"

interface LoanOverviewEntityTooltipProps {
    entity: SherpaEntityWithInformation
    labels: string[]
    onDelete?: (entity: SherpaEntityWithInformation) => void
    editUrl: NextLinkProps['href']
}

export const LoanOverviewEntityTooltip = (props: LoanOverviewEntityTooltipProps) => {

    return <Stack
        justify='between'
        space='md'
        className='p-4 w-full'
        onClick={stopPropagation}>
        <LoanOverviewEntityTooltipStyles.Header>
            <LoanOverviewEntityTooltipStyles.Logo>
                {props.entity.type === 'INDIVIDUAL' && <UserIcon />}
                {props.entity.type === 'TRUST' || props.entity.type === 'COMPANY' && <BuildingIcon />}
                {props.entity.type === 'ASSET' || props.entity.type === 'TRUST' && <HomeSaleIcon />}
            </LoanOverviewEntityTooltipStyles.Logo>
        </LoanOverviewEntityTooltipStyles.Header>
        <Text size='sm' truncate>{props.entity.name}</Text>
        <Stack row className='flex-wrap gap-1'>
            {props.labels.map(label => <Badge key={label} variant='label' className='font-normal' >{label}</Badge>)}
        </Stack>
        <Divider />
        <Stack space='sm'>
            {props.entity.ein && <CopyContainer text={props.entity.ein}>
                <Icon name="Building" width={20} height={20} strokeWidth={1.5} />
                <Text truncate size='sm'>EIN: {props.entity.ein}</Text>
            </CopyContainer>}
            {props.entity.emailAddress && <CopyContainer text={props.entity.emailAddress}>
                <Icon name="MessageText" width={20} height={20} strokeWidth={1.5} />
                <Text truncate size='sm'>{props.entity.emailAddress}</Text>
            </CopyContainer>}
            {props.entity.companyAddress?.streetAddressLine1 && <CopyContainer text={addressToString(props.entity.companyAddress)}>
                <Icon name="MapPin" className='text-blue-100' width={20} height={20} strokeWidth={1.5} />
                <Text truncate size='sm'>{addressToString(props.entity.companyAddress)}</Text>
            </CopyContainer>}
            {!props.entity.mailingSameAsCompanyAddress && props.entity.mailingAddress?.streetAddressLine1 && <CopyContainer text={addressToString(props.entity.mailingAddress)}>
                <Icon name="MapPin" className='text-blue-100' width={20} height={20} strokeWidth={1.5} />
                <Text truncate size='sm'>{addressToString(props.entity.mailingAddress)}</Text>
            </CopyContainer>}
            {props.entity.phoneNumber?.value && <CopyContainer text={props.entity.phoneNumber?.value}>
                <Icon name="Phone" width={20} height={20} strokeWidth={1.5} />
                <Text truncate size='sm'>{props.entity.phoneNumber?.value}</Text>
            </CopyContainer>}
        </Stack>
    </Stack>
}