import Accordion from 'components/dist/atoms/Accordion';
import Button from 'components/dist/atoms/Button';
import Card from 'components/dist/atoms/Card';
import Checkbox from 'components/dist/atoms/Checkbox';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Label from 'components/dist/atoms/Label';
import Select from 'components/dist/atoms/Select';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import PhoneNumberInput from 'components/dist/molecules/PhoneNumberInput';
import { FormikedAddressField } from 'src/components/common/form/formiked-fields/formiked-address-field';
import { ConfirmUnsavedChangesDialog } from 'src/components/common/save-dirty-changes-dialog';
import { NeedsListContainer } from 'src/components/form-elements/needs-list-container';
import { AddUsersFormFooter } from "src/components/loans/add-users-form/add-users-form-footer";
import { ElementV2Tree } from 'src/components/v2/elements/element-v2-tree';
import { classNames } from 'src/utils/tailwind-class-names';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { ContactAutocomplete } from '../contact-autocomplete';
import { ContactSubRolesAutocomplete } from '../contact-sub-roles-autocomplete';
import { SelectUserAutocomplete } from '../select-user-autocomplete';
import { useContactFormState } from './contact-form.state';
import { ContactFormProps } from "./contact-form.types";

export const ContactForm = (props: React.PropsWithChildren<ContactFormProps>) => {
    const state = useContactFormState(props);
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        state.formik.handleSubmit();
    };
    return <>
        <Stack
            className='overflow-hidden max-w-3xl w-full mx-auto gap-0 flex-1 pt-4'
            asChild>
            <form
                id='contact-form'
                onSubmit={onSubmit}>

                {(props.title || state.formik.values.templateId) &&
                    <Stack
                        row
                        space='sm'
                        justify='between'
                        className={classNames('w-full h-24  mx-auto text-left', {
                            'py-10': props.title && state.formik.values.templateId,
                        })}>
                        <Text size='lg' className='flex-1  text-left'>
                            {props.title}
                        </Text>
                        {state.formik.values.templateId && (<Dialog>
                            <Dialog.Trigger asChild>
                                <Button className='w-60 inline inline-flex' spaceX="sm">
                                    <Text as="span" size="sm" truncate variant="inherit" className="shrink-0">View Needs List</Text>
                                </Button>
                            </Dialog.Trigger>
                            <Dialog.Content
                                onOpenAutoFocus={(e) => e.preventDefault()}
                                aria-label="Needs List"
                                className="sm:max-w-4xl">
                                <NeedsListContainer
                                    title={getUserDisplayName(state.formik.values)}
                                    preview>
                                    {state.elementsTree.length > 0 && <ElementV2Tree
                                        hideActions
                                        key={state.ids.join(',')}
                                        reviewStatus={state.loanData.reviewStatus}
                                        expanded={state.ids}
                                        totalFileRequests={state.totalFileRequests}
                                        totalHiddenFileRequests={state.totalHiddenFileRequests}
                                        elementsTree={state.elementsTree} />}
                                </NeedsListContainer>
                            </Dialog.Content>
                        </Dialog>)}
                    </Stack>}
                {!props.initialValues?.emailAddress && <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 pb-6'>
                    <div>
                        <Label variant='secondary'>Selected User</Label>
                        <ContactAutocomplete
                            onContactSelected={state.onUserSelected}
                            loanId={props.loanId}
                            value={state.formik.values.emailAddress
                                ? {
                                    familyName: state.formik.values.familyName,
                                    givenName: state.formik.values.givenName,
                                    emailAddress: state.formik.values.emailAddress,
                                }
                                : null}
                        />
                    </div>
                    <div />
                </div>}
                {state.formik.values.fieldsVisible ? <fieldset disabled={state.formik.isSubmitting}
                    className='grid grid-cols-1 sm:grid-cols-2 gap-6 place-content-start h-full justify-start place-start items-start scrollbar-stable overflow-auto'>
                    {state.formik.initialValues?.userId && <>
                        <Stack space='sm' className='w-full'>
                            <Label variant='secondary'>Selected User</Label>
                            <SelectUserAutocomplete
                                onAddNewClick={state.onAddNewClick}
                                value={state.formik.values.userId}
                                users={props.users}
                                onUserSelected={props.onUserSelected} />
                        </Stack>
                        <div />
                    </>}
                    <Stack space="sm">
                        <Label variant="secondary" htmlFor="givenName">
                            First Name
                        </Label>
                        <Input
                            disabled={state.isFieldDisabled('givenName')}
                            placeholder='First name'
                            name='givenName'
                            onChange={state.formik.handleChange}
                            onBlur={state.formik.handleBlur}
                            value={state.formik.values.givenName}
                            error={state.formik.touched.givenName && Boolean(state.formik.errors.givenName)} />
                    </Stack>
                    <Stack space="sm">
                        <Label variant="secondary" htmlFor="familyName">
                            Last Name
                        </Label>
                        <Input
                            disabled={state.isFieldDisabled('familyName')}
                            placeholder='Last name'
                            name='familyName'
                            onChange={state.formik.handleChange}
                            onBlur={state.formik.handleBlur}
                            value={state.formik.values.familyName}
                            error={state.formik.touched.familyName && Boolean(state.formik.errors.familyName)} />
                    </Stack>
                    <Stack space="sm">
                        <Label variant="secondary" htmlFor="emailAddress">
                            Email Address<Text as="span" size="sm" variant="blue">*</Text>
                        </Label>
                        <Input
                            disabled={state.isFieldDisabled('emailAddress')}
                            name="emailAddress"
                            placeholder='Email address'
                            onChange={state.formik.handleChange}
                            onBlur={state.formik.handleBlur}
                            value={state.formik.values.emailAddress}
                            error={state.formik.touched.emailAddress && Boolean(state.formik.errors.emailAddress)} />
                    </Stack>
                    <Stack space="sm">
                        <Label variant="secondary" htmlFor="phoneNumber.value">
                            Phone
                        </Label>
                        <Stack row items="center" space='sm'>
                            <div className='rounded-md border border-gray-neutral-30 flex items-center justify-center p-4'>
                                <Icon name="USA" width={21} height={14} />
                            </div>
                            <PhoneNumberInput
                                disabled={state.isFieldDisabled('phoneNumber.value')}
                                name="phoneNumber.value"
                                onChange={state.formik.handleChange}
                                onBlur={state.formik.handleBlur}
                                value={state.formik.values.phoneNumber?.value}
                                error={state.formik.touched.phoneNumber?.value && Boolean(state.formik.errors.phoneNumber?.value)}
                                wrapClassName='flex-1' />
                        </Stack>
                    </Stack>
                    <Stack space="sm">
                        <Label variant="secondary" htmlFor="positionOrTitle">
                            Job Title
                        </Label>
                        <Input
                            disabled={state.isFieldDisabled('positionOrTitle')}
                            placeholder='Job Title'
                            name="positionOrTitle"
                            onChange={state.formik.handleChange}
                            onBlur={state.formik.handleBlur}
                            value={state.formik.values.positionOrTitle}
                            error={state.formik.touched.positionOrTitle && Boolean(state.formik.errors.positionOrTitle)} />
                    </Stack>
                    <Stack space="sm">
                        <Label variant="secondary" htmlFor="username">
                            Loan Role
                        </Label>
                        <ContactSubRolesAutocomplete
                            lenderId={props.lenderId}
                            hideTeamRoles={!props.initialValues?.userId}
                            loanRoleId={state.formik.values.contactSubRoleId}
                            onContactRoleSelected={state.onContactRoleSelected} />
                    </Stack>
                    {!["BORROWER"].includes(state.formik.values.contactSubRoleType) &&
                        state.isLoggedInUserALender && <Stack row space='md' className='sm:col-span-2'>
                            {!props.hideKeyContact && <Stack asChild space='sm' row items='center'>
                                <Label fontSize='xs'>
                                    <Checkbox
                                        disabled={state.formik.values.editOtherFieldsDisabled}
                                        checked={state.formik.values.keyContact}
                                        onCheckedChange={checked => state.formik.setFieldValue('keyContact', checked, true)}
                                        size='sm' /> Set as Key Contact
                                </Label>
                            </Stack>}
                            {state.formik.values.keyContact && <Stack asChild space='sm' row items='center'>
                                <Label fontSize='xs'>
                                    <Checkbox
                                        checked={state.formik.values.visibleToBorrower}
                                        onCheckedChange={checked => state.formik.setFieldValue('visibleToBorrower', checked, true)}
                                        size='sm' /> Visible to Borrowers
                                </Label>
                            </Stack>}
                        </Stack>}
                    {!["BORROWER"].includes(state.formik.values.contactSubRoleType) &&
                        !state.isLoggedInUserALender && <Stack row space='md' className='sm:col-span-2'>
                            <Stack asChild space='sm' row items='center'>
                                <Label fontSize='xs'>
                                    <Checkbox
                                        checked={state.formik.values.visibleToLender}
                                        onCheckedChange={checked => state.formik.setFieldValue('visibleToLender', checked, true)}
                                        size='sm' /> Visible to Lenders
                                </Label>
                            </Stack>
                        </Stack>}
                    {["BORROWER"].includes(state.formik.values.contactSubRoleType) && <Stack space='sm' className='w-full'>
                        <Label
                            variant={state.formik.touched.templateId && state.formik.errors.templateId ? "destructive" : "secondary"}>
                            Needs List Template<Text variant="blue" as="span">*</Text>
                        </Label>
                        <Select
                            name='templateId'
                            required
                            value={state.formik.values.templateId}
                            onValueChange={templateId => state.formik.setFieldValue('templateId', templateId, true)}
                        >
                            <Select.Trigger
                                id="templateId"
                                data-testid="templateId"
                                className={state.formik.touched.templateId && state.formik.errors.templateId ? "border-destructive" : ""}>
                                <Select.Value placeholder="Select" />
                            </Select.Trigger>
                            <Select.Content>
                                {state.templates.map((template) => {
                                    return (
                                        <Select.Item key={template.id} value={template.id}>
                                            {template.name}
                                        </Select.Item>
                                    );

                                })}
                            </Select.Content>
                        </Select>
                        {state.formik.touched.templateId && state.formik.errors.templateId && <Text variant="destructive" size='xs' className="pl-2 pt-2">
                            {state.formik.errors.templateId}
                        </Text>}
                    </Stack>}
                    <Card className='sm:col-span-2'>
                        <Accordion
                            defaultValue={(state.formik.values.companyAddress?.streetAddressLine1 ||
                                state.formik.values.companyName ||
                                state.formik.values.companyWebsite
                            ) ? 'employments' : ''}
                            collapsible
                            type="single">
                            <Accordion.Item value="employments">
                                <Accordion.Trigger asChild className="py-0 cursor-pointer">
                                    <Stack row space="sm">
                                        <Text size="sm" className="flex-1">
                                            Company Information
                                        </Text>
                                    </Stack>
                                </Accordion.Trigger>
                                <Accordion.Content className="pt-2">
                                    <Separator className='mb-4' />
                                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
                                        <Stack space="sm">
                                            <Label variant="secondary" htmlFor="username">
                                                Company Name
                                            </Label>
                                            <Input
                                                disabled={state.isFieldDisabled('companyName')}
                                                onChange={state.formik.handleChange}
                                                onBlur={state.formik.handleBlur}
                                                name="companyName"
                                                value={state.formik.values.companyName}
                                                error={state.formik.touched.companyName && Boolean(state.formik.errors.companyName)}
                                                placeholder='Company Name' />
                                        </Stack>
                                        <Stack space="sm">
                                            <Label variant="secondary" htmlFor="username">
                                                Company Website
                                            </Label>
                                            <Input
                                                disabled={state.isFieldDisabled('companyWebsite')}
                                                onChange={state.formik.handleChange}
                                                onBlur={state.formik.handleBlur}
                                                name="companyWebsite"
                                                value={state.formik.values.companyWebsite}
                                                error={state.formik.touched.companyWebsite && Boolean(state.formik.errors.companyWebsite)}
                                                placeholder='Company Name' />
                                        </Stack>
                                        <Stack space="sm" className='sm:col-span-2'>
                                            <Label variant="secondary" htmlFor="username">
                                                Company Address
                                            </Label>
                                            <Stack
                                                space="sm"
                                                className="grid grid-cols-4 [&>_div:nth-child(1)]:col-span-4 [&>_div:nth-child(2)]:col-span-4 [&>_div:nth-child(3)]:col-span-2 [&>_div:nth-child(4)]:col-span-1 [&>_div:nth-child(5)]:col-span-1 [&>_div]:max-w-full">
                                                <FormikedAddressField
                                                    disabled={state.isFieldDisabled('companyAddress')}
                                                    name="companyAddress"
                                                    label="Company Address"
                                                    placeholder='Company Address'
                                                    formik={state.formik}
                                                />
                                            </Stack>
                                        </Stack>
                                    </div>
                                </Accordion.Content>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                </fieldset> : <div className='h-full' />}
                {props.children}
            </form>
        </Stack>
        {state.hasUnsavedChanges && <ConfirmUnsavedChangesDialog hasUnsavedChanges />}
        {props.withEditFooter && <AddUsersFormFooter
            isDirty={state.formik.dirty}
            isLoading={state.formik.isSubmitting}
            submitText='Save and Close'
            onSave={state.formik.handleSubmit}
            onSaveAndNew={state.onSaveAndNew}
            onSaveClose={state.onSaveClose}
            onCancel={props.onClose}
        />}
    </>
};