import MuiTreeItem from "@mui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const TreeItemLabel = styled("div")(({ theme }) => ({
    display: "flex",
    alignContent: "flex-start",
    alignItems: "flex-start",
    minHeight: 32,
}));

const TreeItem = styled(MuiTreeItem)(({ theme }) => ({
    '& .MuiTreeItem-group': {
        borderLeft: `1px solid ${themeV2.neutral.grey[20]}`,
    },
    '& .MuiTreeItem-content': {
        alignItems: 'center',
        '&.Mui-selected,&.Mui-selected:hover': {
            // backgroundColor: themeV2.colors.blue[60],
            // color: themeV2.colors.blue[10],
            // borderRadius: theme.shape.borderRadius,
        },
        '.MuiRadio-root svg:first-of-type': {
            backgroundColor: 'white',
            borderRadius: '100%',
            stroke: 'white',
            fill: themeV2.neutral.grey[80]
        },
        '&.Mui-selected .MuiRadio-root svg': {
            backgroundColor: themeV2.colors.blue[100],
            borderRadius: '100%',
            stroke: 'white',
            fill: 'white'
        },
        '& .MuiTreeItem-iconContainer': {
            width: 'fit-content',
            alignItems: 'center',
        }
    }
}));

const TreeItemTitle = styled("div")(({ theme }) => ({
    flex: 1,
    overflow: "hidden",
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    gap: theme.spacing(1)
}));

const Actions = styled("div")(({ theme }) => ({
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    backGroundColor: 'white',
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
}));

const FileLabel = styled("label")(({ theme }) => ({
    opacity: 0,
    position: "absolute",
    zIndex: 2,
    inset: 0,
    cursor: 'pointer',
    // set cursor to pointer for input field hover
    "&:hover": {
        cursor: 'pointer'
    }


}));

export const ElementV2TreeStyles = {
    TreeItem,
    TreeItemTitle,
    TreeItemLabel,
    Actions,
    FileLabel
}