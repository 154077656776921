import Dialog from 'components/dist/atoms/Dialog';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    open: boolean;
    onClose: () => void;
    disablePortal?: boolean;
}>;

export const DialogContainer = (props: Props): JSX.Element => {

    return <Dialog
        open={props.open}
        onOpenChange={(open) => !open && props.onClose()}
        aria-labelledby="mysherpas-generic-dialog"
        aria-describedby="mysherpas-generic-dialog">
        <Dialog.Content
            withoutPortal={props.disablePortal}>
            {props.children}
        </Dialog.Content>
    </Dialog>
}