import * as Yup from 'yup';

export const messageValidationSchema = Yup
    .object({
        subject: Yup.string(),
        body: Yup.string()
            .strict(true).
            // when we don't have any attachments we need to make the body required
            when('attachments', (attachments: string[], schema) => {
                return attachments.length === 0
                    ? schema
                        .required('Body is required')
                        // should have text and not just html eg <p></p><p></p>
                        .test('has-text', 'Body is required', value => {
                            return value && value.replace(/<[^>]*>/g, '').trim().length > 0
                        })
                    : schema;
            })
        ,
        recipients: Yup.array().when(['messageThreadId', 'subject'], {
            is: (messageThreadId, subject) => !messageThreadId && !!subject,
            then: Yup.array().min(1, 'Recipients are required'),
            otherwise: Yup.array()
        }),
        loanId: Yup.string().required('Loan is required'),
        messageThreadId: Yup.string().nullable(),
        draftedMessageId: Yup.string().nullable(),
        sendPublicMessageReminder: Yup.boolean().nullable(),
        inReplyToMessage: Yup.string().nullable(),
        attachments: Yup.array().of(Yup.string()),
        locked: Yup.boolean(),
        isDigestEnabled: Yup.boolean(),
    }).required();