import Button from 'components/dist/atoms/Button';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';

interface ContactFormFooterProps {
    onClose: () => void;

}
export const ContactFormFooter = (props: ContactFormFooterProps) => {
    return <>
        <Separator />
        <Stack space='sm' justify='end' row className='px-4 py-3'>
            <Button
                onClick={props.onClose}
                variant='secondary'>Cancel</Button>
            <Button
                type='submit'
            >Save</Button>
        </Stack>
    </>
}