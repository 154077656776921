import { format } from "date-fns";
import { FormikHelpers, useFormik } from "formik";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { PriorityType } from "src/backend";
import { QUERY_PARAM_FORM_ELEMENT_ID } from "src/constants/query-params";
import { useGetElements } from "src/hooks/use-get-elements";
import { useUser } from "src/hooks/use-user";
import { useCreateElementsMutation, useCreateSharedInfoElementMutation, useGetLoanElementsQuery, useUpdateElementsMutation } from "src/services/packageApi";
import { getLoanFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { findSectionByIdAndItsAncestors } from "src/utils";
import { filterPriority, sortPriorities } from "src/utils/form-elements";

import { FormElementFormProps } from "./edit-form-element-details.component";
import { formElementFormValidation } from "./form-element-form.validation";

interface FormElementFormValues {
    id: string,
    title: string;
    canFill: boolean;
    canExpire: boolean;
    description: string;
    includeWithMessage: boolean;
    needsLegalReview: boolean;
    entityId: string;
    parentId: string;
    priority: PriorityType;
    dueDate: string;
    isEditingEntityEnabled: boolean;
    isConfirmationDialogOpen: boolean;
}

const getFormattedDueDate = (dueDate: string): string => {
    return dueDate ? format(new Date(dueDate), 'dd/MM/yyyy') : null;
}

const getDueDateStatus = (dueDate: string): 'success' | 'warning' | 'danger' => {
    // if due date is due, return 'danger'
    // else if due date is within 7 days, return 'warning'
    // else return 'success'
    if (!dueDate) {
        return 'success';
    } else if (new Date(dueDate) < new Date()) {
        return 'danger';
    } else if (new Date(dueDate) < new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)) {
        return 'warning';
    } else {
        return 'success';
    }
};

const PriorityTypeList = {
    "Unset": "Unset",
    "UNSET": "UNSET",
    "HIDDEN": "HIDDEN",
    "PRIVATE": "PRIVATE",
    "IGNORE": "IGNORE",
    "BLOCKER": "BLOCKER",
    "LOW": "LOW",
    "MEDIUM": "MEDIUM",
    "HIGH": "HIGH",
    "CRITICAL": "CRITICAL",
    "URGENT": "URGENT"
}
const priorities = Object.keys(PriorityTypeList)
    .filter(filterPriority)
    .sort(sortPriorities);

export const useFormElementFormState = (props: FormElementFormProps) => {
    const elementState = useGetElements({ loanId: props.loanId });
    const conventionalElements = useGetLoanElementsQuery({
        id: props.loanId,
        view: 'CONVENTIONAL'
    })
    const router = useRouter();
    const dispatch = useDispatch();
    const [createElements] = useCreateElementsMutation();
    const [updateElements] = useUpdateElementsMutation();
    const [createSharedInfoElement] = useCreateSharedInfoElementMutation();
    const userState = useUser();
    const nonNewEditIds = props.elementIds.filter(id => id !== 'new');
    const elementsToEdit = conventionalElements.data?.list?.filter(element => nonNewEditIds.includes(element.id));
    const elementToEdit = elementsToEdit.length === 1 ? elementsToEdit?.[0] : null;
    const isEdit = nonNewEditIds.length > 0;
    let initialValues: Partial<FormElementFormValues> = {};

    const entityIds = elementsToEdit
        .filter(element => element.sherpaEntityId)
        .map(element => element.sherpaEntityId)
    // check if we are editing multiple elements with different sherpaEntityId
    const isEditingMultipleEntities = entityIds
        .filter((value, index, self) => self.indexOf(value) === index)
        .length > 1;

    if (elementsToEdit.length > 1) {
        initialValues = {
            id: 'PLACEHOLDER',
            title: 'PLACEHOLDER',
            canFill: false,
            canExpire: false,
            description: '',
            includeWithMessage: false,
            needsLegalReview: false,
            entityId: isEditingMultipleEntities ? '' : entityIds[0],
            parentId: 'PLACEHOLDER',
            priority: null,
            dueDate: '',
            isEditingEntityEnabled: !isEditingMultipleEntities,
            isConfirmationDialogOpen: false
        }
    } else if (elementsToEdit.length === 1) {
        initialValues = {
            id: elementToEdit.id,
            title: elementToEdit.originalTitle,
            canFill: elementToEdit.modifiers.includes('FILLABLE_FORM'),
            canExpire: !!elementToEdit.canHaveExpiration,
            description: elementToEdit.description ?? "",
            includeWithMessage: elementToEdit.includeDescription,
            needsLegalReview: elementToEdit.modifiers.includes('NEEDS_LEGAL_REVIEW'),
            entityId: elementToEdit.sherpaEntityId ?? "PROJECT_DOCS",
            parentId: elementToEdit.parentId,
            priority: elementToEdit.priorityType,
            dueDate: elementToEdit.expireDate,
            isEditingEntityEnabled: true,
            isConfirmationDialogOpen: false
        }
    }
    const onPreSubmitCheck = (values: FormElementFormValues, formikHelpers: FormikHelpers<FormElementFormValues>) => {
        // check if selected entity is different than any of the elements entity
        if (values.isEditingEntityEnabled && elementsToEdit.length > 1 && elementsToEdit.some(element => element.sherpaEntityId !== values.entityId)) {
            formikHelpers.setFieldValue('isConfirmationDialogOpen', true);
            formik.setSubmitting(false);
        } else {
            onSubmit(values, formikHelpers, elementsToEdit.map(element => element.id));
        }
    };

    const onSubmit = async (values: FormElementFormValues, formikHelpers: FormikHelpers<FormElementFormValues>, elementsIds: string[]) => {
        formikHelpers.setSubmitting(true);
        const selectedElements = elementsToEdit.filter(element => elementsIds.includes(element.id));
        try {
            const sherpaEntityId = (!!values.entityId && values.entityId !== "PROJECT_DOCS" && values.isEditingEntityEnabled) ? values.entityId : null;
            // if we are updating, we need to update the element
            if (isEdit) {
                await updateElements({
                    elements: selectedElements.map(element => {
                        const modifiers = []
                        element?.modifiers.forEach(modifier => {
                            if (modifier !== 'FILLABLE_FORM' && modifier !== 'NEEDS_LEGAL_REVIEW') {
                                modifiers.push(modifier);
                            }
                        });
                        if (values.canFill) {
                            modifiers.push('FILLABLE_FORM');
                        }
                        if (values.needsLegalReview) {
                            modifiers.push('NEEDS_LEGAL_REVIEW');
                        }
                        return ({
                            id: element.id,
                            loanId: props.loanId,
                            title: selectedElements.length === 1 ? values.title : element.originalTitle,
                            storageType: 'FILE',
                            description: values.description,
                            canHaveExpiration: !!values.canExpire,
                            expireDate: values.dueDate,
                            needsLegalReview: values.needsLegalReview,
                            includeDescription: values.includeWithMessage,
                            sherpaEntityId,
                            parentId: element.parentId,
                            priorityType: values.priority,
                            modifiers,
                        })
                    }),
                    multiSelect: selectedElements.length > 1
                })
                // if only one element is being edited, we can show the toast with the element title
                // otherwise we show toast with n items updated
                if (selectedElements.length === 1) {
                    toast.success(`${values.title} updated successfully`);
                } else {
                    toast.success(`${selectedElements.length} items updated successfully`);
                }
            } else {
                const modifiers = []

                if (values.canFill) {
                    modifiers.push('FILLABLE_FORM');
                }
                if (values.needsLegalReview) {
                    modifiers.push('NEEDS_LEGAL_REVIEW');
                }
                const createdElements = await createElements({
                    elements: [{
                        loanId: props.loanId,
                        title: values.title,
                        storageType: 'FILE',
                        description: values.description,
                        canHaveExpiration: !!values.canExpire,
                        expireDate: values.dueDate,
                        needsLegalReview: values.needsLegalReview,
                        includeDescription: values.includeWithMessage,
                        sherpaEntityId,
                        parentId: values.parentId,
                        priorityType: values.priority,
                        modifiers,
                    }],
                    multiSelect: false
                }).unwrap();
                if (userState.isBorrower) {
                    // if user is a borrower we need to create a share with borrower so he can view the file

                    // get first element from packageInfo
                    const packageInfo = createdElements.list[0];
                    await createSharedInfoElement({
                        shares: [{
                            id: null,
                            infoId: packageInfo.id,
                            loanId: props.loanId,
                            permissions: ['EDIT', 'VIEW'],
                            sharedByUserId: userState.user.id,
                            sharedWithUserId: userState.user.id,
                        }]
                    });
                }
                // navigate to created element
                router.push({
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: createdElements.list[0].id
                    }
                })
                // New File Request created on {{View/Folder name}} successfully
                toast.success(`New File Request created on ${values.title} successfully`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            await dispatch(getLoanFormElements(props.loanId, true));
            formikHelpers.setSubmitting(false);
            props.onSuccess?.();
        }
    }

    const formik = useFormik<FormElementFormValues>({
        validationSchema: formElementFormValidation,
        enableReinitialize: true,
        initialValues: {
            id: '',
            title: '',
            description: '',
            canFill: false,
            canExpire: false,
            needsLegalReview: false,
            includeWithMessage: false,
            entityId: '',
            parentId: props.parentId,
            priority: null,
            dueDate: '',
            isEditingEntityEnabled: true,
            isConfirmationDialogOpen: false,
            ...initialValues
        },
        onSubmit: onPreSubmitCheck,
    });

    const handleCheckChange = (name: string, checked: boolean) => {
        formik.setFieldValue(name, checked);
    };

    const onDueDateSelected = (date: Date) => {
        formik.setFieldValue('dueDate', date);
    };

    const onEntitySelected = (entityId: string) => {
        formik.setFieldValue('entityId', entityId);
    };
    const onPackageInfoSelected = (packageInfoId: string) => {
        formik.setFieldValue('parentId', packageInfoId, true);
    }

    const onPrioritySelected = (priority: PriorityType) => {
        formik.setFieldValue('priority', priority);
    }

    const entities = [...props.loanEntities.map(entity => ({
        id: entity.sherpaEntity.id,
        name: entity.sherpaEntity.name,
    }))];
    // if we are editing and element does not have a sherpaEntityId, we need to
    // add the project docs entity to the list
    if (!elementToEdit || !elementToEdit.sherpaEntityId) {
        entities.push({
            id: 'PROJECT_DOCS',
            name: 'Project Docs'
        });
    }

    const openAccordionIds = useMemo(() => {
        const ids = formik.values.id
            ? findSectionByIdAndItsAncestors(formik.values.id, elementState.list)
                .map(element => element.id)
            : [];

        return [
            ...ids,
            elementState.tree[0]?.id
        ]
    }, [elementState.list, elementState.tree, formik.values.id]);

    let title = 'Create New File Request';
    if (nonNewEditIds.length > 1) {
        title = `Edit ${nonNewEditIds.length} Items Details`;
    } else if (nonNewEditIds.length === 1) {
        title = `Edit ${formik.initialValues.title} Details`;
    }

    const onConfirmationDialogCancel = () => {
        debugger;
        formik.setFieldValue('isConfirmationDialogOpen', false);
    }

    const onConfirmationDialogConfirm = (elementsIds: string[]) => {
        const elementIdsAlreadyAssigned = elementsToEdit
            .filter(element => element.sherpaEntityId === formik.values.entityId)
            .map(element => element.id);
        onSubmit(formik.values, formik, [...elementsIds, ...elementIdsAlreadyAssigned]);
    }

    const entityToEditName = props.loanEntities.find(entity => entity.sherpaEntity.id === formik.values.entityId)?.sherpaEntity?.name ?? "Project Docs";

    return {
        onDueDateSelected,
        handleCheckChange,
        onEntitySelected,
        onPackageInfoSelected,
        onPrioritySelected,
        onConfirmationDialogCancel,
        onConfirmationDialogConfirm,
        entityToEditName,
        isEditingMultipleEntities,
        title,
        isEdit,
        elementsToAssignNewEntity: elementsToEdit.filter(element => element.sherpaEntityId !== formik.values.entityId),
        isMultiEdit: nonNewEditIds.length > 1,
        openAccordionIds,
        isLoading: elementState.isLoading || conventionalElements.isLoading,
        isSubmitting: formik.isSubmitting,
        priorities,
        formik,
        entities,
        descriptionLength: formik.values.description.length,
        foldersTree: conventionalElements?.data?.tree ?? [],
        dueDateStatus: getDueDateStatus(formik.values.dueDate),
        formattedDueDate: getFormattedDueDate(formik.values.dueDate),
    } as const;
};