import { useGetLoanElementsQuery } from "src/services/packageApi";

import { useUserLoanViewType } from "./loans/use-user-loan-view-type";

interface GetElementsProps {
    loanId: string;
}

export const useGetElements = (props: GetElementsProps) => {
    const { loanViewType } = useUserLoanViewType();

    const { data, isLoading, refetch } = useGetLoanElementsQuery({
        id: props.loanId,
        view: loanViewType
    }, {
        skip: !props.loanId || !loanViewType
    })

    return {
        refetch,
        list: data?.list ?? [],
        links: data?.links ?? [],
        isLoading: isLoading,
        tree: data?.tree ?? [],
        rootElementId: data?.rootElementId,
        totalFileElements: data?.totalFileElements ?? 0,
        totalFolderElements: data?.totalFolderElements ?? 0,
    }
};