import React from "react";
import { LoanReviewStatus, StorageType, TemplateElementRequestDto, TemplateElementResponseDto } from "src/backend";
import { answerV2TemplateElement, createV2TemplateElement, deleteV2TemplateElement, getV2TemplateElements, updateV2TemplateElement, v2TemplateElementsSelector, v2TemplateElementsTreeSelector } from "src/slices/elementv2";
import { useDispatch, useSelector } from "src/store";
import { isElementReviewStatusHidden } from "src/utils/form-element/is-element-review-status-hidden";

import { v2TemplateSelector } from "../slices/templatev2";

interface UseV2FormElementsProps {
    templateId: string;
    reviewStatus: LoanReviewStatus;
}

export const useV2TemplateElements = (props: UseV2FormElementsProps) => {
    const templateElements = useSelector(v2TemplateElementsSelector(props.templateId))
    const elementsTree = useSelector(v2TemplateElementsTreeSelector(props.templateId))
    const template = useSelector(v2TemplateSelector(props.templateId))
    const dispatch = useDispatch();

    const handleCreateTemplateElement = (element: TemplateElementRequestDto) => {
        dispatch(createV2TemplateElement(element, props.templateId))
    }

    const handleUpdateElement = (element: Partial<TemplateElementRequestDto>) => {
        dispatch(updateV2TemplateElement(element, props.templateId))
    }

    const handleGetTemplateElements = () => {
        dispatch(getV2TemplateElements(props.templateId))
    }

    const handleDeleteTemplateElement = (element: TemplateElementRequestDto) => {
        dispatch(deleteV2TemplateElement(element, props.templateId))
    }

    const handleUploadFile = (element: TemplateElementResponseDto, file: File) => {
        dispatch(answerV2TemplateElement({
            file: file,
            element
        }))
    }
    React.useEffect(() => {
        if (props.templateId) {
            dispatch(getV2TemplateElements(props.templateId))
        }
    }, [dispatch, props.templateId]);

    return {
        elements: templateElements,
        ids: templateElements.map(e => e.templateElementId),
        totalFileRequests: templateElements.filter(e => e.storageType === 'FILE').length,
        totalHiddenFileRequests: templateElements.filter(e => e.storageType === 'FILE' && isElementReviewStatusHidden(e.visibleAtStatus, props.reviewStatus)).length,
        elementsTree,
        template,
        onUploadAnswer: handleUploadFile,
        onDeleteElement: handleDeleteTemplateElement,
        onUpdateElement: handleUpdateElement,
        onCreateElement: handleCreateTemplateElement,
        onGetTemplateElements: handleGetTemplateElements
    } as const
}