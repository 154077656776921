import * as React from "react"

export const GoogleIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={12}
        fill="none"
        viewBox="0 0 26 8"
        {...props}
    >
        <g clipPath="url(#aGoogleIcon)">
            <path
                fill="#4285F4"
                d="M3.252 3.258v.87h2.1c-.055.482-.223.852-.483 1.093-.297.296-.78.63-1.617.63C1.97 5.85.966 4.814.966 3.536c0-1.277.985-2.314 2.286-2.314.688 0 1.208.278 1.58.63l.613-.612A3.063 3.063 0 0 0 3.252.352C1.487.352 0 1.777 0 3.536c0 1.76 1.487 3.185 3.252 3.185.948 0 1.673-.315 2.23-.889.577-.574.762-1.389.762-2.037 0-.203-.018-.388-.037-.537H3.252Z"
            />
            <path
                fill="#EA4335"
                d="M8.847 2.54c-1.134 0-2.081.87-2.081 2.054 0 1.185.929 2.055 2.081 2.055s2.082-.851 2.082-2.036c0-1.204-.93-2.074-2.082-2.074Zm0 3.313c-.632 0-1.17-.518-1.17-1.24 0-.74.538-1.24 1.17-1.24.632 0 1.17.5 1.17 1.24 0 .722-.538 1.24-1.17 1.24Z"
            />
            <path
                fill="#4285F4"
                d="M19.012 3.002h-.037c-.205-.24-.595-.463-1.097-.463-1.04 0-1.933.889-1.933 2.055 0 1.148.892 2.055 1.933 2.055.502 0 .892-.222 1.097-.462h.037v.296c0 .777-.428 1.203-1.097 1.203-.557 0-.892-.389-1.04-.74l-.781.333c.223.537.836 1.222 1.84 1.222 1.06 0 1.97-.63 1.97-2.148V2.65h-.855v.352h-.037ZM17.97 5.853c-.632 0-1.096-.537-1.096-1.24 0-.722.483-1.24 1.096-1.24.613 0 1.096.536 1.096 1.258.02.704-.464 1.222-1.096 1.222Z"
            />
            <path
                fill="#FBBC05"
                d="M13.476 2.54c-1.134 0-2.082.87-2.082 2.054 0 1.185.93 2.055 2.082 2.055 1.152 0 2.081-.851 2.081-2.036 0-1.204-.929-2.074-2.081-2.074Zm0 3.313c-.632 0-1.17-.518-1.17-1.24 0-.74.538-1.24 1.17-1.24.632 0 1.17.5 1.17 1.24 0 .722-.538 1.24-1.17 1.24Z"
            />
            <path fill="#34A853" d="M20.59.445h.892v6.221h-.892V.446Z" />
            <path
                fill="#EA4335"
                d="M24.236 5.853c-.465 0-.78-.203-1.004-.63l2.77-1.129-.094-.24c-.167-.463-.706-1.315-1.765-1.315-1.06 0-1.952.833-1.952 2.055 0 1.148.874 2.055 2.045 2.055.947 0 1.486-.574 1.728-.907l-.706-.463c-.242.352-.558.574-1.022.574Zm-.056-2.536c.372 0 .688.185.78.444l-1.858.778c-.018-.852.613-1.222 1.078-1.222Z"
            />
        </g>
        <defs>
            <clipPath id="aGoogleIcon">
                <path fill="#fff" d="M0 .352h26v8.147H0z" />
            </clipPath>
        </defs>
    </svg>
)