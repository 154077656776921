import type { LoanReviewStatus } from "src/backend";

/**
 * 
 * @param status  
 * IN_PROGRESS = "IN_PROGRESS",
    READY_FOR_UNDERWRITING = "READY_FOR_UNDERWRITING",
    READY_FOR_REVIEW_PENDING = "READY_FOR_REVIEW_PENDING",
    READY_FOR_REVIEW_REVIEW = "READY_FOR_REVIEW_REVIEW",
    REVIEW_COMPLETE_ACCEPTED = "REVIEW_COMPLETE_ACCEPTED",
    REVIEW_COMPLETE_CONDITIONAL = "REVIEW_COMPLETE_CONDITIONAL",
    REVIEW_COMPLETE_REJECTED = "REVIEW_COMPLETE_REJECTED",
    CLOSING = "CLOSING",
    HOLD = "HOLD",
    IN_SERVICE = "IN_SERVICE",
    WITHDRAWN = "WITHDRAWN",
 */
export const getReviewStatusTooltip = (status: LoanReviewStatus) => {
    switch (status) {
        case 'IN_PROGRESS':
            return "In Progress";
        case 'READY_FOR_UNDERWRITING':
            return "Ready for Underwriting";
        case 'IN_DEFAULT':
            return "In Default";
        case 'LEAD':
            return "Lead";
        case 'ARCHIVE':
            return "Archived";
        case 'READY_FOR_REVIEW_PENDING':
            return "Review: Pending";
        case 'READY_FOR_REVIEW_REVIEW':
            return "Review: In Review";
        case 'REVIEW_COMPLETE_ACCEPTED':
            return "Review: Accepted";
        case 'REVIEW_COMPLETE_CONDITIONAL':
            return "Review: Conditional";
        case 'REVIEW_COMPLETE_REJECTED':
            return "Review: Rejected";
        case 'CLOSING':
            return "Closing";
        case 'HOLD':
            return "Hold";
        case 'IN_SERVICE':
            return "Servicing";
        case 'WITHDRAWN':
            return "Withdrawn";
        default:
            return "";
    }
}